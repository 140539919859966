import { Component, OnInit , ViewChild, ElementRef} from "@angular/core";
import { MemberService } from "../../member/member.service";

import { MessageService } from "primeng/components/common/messageservice";
import * as _ from "lodash";
import * as moment from "moment";
import { catchError, filter, retry } from "rxjs/operators";
import { throwError } from "rxjs";
import { Paginator } from "primeng/primeng";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-user-blacklist",
  templateUrl: "./user-blacklist.component.html",
  styleUrls: ["./user-blacklist.component.scss"],
  providers: [MessageService],
})
export class UserBlacklistComponent implements OnInit {
  public data: any = [];
  public columns: any = [];
  public display = false;
  public displayImg = false;
  public resetData = true;
  public selectedCompany: String;
  public selectedCompanyId: string = null;
  public selectedRole: string = null;
  public isAddMulai = false;
  public approveDialoge = false;
  public rejectDialoge = false;
  public addModal = false;
  public displayUpload = false;
  public loadingPaginate = false;
  public selectedColumns: any[];
  public loading: boolean;
  public selectedItem: any = null;
  public selectedImg = null;
  public grades = [];
  public grades2 = [];
  public selectedGrade = null;
  public companies = [];
  public roles = [];
  public filterRoles = [];
  public companies2 = [];
  public originGrades = [];
  public arrStatus = [];
  public arrReligion = [];
  public arrDomicile = [];
  public arrMariege = [];
  public statusOpsi = [];
  public arrRole = [];
  public selectedStatus = null;
  public date: Date = null;
  public date1: Date = null;
  public date2: Date = null;
  public minDate = moment().add("days", -1)["_d"];
  public isSubmitApprove = false;
  public isSubmitUpdate = false;
  public isSubmitReject = false;
  public viewDetail = false;
  public totalCount = 0;
  public start = 0;
  public pageLength = 10;
  public availabelColumn: Number;
  public widthDisplay: number;
  public roleId;
  public status = "";
  public dataProfile = null;
  private objFilter = {};
  public date_range = null;
  

  files: File[] = [];

  public displayForm = false;
  public isSubmitRegis = false;

  public name: string;
  public nama: string;
  public company: string;
  public perusahaan: string;
  public nama_karyawan: string;
  public daterange: string;
  public no_anggota : string;
  public status_blacklist = null;

  public nik : string;
  public widthTable = 1000;
  public email: string;
  public isApprovalReg: boolean;
  public identity_photo;
  public company_identity_photo;
  public personal_photo;
  public phone_number: string;
  public role;

  public imageID = null;
  public imageKTP = null;
  public imagePersonal = null;

  @ViewChild('hiddenLink') hiddenLink: ElementRef<HTMLAnchorElement>;

  constructor(
    private messageService: MessageService,
    private memberService: MemberService,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.fetchUser();
    this.fetchCompany();
    this.fetchRole();
    this.fetchMasterDocumentType();
    this.roleId = localStorage.getItem("id_role_master");
    
    // let endDate = new Date();
    // let startDate = new Date();
    // this.date_range = [ startDate, endDate];
    // console.log('date_range', this.date_range);
    this.widthDisplay = 1200;
    this.statusOpsi = [{ label: "Semua Status", value: null }];
    this.statusOpsi.push({ label: "Blacklist", value: "Y" });
    this.statusOpsi.push({ label: "WhiteList", value: "N" });
    this.columns = [
      { field: "id_koperasi", header: "No Anggota", show: true },
      { field: "id_employee", header: "NIP/NIK", show: true },
      { field: "name", header: "Nama", show: true },
      { field: "name_company", header: "Perusahaan", show: true },
      { field: "last_login_format", header: "Terakhir Login", show: true },
      { field: "date_become_member", header: "Tanggal Masuk Koperasi", show: true },
      { field: "resign", header: "Status Blacklist", show: true },
    ];
    this.availabelColumn = _.filter(this.columns, { show: true }).length + 1;
    this.selectedColumns = _.filter(this.columns, { show: true });
  }

  // Save
  // ========================= //
  save(e) {
    this.isSubmitRegis = true;
    e["identity_photo"] = this.imageID.imageSrc;
    e["company_identity_photo"] = this.imageKTP.imageSrc;
    e["personal_photo"] = this.imagePersonal.imageSrc;
    e["role"] = "ROLE001";

    this.memberService.postUser(e).subscribe(
      (res) => {
        this.isSubmitRegis = false;
        this.displayForm = false;
        this.fetchUser();
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "User has been added",
        });
      },
      (err) => {
        console.log(err);

        this.isSubmitRegis = false;
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Please try again",
        });
      }
    );
  }

  // Toggle Columns
  // ====================== //
  changeColumn(e) {
    const find = _.find(this.columns, e.itemValue);
    if (e.itemValue != undefined) {
      if (e.itemValue.show) {
        find.show = false;
      } else {
        find.show = true;
      }
    }
    this.availabelColumn = _.filter(this.columns, { show: true }).length + 1;
    this.selectedColumns = _.filter(this.columns, { show: true });
  }

  // Convert Image
  // ========================= //
  private typeupload = null;
  handleInputChange(e, type) {
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    const pattern = /image-*/;
    const reader = new FileReader();
    if (file != undefined) {
      if (!file.type.match(pattern)) {
        alert("invalid format");
        return;
      }
    }

    this.typeupload = type;
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  prosesReset() {
    this.resetData = false;
    setTimeout(() => {
      this.resetData = true;
    }, 600);
  }
  _handleReaderLoaded(e, type) {
    const reader = e.target;
    switch (this.typeupload) {
      case "company":
        this.imgEmployee = reader.result;
        break;
      case "document":
        this.imgDocument = reader.result;
        break;
    }

    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 500);
  }
  pilihImg(val) {
    this.selectedImg = val;
    this.displayImg = true;
  }
  // Fetching User
  // ========================= //
  // fetchUser() {
  //   this.loading = true;
  //   this.memberService
  //     .getListUserBlacklist(this.start, this.pageLength, this.objFilter)
  //     .subscribe(
  //       (res) => {
  //         _.map(res["data"].data, (x, i) => {
  //           x["number"] = i + 1;
  //           x.last_login_format = x.last_login
  //             ? moment(x.last_login).format("DD MMM YYYY HH:mm")
  //             : "-";
  //         });
  //         this.data = res["data"].data;
  //         console.log(this.data);
  //         this.totalCount = Number(res["data"].count_filter);
  //         this.loading = false;
  //         this.widthTable = 1200;
  //         this.fetchGrade();
  //         this.fetchCompany();
  //         this.fetchStatus();
  //       },
  //       (err) => {
  //         // this.fetchUser();
  //         this.loading = false;
  //         if (err.status == 401) {
  //           this.memberService.updateToken(err.error.data.token);
  //         }
  //       }
  //     );
  // }

  fetchUser() {
    console.log(this.objFilter);
    this.loading = true;
    // const { name } = this;
    // const params = { name: names, page: page, row: this.pageLength };
    
    this.memberService.getListUserBlacklist(this.start, this.pageLength, this.objFilter).subscribe(
      (res) => {
        if (res["status"] == 0) {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: res["message"],
          });
          this.loading = false;
          this.loadingPaginate = false;
          // this.fetchCompany();
          return;
        }

        this.data = res["data"];
        this.totalCount = res["pagination"].total;
        console.log('this.totalCount',this.totalCount);

        // const tmp = this.data.sort(this.compare);
        const tmp = this.data;
        console.log({ tmp });
        // _.map(tmp, (x, i) => {
        //   x.number = i;
        //   x.balance =
        //   this.formatCurrency(x.balance) ;
        // });
        // this.totalCount = res['data'].count_all;
        // if (parseInt(res["data"].count_all) == 0) {
        //   this.totalCount = 0;
        // } else {
        //   this.totalCount = Number(res["data"].count_all);
        //   if (this.totalCount > 100) {
        //     this.totalCount -= 10;
        //   }
        // }
        this.loading = false;
        this.loadingPaginate = false;
      },
      (err) => {
        this.loadingPaginate = false;
        this.loading = false;
      }
    );
  }
  paginate(e) {
    this.start = e.page * this.pageLength;
    this.fetchUser();
  }
  approveItem(val) {
    this.approveDialoge = true;
    this.selectedItem = val;
  }
  rejectItem(val) {
    this.rejectDialoge = true;
    this.selectedItem = val;
  }
  tambahNew() {
    this.company = "";
    this.role = this.roles[0].value;
    this.name = "";
    this.phone_number = "";
    this.email = "";
    this.personal_photo = "";
    this.isAddMulai = false;
    this.addModal = true;
  }
  prosesAdd() {
    this.isAddMulai = true;
    const body = {
      company: this.company,
      role: this.role,
      name: this.name,
      personal_photo: "foto",
      phone_number: this.phone_number,
      email: this.email,
      is_approval_reg: this.isApprovalReg ? "1" : "0",
    };

    this.memberService.registerUserBE(body).subscribe(
      (res) => {
        if (res["status"] == 0) {
          this.isAddMulai = false;
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: res["message"],
          });
        } else {
          this.addModal = false;
          this.isAddMulai = false;
          this.fetchUser();
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "User Registered Successfully",
          });
        }
      },
      (err) => {
        console.log(err);
        this.isAddMulai = false;
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: err.error.message,
        });
      }
    );
  }

  prosesUpdate() {
    // this.isAddMulai = true;
    this.isSubmitUpdate = true;
    const body = {
      company: this.selectedItem.id_company,
      role: this.selectedItem.id_role_master,
      id: this.selectedItem.id_user,
      name: this.selectedItem.name,
      personal_photo: "foto",
      phone_number: this.selectedItem.phone_number,
      email: this.selectedItem.email,
      is_approval_reg: this.selectedItem.is_approval_reg ? "1" : "0",
    };
    this.memberService.updateUserBE(body).subscribe(
      (res) => {
        if (res["status"] == 0) {
          this.isSubmitUpdate = false;
          this.messageService.add({
            severity: "warn",
            summary: "Error",
            detail: res["message"],
          });
        } else {
          this.viewDetail = false;
          this.isSubmitUpdate = false;
          this.fetchUser();
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "User Updated Successfully",
          });
        }
      },
      (err) => {
        console.log(err);
        this.isSubmitUpdate = false;
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: err.error.message,
        });
      }
    );
  }

  prosesActive() {
    this.isSubmitApprove = true;
    const body = {
      id: this.selectedItem.id_user,
      company: this.selectedItem.id_company,
      role: this.selectedItem.id_role_master,
      name: this.selectedItem.name,
      personal_photo: "foto",
      phone_number: this.selectedItem.phone_number,
      email: this.selectedItem.email,
      is_approval_reg: this.selectedItem.is_approval_reg ? "1" : "0",
    };
    this.memberService.noBlacklist(body).subscribe(
      (res) => {
        this.viewDetail = false;
        this.isSubmitApprove = false;
        this.fetchUser();
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "User WhiteList",
        });
      },
      (err) => {
        this.isSubmitApprove = false;
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Please try again",
        });
      }
    );
  }

  prosesDeactive() {
    this.isSubmitReject = true;
    const body = {
      id: this.selectedItem.id_user,
      company: this.selectedItem.id_company,
      role: this.selectedItem.id_role_master,
      name: this.selectedItem.name,
      personal_photo: "foto",
      phone_number: this.selectedItem.phone_number,
      email: this.selectedItem.email,
      is_approval_reg: this.selectedItem.is_approval_reg ? "1" : "0",
    };
    this.memberService.doBlacklist(body).subscribe(
      (res) => {
        this.viewDetail = false;
        this.isSubmitReject = false;
        this.fetchUser();
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "User Blacklist",
        });
      },
      (err) => {
        this.isSubmitApprove = false;
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Please try again",
        });
      }
    );
  }
  // Select Item / User
  // ======================== //
  selectItem(e) {
    e.is_approval_reg = e.is_approval_reg == "1" ? true : false;
    this.viewDetail = true;
    this.selectedItem = JSON.parse(JSON.stringify(e));
  }

  tabChange() {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 100);
  }

  // Create Mutation Employee
  // ======================== //
  public isSubmitMutation = false;
  public idEmployee = null;
  public division = null;
  public position = null;
  public selectedCompany2 = null;
  public imgEmployee = null;
  createMutation() {
    this.isSubmitMutation = true;
    const obj = {
      id: this.selectedItem.id_user,
      id_employee: this.idEmployee,
      id_company: this.selectedCompany2,
      company_identity_photo: this.imgEmployee,
      division: this.division,
      position: this.position,
    };
    this.memberService.updateMutation(obj).subscribe(
      (res) => {
        this.isSubmitMutation = false;
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Mutasi karyawan berhasil",
        });
        this.display = false;
        this.fetchUser();
      },
      (err) => {
        this.isSubmitMutation = false;
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Mutasi karyawan gagal, silakan coba lagi",
        });
      }
    );
  }

  // Select Company Mutation Employee
  // ============================== //
  changeCompany2(e) {
    this.selectedCompany2 = e.value;
  }

  // Fetch Salary
  // ============================ //
  fetchSallary(id) {
    this.memberService.getSallary(id).subscribe(
      (res) => {
        this.dataProfile["salary"] = res["data"];
        this.dataProfile["salary"].amount = Number(
          res["data"].salary_amount
        ).toLocaleString();
        this.fetchBank(id);
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }

  // Fetch Bank
  // ============================ //
  fetchBank(id) {
    this.memberService.getBank(id).subscribe(
      (res) => {
        this.dataProfile["bank"] = res["data"];
        this.fetchDocument(id);
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }

  // Fetch Document
  // ============================ //
  public isViewDocument = false;
  public openTabDocument = false;
  public selectedDocument = null;
  public imgDocument = null;
  public isSubmitDocument = false;
  public arrDocumentType = [];
  public selectedDocumentType = null;
  public isUpdateDocument = false;

  fetchDocument(id) {
    this.memberService.getDocument(id).subscribe(
      (res) => {
        res["data"].map((x) => {
          x["disable"] = false;
          x["document_name"] = _.find(this.arrDocumentType, {
            value: x.id_document_type,
          }).label;
        });
        this.dataProfile["document"] = res["data"];
        this.display = true;
        this.loading = false;
        this.openTabDocument = false;
        setTimeout(() => {
          window.dispatchEvent(new Event("resize"));
        }, 100);
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }

  removeDocument(e) {
    this.selectedDocument = e;
    this.selectedDocument.disable = true;
    this.memberService
      .deleteDocument(
        Number(this.selectedDocument.id_user),
        this.selectedDocument.id_user_document
      )
      .subscribe(
        (res) => {
          this.fetchDocument(Number(this.selectedDocument.id_user));
          this.selectedDocument = null;
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Berhasil hapush dokumen",
          });
        },
        (err) => {
          this.selectedDocument.disable = false;
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: "Gagal hapush dokumen",
          });
        }
      );
  }

  viewDocument(e) {
    this.widthDisplay = 800;
    this.selectedDocument = e;
    this.isViewDocument = true;
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 100);
  }

  closeViewDocument() {
    this.widthDisplay = 1200;
    this.isViewDocument = false;
    this.openTabDocument = true;
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 100);
  }

  changeDocumentType(e) {
    this.selectedDocumentType = e.value;
  }

  createDocument() {
    const obj = {
      id: this.selectedItem.id_user,
      id_document_type: this.selectedDocumentType,
      doc_photo: this.imgDocument,
    };
    this.isSubmitDocument = true;

    if (this.isUpdateDocument) {
      this.memberService.updateDocument(obj).subscribe(
        (res) => {
          this.isSubmitDocument = false;
          this.fetchDocument(this.selectedItem.id_user);
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Berhasil tambah dokumen",
          });
          this.cancelUpdateDoc();
        },
        (err) => {
          this.isSubmitDocument = false;
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: "Gagal tambah dokumen",
          });
        }
      );
    } else {
      this.memberService.postDocument(obj).subscribe(
        (res) => {
          this.isSubmitDocument = false;
          this.fetchDocument(this.selectedItem.id_user);
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Berhasil tambah dokumen",
          });
        },
        (err) => {
          this.isSubmitDocument = false;
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: "Gagal tambah dokumen",
          });
        }
      );
    }
  }
  editDocument(e) {
    this.isUpdateDocument = true;
    this.selectedDocumentType = e.id_document_type;
    this.imgDocument = e.path;
  }
  cancelUpdateDoc() {
    this.isUpdateDocument = false;
    this.imgDocument = null;
  }

  // Fetch Master
  // ========================= //
  fetchCompany() {
    this.memberService.getCompany().subscribe(
      (res) => {
        this.companies = [{ label: "Semua Perusahaan", value: null }];
        _.map(res["data"], (x) => {
          this.companies.push({ label: x.name_company, value: x.id_company });
        });
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );

    this.memberService.getCompany2().subscribe(
      (res) => {
        _.map(res["data"], (x) => {
          this.companies2.push({ label: x.name_company, value: x.id_company });
        });
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }
  fetchStatus() {
    this.memberService.getStatus().subscribe(
      (res) => {
        this.arrStatus = [{ label: "Semua Status", value: null }];
        _.map(res["data"], (x) => {
          const obj = {
            label: x.workflow_status_name,
            value: x.id_workflow_status,
          };
          this.arrStatus.push(obj);
        });

        _.map(this.data, (x) => {
          x["status_name"] = _.find(this.arrStatus, {
            value: x.id_workflow_status,
          }).label;
        });
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }
  fetchGrade() {
    this.memberService.getGrade().subscribe(
      (res) => {
        this.originGrades = res["data"];
        this.grades = [{ label: "Semua Golongan", value: null }];
        this.grades2 = [];
        _.map(res["data"], (x) => {
          const obj = { label: x.name_grade, value: x.id_grade };
          this.grades.push(obj);
          this.grades2.push(obj);
        });
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }
  fetchDomicile() {
    this.memberService.getDomicile().subscribe(
      (res) => {
        this.arrDomicile = res["data"];
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }
  fetchReligion() {
    this.memberService.getReligion().subscribe(
      (res) => {
        this.arrReligion = res["data"];
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }
  fetchMarriage() {
    this.memberService.getMarriage().subscribe(
      (res) => {
        this.arrMariege = res["data"];
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }
  fetchRole() {
    this.filterRoles = [];
    this.memberService.getRole().subscribe(
      (res) => {
        this.arrRole = res["data"];
        this.roles = [];
        _.map(res["data"], (x) => {
          this.roles.push({
            label: x.name_role_master,
            value: x.id_role_master,
          });
        });
        this.role = this.roles[0].value;
        this.filterRoles = this.roles;
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }
  fetchMasterDocumentType() {
    this.memberService.getMstDocument().subscribe(
      (res) => {
        _.map(res["data"], (x) => {
          const obj = { label: x.document_name, value: x.id_document_type };
          this.arrDocumentType.push(obj);
        });
        this.selectedDocumentType = this.arrDocumentType[0].value;
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }

  // Filter List
  // ========================== //
  // private typingTimer;
  // private doneTypingInterval = 1000;
  // onSearchName(searchValue: string) {
  //   clearTimeout(this.typingTimer);
  //   this.typingTimer = setTimeout(() => {
  //     if (searchValue) {
  //       this.objFilter["nama"] = searchValue;
  //     } else {
  //       delete this.objFilter["nama"];
  //     }
  //     this.start = 0;
  //     this.fetchUser();
  //   }, this.doneTypingInterval);
  // }
  // onSearchNik(searchValue: string) {
  //   clearTimeout(this.typingTimer);
  //   this.typingTimer = setTimeout(() => {
  //     if (searchValue) {
  //       this.objFilter["id_employee"] = searchValue;
  //     } else {
  //       delete this.objFilter["id_employee"];
  //     }
  //     this.start = 0;
  //     this.fetchUser();
  //   }, this.doneTypingInterval);
  // }
  // onSearchNoAnggota(searchValue: string) {
  //   clearTimeout(this.typingTimer);
  //   this.typingTimer = setTimeout(() => {
  //     if (searchValue) {
  //       this.objFilter["id_koperasi"] = searchValue;
  //     } else {
  //       delete this.objFilter["id_koperasi"];
  //     }
  //     this.start = 0;
  //     this.fetchUser();
  //   }, this.doneTypingInterval);
  // }
  // changeCompany(e) {
  //   if (e.value) {
  //     this.objFilter["company"] = e.value;
  //   } else {
  //     delete this.objFilter["company"];
  //   }

  //   this.start = 0;
  //   this.fetchUser();
  //   this.prosesReset();
  // }
  // changeStatus(e) {
  //   if (e.value) {
  //     this.objFilter["status"] = e.value;
  //   } else {
  //     delete this.objFilter["status"];
  //   }

  //   this.start = 0;
  //   this.fetchUser();
  // }
 

  // onDateRangeChange() {

  //   if (this.date_range) {
  //     const date1 = moment(this.date_range[0]).format("YYYY-MM-DD");
  //     const date2 = moment(this.date_range[1]).format("YYYY-MM-DD");
  //     this.objFilter["tgl_masuk"] = date1+" - "+date2;
  //   } else {
  //     delete this.objFilter["tgl_masuk"];
  //   }
  //   this.start = 0;
  //   this.fetchUser();
  // }

   // FILTER
   submitFilter(valFilter: any) {
    const {
      nama,
      id_koperasi,
      id_employee,
      status_blacklist,
      perusahaan,
    } = valFilter;

    let qName = nama != null ? `&name=${nama}` : "";
    let qIdKoperasi = id_koperasi != null ? `&id_koperasi=${id_koperasi}` : "";
    let qPerusahaan = perusahaan != undefined ? `&perusahaan=${perusahaan}` : "";
    let qid_employee = id_employee != undefined ? `&id_employee=${id_employee}` : "";
    let qstatus_blacklist = status_blacklist != undefined ? `&status=${status_blacklist}` : "";

    if(this.date_range){
      const date1 = moment(this.date_range[0]).format("YYYY-MM-DD");
      const date2 = moment(this.date_range[1]).format("YYYY-MM-DD");
      let date1a = "&reqStart="+date1;
      let date2a = "&reqEnd="+date2;
      this.objFilter = `${qName}${qIdKoperasi}${qPerusahaan}${qid_employee}${qstatus_blacklist}${date1a}${date2a}`;

    }else{
      let qdaterange =  "";
      this.objFilter = `${qName}${qIdKoperasi}${qPerusahaan}${qid_employee}${qstatus_blacklist}${qdaterange}`;
    }
   
    this.start = 1;
    this.fetchUser();
  }

  donwloadReport(valFilter: any) {
    const {
      nama,
      id_koperasi,
      id_employee,
      status_blacklist,
      perusahaan,
    } = valFilter;
    let qName = nama != null ? `&name=${nama}` : "";
    let qIdKoperasi = id_koperasi != null ? `&id_koperasi=${id_koperasi}` : "";
    let qPerusahaan = perusahaan != undefined ? `&perusahaan=${perusahaan}` : "";
    let qid_employee = id_employee != undefined ? `&id_employee=${id_employee}` : "";
    let qstatus_blacklist = status_blacklist != undefined ? `&status=${status_blacklist}` : "";

    if(this.date_range){
      const date1 = moment(this.date_range[0]).format("YYYY-MM-DD");
      const date2 = moment(this.date_range[1]).format("YYYY-MM-DD");
      let date1a = "&reqStart="+date1;
      let date2a = "&reqEnd="+date2;
      this.objFilter = `${qName}${qIdKoperasi}${qPerusahaan}${qid_employee}${qstatus_blacklist}${date1a}${date2a}`;

    }else{
      let qdaterange =  "";
      this.objFilter = `${qName}${qIdKoperasi}${qPerusahaan}${qid_employee}${qstatus_blacklist}${qdaterange}`;
    }
    this.fetchDownloadReportProgress();
  }

  fetchDownloadReportProgress() {
    this.hiddenLink.nativeElement.href = this.memberService.getDownloaReport(this.objFilter);
    this.hiddenLink.nativeElement.click();
  }

  onClose() {
    this.selectedItem = null;
    this.selectedCompanyId = null;
    this.selectedRole = null;
  }

  resetFilter() {
    this.objFilter = "";
    // this.pagination.changePageToFirst(event);
    this.fetchUser();
    this.start = 1;
  }

  uploadItem() {
    this.displayUpload = true;
  }

  @ViewChild("p") pagination: Paginator;

  onChange(event: any) {
    const files = event.target.files;

    if (files.length) {
      this.status = "initial";
      this.files = files;
    }
  }

  public loadingSubmitApprove: boolean = false;
  onUpload() {
    this.loadingSubmitApprove = true;
    // console.log(this.files);
    if (this.files.length) {
      const formData = new FormData();
      const file = this.files[0]; // Menggunakan akses indeks standar untuk FileList
     
      if(file !== undefined){
        formData.append("file", file, file.name);
      }
      console.log(formData);
      const upload$ = this.http.post("https://u.koperasi-astra.com/file-import-blacklist", formData);
      console.log(upload$);
    
      this.status = "uploading";
    
      upload$.subscribe({
        next: () => {
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Data Successfully submitted",
          });
          this.loadingSubmitApprove = false;
          this.status = "success";
          window.location.reload();
        },
        error: (error: any) => {
          this.loadingSubmitApprove = false;
          this.status = "fail";
        },
      });
    }
    
  }
}
