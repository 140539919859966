import { Component, OnInit, ViewChild } from "@angular/core";
import * as _ from "lodash";
import { FileUpload } from "primeng/fileupload";
import { Paginator } from "primeng/paginator";
import { MasterService } from "../master.service";
import { Message, MessageService } from "primeng/components/common/api";
import * as moment from "moment";
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder } from '@angular/forms';
interface selectedItem {
  id_content_type: string;
  title: string;
  start_date: Date;
  startDate: Date;
  endDate: Date;
  end_date: Date;
  position: string;
  image_path: FileUpload;
  isAktif: boolean;
  status: number;
  id_content: string;
}

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
  providers: [MessageService],
})
export class ChangePassword implements OnInit {
  columns: any[];
  arrData: any[] = [];
  arrContentType: any[] = [];
  selectedItem: any = {};
  selectedThumbnail;
  idContent: string = null;
  loading: boolean = false;
  loadingSave: boolean = false;
  displayForm: boolean = false;
  isEdit: boolean = false;
  arrPosition: any[];
  totalCount: number = 0;
  perPage: number = 5;

  private selectedPage = 1;
  @ViewChild("p") paginator: Paginator;
  @ViewChild("fileInput") fileInput: FileUpload;

  public msgs: Message[] = [];
  newPassword: any;
  confirmPassword: any;
  constructor(
    private masterService: MasterService,
    private messageService: MessageService
  ) {}

  async ngOnInit() {
    try {
      
    } catch (error) {
      console.log("Something went wrong!");
    }
    this.columns = [
      { header: "Banner", field: "image_path" },
      { header: "Category Type", field: "id_content_type" },
      { header: "Title", field: "title" },
      { header: "Urutan", field: "position" },
      { header: "Status", field: "status" },
    ];
    this.arrPosition = [
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "4", label: "4" },
      { value: "5", label: "5" },
    ];
  }

  fetchData(start: number, length: number, sort: string) {
    this.loading = true;
    return new Promise((resolve, reject) => {
      let param = { start, length, sort };
      this.arrData = [];
      this.masterService.getDataBanner(param).subscribe(
        (res) => {
          resolve(res);
        },
        (res: Response) => {
          reject(res.statusText);
        }
      );
    });
  }

  fetchContentType() {
    return new Promise((resolve, reject) => {
      this.masterService.getDataContentType().subscribe((res) => {
        resolve(res);
      });
    });
  }

  paginate(e) {
    this.selectedPage = e.page + 1;
    this.ngOnInit();
    // this.fetchData(this.selectedPage, this.perPage, "id_content,asc");
  }

  showThis(val: selectedItem) {
    val.isAktif = val.status == 1 ? true : false;
    val.startDate =
      val.start_date != null
        ? new Date(moment(val.start_date).format("DD MMM YYYY"))
        : moment("00:00", "HH:mm")["_d"];

    val.endDate =
      val.end_date != null
        ? new Date(moment(val.end_date).format("DD MMM YYYY"))
        : new Date(moment(val.startDate).format("DD MMM YYYY"));
    this.isEdit = true;
    this.idContent = val.id_content;
    this.selectedThumbnail = val.image_path;

    this.selectedItem = val;
  }

  

  async save(e: any) {

    const param = {
      password_lama: e.password_lama,
      newPassword: e.newPassword,
      confirmPassword: e.confirmPassword,

    };

if(e.newPassword == e.confirmPassword){
      this.masterService.updatePassword(param).subscribe((res) => {
        if (res.status == 1) {
          this.msgs = [];
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Password Berhasil diganti",
          });
          this.resetForm();
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: res.message,
          });
        }
        this.loadingSave = false;
      },
      (err) => {
        console.log(err.error.message);
        this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: err.error.message,
            });
      }
      );
    
  }else{
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: "Password Baru Tidak Sama dengan Confirm Password Baru",
    });
  }
}

  resetForm() {
    this.loading = false;
    this.loadingSave = false;
    this.selectedThumbnail = null;
    this.selectedItem = "";
    this.isEdit = false;
    this.idContent = null;
    this.displayForm = false;
    this.ngOnInit();

  }
}
