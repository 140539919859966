import { Component, OnInit, ViewChild } from "@angular/core";
import * as _ from "lodash";
import { FileUpload } from "primeng/fileupload";
import { Paginator } from "primeng/paginator";
import { MasterService } from "../master.service";
import { Message, MessageService } from "primeng/components/common/api";
import * as moment from "moment";
interface selectedItem {
  id_content_type: string;
  title: string;
  start_date: Date;
  startDate: Date;
  endDate: Date;
  end_date: Date;
  position: string;
  image_path: FileUpload;
  isAktif: boolean;
  status: number;
  id_content: string;
}
@Component({
  selector: "app-upload-banner",
  templateUrl: "./upload-banner.component.html",
  styleUrls: ["./upload-banner.component.scss"],
  providers: [MessageService],
})
export class UploadBannerComponent implements OnInit {
  columns: any[];
  arrData: any[] = [];
  arrContentType: any[] = [];
  selectedItem: any = {};
  selectedThumbnail;
  idContent: string = null;
  loading: boolean = false;
  loadingSave: boolean = false;
  displayForm: boolean = false;
  isEdit: boolean = false;
  arrPosition: any[];
  totalCount: number = 0;
  perPage: number = 5;

  private selectedPage = 1;
  @ViewChild("p") paginator: Paginator;
  @ViewChild("fileInput") fileInput: FileUpload;

  public msgs: Message[] = [];
  constructor(
    private masterService: MasterService,
    private messageService: MessageService
  ) {}

  async ngOnInit() {
    try {
      let resContentType: any = await this.fetchContentType();
      let resContent: any = await this.fetchData(
        this.selectedPage,
        this.perPage,
        "status,desc"
      );

      if (resContentType.length) {
        this.arrContentType = _.forEach(resContentType, (x) => {
          x.value = x.id_content_type;
          x.label = x.content_name;
        });
      }

      if (resContent.data) {
        this.arrData = _.forEach(resContent.data, (x) => {
          x.content_type_name = _.find(this.arrContentType, {
            value: x.id_content_type,
          }).content_name;
        });
        this.totalCount = resContent.count_all;
        this.loading = false;
      }
    } catch (error) {
      console.log("Something went wrong!");
    }
    this.columns = [
      { header: "Banner", field: "image_path" },
      { header: "Category Type", field: "id_content_type" },
      { header: "Title", field: "title" },
      { header: "Urutan", field: "position" },
      { header: "Status", field: "status" },
    ];
    this.arrPosition = [
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "4", label: "4" },
      { value: "5", label: "5" },
    ];
  }

  fetchData(start: number, length: number, sort: string) {
    this.loading = true;
    return new Promise((resolve, reject) => {
      let param = { start, length, sort };
      this.arrData = [];
      this.masterService.getDataBanner(param).subscribe(
        (res) => {
          resolve(res);
        },
        (res: Response) => {
          reject(res.statusText);
        }
      );
    });
  }

  fetchContentType() {
    return new Promise((resolve, reject) => {
      this.masterService.getDataContentType().subscribe((res) => {
        resolve(res);
      });
    });
  }

  paginate(e) {
    this.selectedPage = e.page + 1;
    this.ngOnInit();
    // this.fetchData(this.selectedPage, this.perPage, "id_content,asc");
  }

  showThis(val: selectedItem) {
    val.isAktif = val.status == 1 ? true : false;
    val.startDate =
      val.start_date != null
        ? new Date(moment(val.start_date).format("DD MMM YYYY"))
        : moment("00:00", "HH:mm")["_d"];

    val.endDate =
      val.end_date != null
        ? new Date(moment(val.end_date).format("DD MMM YYYY"))
        : new Date(moment(val.startDate).format("DD MMM YYYY"));
    this.isEdit = true;
    this.idContent = val.id_content;
    this.selectedThumbnail = val.image_path;

    this.selectedItem = val;
  }

  // validationSize
  validateFileSize($event: any, maxFileSize: number) {
    if ($event.files[0].size > maxFileSize) {
      alert("Maximum File 3MB");
    }
  }

  //single file upload
  onChangeThumbnail(file) {
    var myReader: FileReader = new FileReader();
    myReader.readAsDataURL(file);
    myReader.onloadend = (e) => {
      this.selectedThumbnail = myReader.result;
    };
  }

  convertImgToBlob() {
    return new Promise((resolve) => {
      var myReader: FileReader = new FileReader();
      myReader.readAsDataURL(this.fileInput.files[0]);
      myReader.onloadend = (e) => {
        resolve(myReader.result);
      };
    });
  }

  async save(e: any) {
    let img =
      this.fileInput.files.length > 0 ? await this.convertImgToBlob() : "no";
    this.loadingSave = true;
    const param = {
      id_content_type: e.id_content_type,
      title: e.title,
      path: img,
      status: e.status ? 1 : 0,
      start_date: `${moment(e.startPeriode).format("YYYY-MM-DD")} 00:01:00`,
      end_date: `${moment(e.endPeriode).format("YYYY-MM-DD")} 23:59:00`,
      position: e.position,
    };

    if (this.isEdit) {
      param["id_content"] = this.idContent;
      this.masterService.updateBanner(param).subscribe((res) => {
        if (res.status == 1) {
          this.msgs = [];
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Update Success",
          });
          this.resetForm();
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: res.message,
          });
        }
        this.loadingSave = false;
      });
    } else {
      this.masterService.postBanner(param).subscribe(
        (res) => {
          if (res.status == 1) {
            this.messageService.add({
              severity: "success",
              summary: "Success",
              detail: "Create Success",
            });
            this.resetForm();
          } else {
            this.msgs = [];
            this.msgs.push({
              severity: "error",
              summary: "Error",
              detail: res.message,
            });
          }
          this.loadingSave = false;
        },
        (err: Response) => {
          this.loadingSave = false;
          console.log(err.statusText);
        }
      );
    }
  }

  resetForm() {
    this.loading = false;
    this.loadingSave = false;
    this.selectedThumbnail = null;
    this.selectedItem = "";
    this.isEdit = false;
    this.idContent = null;
    this.displayForm = false;
    this.ngOnInit();
    // this.fetchContentType();
    // this.fetchData(1, this.perPage, "id_content,asc");
  }
}
