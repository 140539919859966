import { Injectable } from '@angular/core';
import { APIService } from '../../service/api.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  private urlGetPaymentUser = this.APIservice['hostAuth'] + '/payment/list/user-registration';
  private urlGetOrderBiller = this.APIservice['hostAuth'] + '/payment/list/user-order-biller';
  private urlGetOrderBillerDetail = this.APIservice['hostAuth'] + '/payment/list/user-order-biller/detail';
  constructor(
    private APIservice: APIService,
    private http: HttpClient
  ) {}

  getReportParam(start, length, objFilter) {
    const options = {
      headers: new HttpHeaders({
        'accept':  'application/json',
        'Authorization' : localStorage.getItem('token')
      })
    };
    const filter = escape(JSON.stringify(objFilter));
    return this.http.post(this.urlGetPaymentUser + '?start=' + start +  '&length=' + length + '&sort=transaction_date,desc&manual_filter=' + filter,{}, options);
  }
  getOrderBillerParam(start, length, objFilter) {
    const options = {
      headers: new HttpHeaders({
        'accept':  'application/json',
        'Authorization' : localStorage.getItem('token')
      })
    };
    const filter = escape(JSON.stringify(objFilter));
    return this.http.post(this.urlGetOrderBiller + '?start=' + start +  '&length=' + length + '&sort=name,asc&manual_filter=' + filter,{}, options);
  }
  getOrderBillerDetailParam(start, length, id_user) {
    const options = {
      headers: new HttpHeaders({
        'accept':  'application/json',
        'Authorization' : localStorage.getItem('token')
      })
    };
    // const filter = escape(JSON.stringify(objFilter));
    return this.http.post(this.urlGetOrderBillerDetail + '?start=' + start +  '&length=' + length + '&sort=name,asc&id_user_biller=' + id_user,{}, options);
  }
}
