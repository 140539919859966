import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

// Modules
import { DashboardModule } from "./dashboard/dashboard.module";
import { MemberModule } from "./member/member.module";
import { ReportModule } from "./report/report.module";
import { ProductsModule } from "./products/products.module";
import { LoanModule } from "./loan/loan.module";
import { MasterModule } from "./master/master.module";
import { OrderModule } from "./order/order.module";
import { IconsModule } from "../icons/icons.module";
import { TicketsModule } from "./tickets/tickets.module";
import { LoanNewModule } from "./loan-new/loan-new.module";
import { PelunasanModule } from "./pelunasan/pelunasan.module";

import { HeaderComponent } from "./header/header.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { MainComponent } from "./layout/main-component.component";

// PrimeNG
import { OverlayPanelModule } from "primeng/overlaypanel";
import { SidebarModule } from "primeng/sidebar";
import { TabViewModule } from "primeng/tabview";
import { InputSwitchModule } from "primeng/inputswitch";
import { AutoCompleteModule } from "primeng/autocomplete";
import { FileUploadModule } from "primeng/fileupload";
import { LoanReportModule } from "./loan-report/loan-report.module";

@NgModule({
  imports: [
    RouterModule,
    DashboardModule,
    IconsModule,
    MemberModule,
    TicketsModule,
    ProductsModule,
    LoanModule,
    LoanNewModule,
    PelunasanModule,
    LoanReportModule,
    MasterModule,
    OrderModule,
    ReportModule,
    FormsModule,
    CommonModule,
    HttpClientModule,
    FileUploadModule,
    SidebarModule,
    OverlayPanelModule,
    TabViewModule,
    InputSwitchModule,
    AutoCompleteModule,
  ],
  declarations: [MainComponent, HeaderComponent, SidebarComponent],
  exports: [MainComponent],
})
export class MainModule {}
