/* tslint:disable:whitespace */
import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { LoanNewService } from "../loan-new.service";
import { MessageService } from "primeng/components/common/messageservice";
import { store } from "../../../service/store.service";
import * as _ from "lodash";
import * as moment from "moment";
declare var $: any;
import { Paginator } from "primeng/primeng";

@Component({
  selector: "app-pelunasan-list",
  templateUrl: "./pelunasan-list.component.html",
  styleUrls: ["./pelunasan-list.component.scss"],
  providers: [MessageService],
})
export class PelunasanListComponent implements OnInit {
  private objFilter: any;
  public id_role_master;
  public data: any = [];
  public columns: any = [];
  public selectedColumns: any[];
  public loading: boolean;
  public loading_pagination = false;
  public selectedItem = null;
  public statusList = [];
  public companies = [];
  public golonganKaiList = [];
  public loanTypeAllList = [];
  public start = 1;

  public totalCount = 0;
  public pageLength = 10;
  public selectedGolongan = null;
  public selectedLoanType = null;
  public selectedStatus = null;
  public urlDownload = null;
  public date: Date = null;
  public date1: Date = null;
  public date2: Date = null;
  // public minDate = moment().add("days", -1)["_d"];
  public isSubmitApprove = false;
  public isSubmitReject = false;
  public widthTable = 0;

  public start_date = "";
  public end_date = "";

  @ViewChild("p") pagination: Paginator;

  public selectedLoan = null;

  constructor(private loanService: LoanNewService, private router: Router) {
    store.subscribe(() => {
      this.id_role_master = localStorage.getItem("id_role_master");
    });
  }

  ngOnInit() {
    this.id_role_master = localStorage.getItem("id_role_master");
    const date1 = moment().subtract(4, "months").format("YYYY-MM-[01]");
    const date2 = moment().add(1, "days").format("YYYY-MM-DD");
    this.start_date = date1;
    this.end_date = date2;

    if(['ROLE003', 'ROLE007'].includes(this.id_role_master)) {
      this.objFilter = `&status_loan=MLTSTS01`;
    }
    
    this.fetchLoan();
    this.fetchCompany();
    // this.fetchGolonganKai();
    // this.fetchLoanTipeAll();
    this.fetchStatusLoan();

    this.columns = [
      { field: "no_document", header: "No Document", show: true },
      { field: "workflow_status_name", header: "Status", show: true},
      { field: "id_koperasi", header: "No Anggota", show: true },
      { field: "name", header: "Nama Anggota", show: true },
      { field: "total_pelunasan_text", header: "Total Pelunasan", show: true},
      { field: "date_applied_text", header: "Tanggal Transaksi", show: true },
      { field: "simulation_date_text", header: "Tanggal Simulasi", show: true },
      { field: "pinjamanno", header: "No Pinjaman", show: true },
      { field: "nama_pinjaman", header: "Tipe Pinjaman", show: true },
      { field: "perusahaan", header: "Company",show:true},
    ];
    this.selectedColumns = _.filter(this.columns, { show: true });

    const widthTB = $("#tab-card").width();
    this.widthTable = widthTB;
    $(window).on("resize", function () {
      const widthTB = $("#tab-card").width();
      this.widthTable = widthTB;
    });
  }

  // Toggle Columns
  // ====================== //
  changeColumn(e) {
    const find = _.find(this.columns, e.itemValue);
    if (e.itemValue != undefined) {
      if (e.itemValue.show) {
        find.show = false;
      } else {
        find.show = true;
      }
    } else {
    }

    this.selectedColumns = _.filter(this.columns, { show: true });
  }
  compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const bandA = a.request_date.toUpperCase();
    const bandB = b.request_date.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison * -1;
  }

  // Fetching Loan
  // ========================= //
  fetchLoan() {
    let token = localStorage.getItem("token");
		if(!token){
			this.router.navigate(['/sign/']);  
		}

    this.loading = true;
    this.loading_pagination = true;
    // console.log('this.objFilter', this.objFilter)
    // if(!this.objFilter || this.objFilter == '') {
    //   this.objFilter = `&status_loan=MLTSTS01`;
    //   this.selectedStatus = 'MLTSTS01';
    // } 
    this.loanService
      .getLoanNew(this.start, this.pageLength, this.objFilter)
      .subscribe(
        (res: any) => {
          this.data = res.data ? res.data.items : [];
          this.totalCount = res.data && res.data.total;
          // const tmpItems = JSON.parse(JSON.stringify(this.data)); // this.data;
          _.map(this.data, (x, i) => { 
            console.log(x);
            x.hutang_bunga_pelunasan_text = this.formatCurrency(x.hutang_bunga_pelunasan);
            x.pinalti_bunga_pelunasan_text = this.formatCurrency(x.pinalti_bunga_pelunasan);
            x.total_pelunasan_text = this.formatCurrency(x.total_pelunasan);
            x.angsuran_bulan_text = this.formatCurrency(x.angsuran_bulan);
            x.pokokpinjaman_text = this.formatCurrency(x.pokokpinjaman);
            x.total_bunga_text = this.formatCurrency(x.total_bunga);
            x.total_pinjaman_text = this.formatCurrency(x.total_pinjaman);
            x.date_applied_text = (x.date_applied && x.date_applied != '')
              ? moment(x.date_applied).format("DD-MMM-YYYY") : '';
            x.simulation_date_text = (x.simulation_date && x.simulation_date != '')
              ? moment(x.simulation_date).format("DD-MMM-YYYY") : '';
          });

          // store.dispatch({ type: 'COUNTER', jmlCalongAnggota: Number(res['data'].count_all) });
          this.loading = false;
          this.loading_pagination = false;
        },
        (err) => {
          this.loading = false;
          this.loading_pagination = false;
        }
      );
  }

  formatCurrency(value) {
    if(value && value != '') {
      value = parseInt(value || 0);
      return "Rp " + (parseInt(value || 0)).toLocaleString();
    }
    return '';
  }

  // Select Item / User
  // ======================== //
  selectItem(e) {
    console.log(e);
    this.router.navigate(["/main/pelunasan/detail/" + e.pelunasan_id], {
      queryParams: {
        // type: e.id_loan_type,
        id_user: e.id_user,
        id_koperasi: e.id_koperasi,
        // idemployee: e.id_koperasi,
      },
    });
  }

  downloadExcel() {
    // this.loanService.getDownloadLink().subscribe(
    //   (res) => {
    //     this.urlDownload = res["data"];
    //   },
    //   (err) => {
    //     // this.fetchGrade();
    //     console.log(err);
    //   }
    // );
  }

  // Fetch Status Workflow
  // ========================= //
  fetchStatusLoan() {
    this.loanService.getLoanMasterWorkflowStatus().subscribe(
      (res) => {
        this.statusList = [{ label: "Semua Status", value: null }];
        _.map(res["data"], (x) => {
          const obj = {
            label: x.workflow_status_name,
            value: x.id_workflow_status,
          };
          this.statusList.push(obj);
          if(['ROLE003', 'ROLE007'].includes(this.id_role_master)) {
            this.selectedStatus = 'MLTSTS01';
          }
        });
      },
      (err) => {
        // this.fetchGrade();
        console.log(err);
      }
    );
  }

  // Fetch Company
  // ========================= //
  fetchCompany() {
    this.loanService.getLoanCompanies().subscribe(
      (res) => {
        this.companies = [{ label: "Semua Perusahaan", value: null }];
        _.map(res["data"], (x) => {
          this.companies.push({ label: x.perusahaan, value: x.perusahaan });
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // Fetch Golongan KAI
  // ========================= //
  // fetchGolonganKai() {
  //   this.loanService.getLoanGolonganKai().subscribe(
  //     (res) => {
  //       this.golonganKaiList = [{ label: "Semua Golongan", value: null }];
  //       _.map(res["data"], (x) => {
  //         this.golonganKaiList.push({ label: x.golongan_affco, value: x.golongan_affco });
  //       });
  //     },
  //     (err) => {
  //       console.log(err);
  //     }
  //   );
  // }

  // Fetch Jenis Pinjaman All
  // ========================= //
  // fetchLoanTipeAll() {
  //   this.loanService.getLoanTipeAll().subscribe(
  //     (res) => {
  //       this.loanTypeAllList = [{ label: "Semua Pinjaman", value: null }];
  //       _.map(res["data"], (x) => {
  //         this.loanTypeAllList.push({ label: x.nama_pinjaman, value: x.kode_pinjaman });
  //       });
  //     },
  //     (err) => {
  //       console.log(err);
  //     }
  //   );
  // }

  paginate(e) {
    this.start = e.page + 1;
    this.fetchLoan();
  }

  // FILTER
  submitFilter(valFilter: any) {
    const {
      nama,
      id_koperasi,
      date_range,
      no_dokumen,
      id_workflow_status,
      golongan_affco,
      perusahaan,
      kode_pinjaman,
    } = valFilter;
    console.log(valFilter);
    let qName = nama != null ? `&name=${nama}` : "";
    let qIdKoperasi = id_koperasi != null ? `&id_koperasi=${id_koperasi}` : "";
    let qNoDokumen = no_dokumen != null ? `&no_dokumen=${no_dokumen}` : "";
    let qStatusLoan = id_workflow_status != undefined
        ? `&status_loan=${id_workflow_status}`
        : "";
    let qGolonganKai = golongan_affco != undefined ? `&golongan_affco=${golongan_affco}` : "";
    let qPerusahaan = perusahaan != undefined ? `&perusahaan=${perusahaan}` : "";
    let qKodePinjaman = kode_pinjaman != undefined ? `&kode_pinjaman=${kode_pinjaman}` : "";
    let qDateRange: string = "";
    if (date_range != undefined) {
      qDateRange =
      date_range[1] != null
          ? `&start_date=${moment(date_range[0]).format(
              "YYYY-MM-DD"
            )}&end_date=${moment(date_range[1]).format("YYYY-MM-DD")}`
          : `&start_date=${moment(date_range[0]).format(
              "YYYY-MM-DD"
            )}&end_date=${moment(date_range[0]).format("YYYY-MM-DD")}`;
    }
    this.objFilter = `${qName}${qIdKoperasi}${qNoDokumen}${qStatusLoan}${qGolonganKai}${qPerusahaan}${qKodePinjaman}${qDateRange}`;
    this.pagination.changePageToFirst(event);
    this.start = 1;
    this.fetchLoan();
  }

  resetFilter() {
    this.objFilter = "";
    this.pagination.changePageToFirst(event);
    this.fetchLoan();
    this.start = 1;
  }
}
