import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup } from "@angular/forms";
import { MessageService } from "primeng/components/common/messageservice";
import { MemberService } from "../../member/member.service";
import { LoanService } from "../loan.service";
import { store } from "../../../service/store.service";
import * as moment from "moment";
import * as _ from "lodash";
import JSZipUtils from "jszip-utils";
import * as JSZip from "jszip";
const { saveAs } = require("file-saver");

@Component({
  selector: "app-detail-loan",
  templateUrl: "./detail-loan.component.html",
  styleUrls: ["./detail-loan.component.scss"],
  providers: [MessageService],
})
export class DetailLoanComponent implements OnInit {
  public loadingData: boolean = true;
  public disablebtnSalary: boolean = false;
  public isUpdateDocs: boolean = false;
  public confirmUpdate: boolean = false;
  public isSimulationSubmit: boolean = false;
  public loan_type;
  public id_user;
  public id_loan;
  public id_role_master;
  public date: Date = null;
  public bunga: number = 1;
  public isPopupVisible: boolean;
  public isPopupReject: boolean;
  public formAddLoan: FormGroup;
  public formReject: FormGroup;
  public tipePinjaman = [
    { id: "multi_guna", title: "Multi Guna" },
    { id: "pendidikan", title: "Pendidikan" },
    { id: "cicilan_hrd", title: "Cicilan HRD-1" },
  ];

  // Member variable
  public data;
  public id_user_company_salary;
  public loading: boolean;
  public selectedItem = null;
  public active_date = false;
  public grades = [];
  public grades2 = [];
  public companies = [];
  public companies2 = [];
  public fees = [];
  public selectedCompany = null;
  public originGrades = [];
  public arrStatus = [];
  public arrReligion = [];
  public arrDomicile = [];
  public arrMariege = [];
  public arrRole = [];
  public arrlanNonCoperative = [];
  public selectedStatus = null;
  public current_loan_offset = null;
  public date1: Date = null;
  public date2: Date = null;
  public minDate = moment().add("days", -1)["_d"];
  public minDateValue = null;
  public isSubmitApprove: boolean = false;
  public isSubmitReject: boolean = false;
  public dataEdit: boolean = false;
  public totalCount: number = 0;
  public dataDisable = false;
  public start = 0;
  public pageLength = 10;
  public availabelColumn: Number;
  public widthDisplay: number;
  public roleId;
  public dataProfile = null;
  private objFilter = {};

  public displayForm: boolean = false;
  public displayReject: boolean = false;
  public isSubmitRegis: boolean = false;
  public statusAction: boolean = false;

  public name;
  public company;
  public email;
  public disbursement_date;
  public disbursement_date_edit;
  public disbursement_date_submit;
  public identity_photo;
  public company_identity_photo;
  public personal_photo;
  public phone_number;

  public approved_loan;
  public approved_loan_submit;
  public interest;

  public imageID = null;
  public imageKTP = null;
  public imagePersonal = null;

  public dataDocument: any = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private memberService: MemberService,
    private messageService: MessageService,
    private loanService: LoanService
  ) {
    store.subscribe(() => {
      // this.id_role_master = store.getState().auth.id_role_master;
      this.id_role_master = localStorage.getItem("id_role_master");
    });
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe((queryParams) => {
      this.loan_type = queryParams.get("type");
      this.id_user = queryParams.get("user");
      this.idEmployee = queryParams.get("idemployee");
    });
    this.route.params.subscribe((params) => {
      this.id_loan = params["id"];
    });
    this.getDownloadPath();
    let tmps = moment().format("YYYY-MM-DD HH:mm");
    this.minDateValue = new Date();
    this.widthDisplay = 1200;
    this.fetchProfileDetail();
    this.fetchDomicile();
    this.fetchMarriage();
    this.fetchReligion();
    this.fetchRole();
    this.fetchMasterDocumentType();
    this.fetchGrade();
    this.fetchStatus();
    this.fetchLoanNoncoperative();
    this.fetchLoanDetail(this.id_user, this.id_loan);
    this.fetchDocumentLoan(this.id_user, this.loan_type);
    this.id_role_master = localStorage.getItem("id_role_master");
  }

  fileUploaded: any = [];
  fileExtention: string;
  getDownloadPath() {
    let splitFiles: string = "";
    this.loanService.getPathDownloadDocument(this.id_loan).subscribe((res) => {
      this.dataDocument = res && res.data;
      if (
        this.dataDocument != null &&
        this.dataDocument.doc_uploaded_file != null
      ) {
        splitFiles = this.dataDocument.doc_uploaded_file.split(";");
        this.fileExtention = splitFiles[0].split(".").pop();
        this.fileUploaded = splitFiles;
      }
    });
  }

  // Fetch Loan Detail
  // ======================== //
  public dataDetailLoan = null;
  public selectedOffset;
  public arrayOffset;
  public oldInterest;
  public oldInterestChanged: boolean = false;
  public lihatOffset: boolean = false;
  fetchLoanDetail(idUser, idLoan) {
    this.loanService.getLoadDetail(idUser, idLoan).subscribe((res) => {
      if (res["status"] == 0) {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: res["message"],
        });
        this.loading = false;
        this.statusAction = true;
        return;
      }

      this.dataDetailLoan = res["data"];
      this.selectedOffset = 0;
      this.approved_loan = this.dataDetailLoan.current_loan.approved_loan;
      this.approved_loan_submit =
        this.dataDetailLoan.current_loan.approved_loan;
      this.interest = this.dataDetailLoan.current_loan.interest;
      this.disbursement_date = this.dataDetailLoan.current_loan
        .disbursement_date
        ? moment(this.dataDetailLoan.current_loan.disbursement_date).format(
            "YYYY-MM-DD"
          )
        : null;
      this.disbursement_date_edit = this.dataDetailLoan.current_loan
        .disbursement_date
        ? moment(this.dataDetailLoan.current_loan.disbursement_date).format(
            "YYYY-MM-DD"
          )
        : null;
      this.disbursement_date_submit = this.dataDetailLoan.current_loan
        .disbursement_date
        ? moment(this.dataDetailLoan.current_loan.disbursement_date).format(
            "YYYY-MM-DD"
          )
        : null;
      this.discount = this.dataDetailLoan.current_loan.discount;
      this.discount_edit = this.dataDetailLoan.current_loan.discount;
      this.discount_submit = this.dataDetailLoan.current_loan.discount;
      this.oldInterest = this.dataDetailLoan.current_loan.interest;
      this.term = this.dataDetailLoan.current_loan.term;
      this.term_edit = this.dataDetailLoan.current_loan.term;
      this.term_submit = this.dataDetailLoan.current_loan.term;
      this.statusAction = this.dataDetailLoan.is_approval_disabled;
      this.dataDetailLoan.rp_final_installment = this.dataDetailLoan
        .final_sum_installment
        ? "Rp " + this.dataDetailLoan.final_sum_installment.toLocaleString()
        : "Rp 0";
      this.dataDetailLoan.rp_installments =
        "Rp " +
        this.dataDetailLoan.current_loan.approved_installment.toLocaleString();
      this.dataDetailLoan.final_per_strength = parseFloat(
        this.dataDetailLoan.final_per_strength
      ).toFixed(2);
      this.dataDetailLoan.last_periode_date = this.dataDetailLoan.current_loan
        .last_payment_date
        ? moment(this.dataDetailLoan.current_loan.last_payment_date).format(
            "YYYY-MM-DD"
          )
        : null;
      this.dataDetailLoan.rp_total_interest = this.dataDetailLoan.current_loan
        .total_interest
        ? "Rp " +
          this.dataDetailLoan.current_loan.total_interest.toLocaleString()
        : "Rp 0";
      this.dataDetailLoan.rp_approved_loan = this.dataDetailLoan.current_loan
        .approved_loan
        ? "Rp " +
          this.dataDetailLoan.current_loan.approved_loan.toLocaleString()
        : "Rp 0";
      this.dataDetailLoan.rp_received_loan = this.dataDetailLoan.current_loan
        .received_loan
        ? "Rp " +
          this.dataDetailLoan.current_loan.received_loan.toLocaleString()
        : "Rp 0";
      this.dataDetailLoan.rp_final_val_unpaid = this.dataDetailLoan
        .final_val_unpaid
        ? "Rp " + this.dataDetailLoan.final_val_unpaid.toLocaleString()
        : "Rp 0";
      this.dataDetailLoan.rp_final_val_strength = this.dataDetailLoan
        .final_val_strength
        ? "Rp " + this.dataDetailLoan.final_val_strength.toLocaleString()
        : "Rp 0";
      this.dataDetailLoan.rp_requested_loan = this.dataDetailLoan.current_loan
        .requested_loan
        ? "Rp " +
          this.dataDetailLoan.current_loan.requested_loan.toLocaleString()
        : "Rp 0";
      this.dataDetailLoan.rp_total_installment = this.dataDetailLoan
        .current_sum_installment
        ? "Rp " + this.dataDetailLoan.current_sum_installment.toLocaleString()
        : "Rp 0";
      this.dataDetailLoan.rp_total_loan = this.dataDetailLoan.current_loan
        .loan_actual
        ? "Rp " + this.dataDetailLoan.current_loan.loan_actual.toLocaleString()
        : "Rp 0";
      this.dataDetailLoan.rp_total_offset = this.dataDetailLoan.current_loan
        .total_offset
        ? "Rp " + this.dataDetailLoan.current_loan.total_offset.toLocaleString()
        : "Rp 0";
      this.dataDetailLoan.rp_salary = this.dataDetailLoan.salary
        ? "Rp " + this.dataDetailLoan.salary.toLocaleString()
        : "";
      this.dataDetailLoan.rp_current_strength = this.dataDetailLoan
        .current_val_strength
        ? "Rp " + this.dataDetailLoan.current_val_strength.toLocaleString()
        : "Rp 0";
      this.dataDetailLoan.rp_final_installment = this.dataDetailLoan
        .final_sum_installment
        ? "Rp " + this.dataDetailLoan.final_sum_installment.toLocaleString()
        : "Rp 0";
      console.log("FEE 1", this.dataDetailLoan.current_loan.fee);
      if (this.dataDetailLoan.current_loan.fee) {
        console.log("FEE ");
        let tmps = this.dataDetailLoan.current_loan.fee;
        var result = Object.entries(tmps);
        console.log(result);
        this.fees = result;
      }

      // <!--status-->
      // <!--bunga (%)  = interest-->
      // <!--bunga total = total_interest-->
      // <!--Voucher = discount-->
      // <!--Tgl Pencairan = disbursement_date-->
      // <!--Pinjaman Diajukan = requested_loan-->
      // <!--Pinjaman Disetujui = approved_loan-->
      // <!--total Pinjaman = total_loan-->
      // <!--Tgl Angsuran Terakhir = last_periode-->
      // <!--Total offset = total_offset-->
      // <!--biaya = fee [array]-->
      console.log(this.dataDetailLoan.active_loan);
      _.map(this.dataDetailLoan.active_loan, (x) => {
        x.rp_installments = "Rp " + x.installments.toLocaleString();
        x.rp_approved_loan = "Rp " + x.approved_loan.toLocaleString();
        x.rp_paid_installment = "Rp " + x.paid_installment.toLocaleString();
        x.rp_unpaid_installment = "Rp " + x.unpaid_installment.toLocaleString();
        x.start_date = moment(x.start_date).format("DD MMM YYYY");
        x.end_date = moment(x.end_date).format("DD MMM YYYY");
      });

      if (this.dataDetailLoan.current_offset != null) {
        if (this.dataDetailLoan.current_offset.length > 0) {
          let semua_offset = this.dataDetailLoan.current_offset;
          this.arrayOffset = [];
          semua_offset.forEach((z, ind) => {
            let obj = { label: "Offset " + (ind + 1), value: ind };
            this.arrayOffset.push(obj);
          });

          let disini = this.dataDetailLoan.current_offset[this.selectedOffset];
          console.log("DISINI =>", disini);
          this.current_loan_offset = disini;
          this.current_loan_offset.loan_number = disini.loan_number;
          this.current_loan_offset.name_loan_type = disini.name_loan_type;
          this.current_loan_offset.workflow_status_name =
            disini.workflow_status_name;
          this.current_loan_offset.requested_loan = disini.requested_loan
            ? disini.requested_loan.toLocaleString()
            : "Rp 0";
          this.current_loan_offset.approved_loan = disini.approved_loan
            ? disini.approved_loan.toLocaleString()
            : "Rp 0";
          this.current_loan_offset.request_date = disini.request_date
            ? moment(disini.request_date).format("YYYY-MM-DD")
            : null;
          this.current_loan_offset.disbursment_date = disini.disbursment_date
            ? moment(disini.disbursment_date).format("YYYY-MM-DD")
            : null;
          this.current_loan_offset.paid_off_date = disini.paid_off_date
            ? moment(disini.paid_off_date).format("YYYY-MM-DD")
            : null;
          this.current_loan_offset.term_total = disini.term_total;
          this.current_loan_offset.term_paid = disini.term_paid;
          this.current_loan_offset.term_left = disini.term_left;
          this.current_loan_offset.installment = disini.installment
            ? disini.installment.toLocaleString()
            : "Rp 0";
          this.current_loan_offset.paid_installment = disini.paid_installment
            ? disini.paid_installment.toLocaleString()
            : "Rp 0";
          this.current_loan_offset.unpaid_installment =
            disini.unpaid_installment
              ? disini.unpaid_installment.toLocaleString()
              : "Rp 0";
        }
      }
      console.log("CURRENT OFFSET >", this.current_loan_offset);
      this.loadingData = false;
      this.sallary = res["data"].salary;
      this.loading = false;
      this.active_date = true;
    });
  }
  gantiOffset() {
    if (this.dataDetailLoan.current_offset.length > 0) {
      let disini = this.dataDetailLoan.current_offset[this.selectedOffset];
      console.log("DISINI =>", disini);
      this.current_loan_offset = disini;
      this.current_loan_offset.loan_number = disini.loan_number;
      this.current_loan_offset.name_loan_type = disini.name_loan_type;
      this.current_loan_offset.workflow_status_name =
        disini.workflow_status_name;
      this.current_loan_offset.requested_loan = disini.requested_loan
        ? disini.requested_loan.toLocaleString()
        : "Rp 0";
      this.current_loan_offset.approved_loan = disini.approved_loan
        ? disini.approved_loan.toLocaleString()
        : "Rp 0";
      this.current_loan_offset.request_date = disini.request_date
        ? moment(disini.request_date).format("YYYY-MM-DD")
        : null;
      this.current_loan_offset.disbursment_date = disini.disbursment_date
        ? moment(disini.disbursment_date).format("YYYY-MM-DD")
        : null;
      this.current_loan_offset.paid_off_date = disini.paid_off_date
        ? moment(disini.paid_off_date).format("YYYY-MM-DD")
        : null;
      this.current_loan_offset.term_total = disini.term_total;
      this.current_loan_offset.term_paid = disini.term_paid;
      this.current_loan_offset.term_left = disini.term_left;
      this.current_loan_offset.installment = disini.installment
        ? disini.installment.toLocaleString()
        : "Rp 0";
      this.current_loan_offset.paid_installment = disini.paid_installment
        ? disini.paid_installment.toLocaleString()
        : "Rp 0";
      this.current_loan_offset.unpaid_installment = disini.unpaid_installment
        ? disini.unpaid_installment.toLocaleString()
        : "Rp 0";
    }
  }
  // Document Loan
  // ========================== //
  public selectedDocumentLoan = null;
  public displayDocument: boolean = false;
  public isViewDocumentLoan: boolean = false;
  public arrDocumentDetailLoan = [];
  fetchDocumentLoan(idUser, loanType) {
    this.loanService.getLoadDocument(idUser, loanType).subscribe((res) => {
      this.arrDocumentDetailLoan = res["data"];
    });
  }
  viewDocumentLoan(e) {
    this.displayDocument = true;
    this.selectedDocumentLoan = e;
    this.isViewDocumentLoan = true;
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 100);
  }
  closeViewDetailDocument() {
    this.isViewDocumentLoan = false;
    this.selectedDocumentLoan = null;
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 1000);
  }

  // Select Item / User
  // ======================== //
  fetchProfileDetail() {
    this.loading = true;
    this.memberService.getUserDetail(this.id_user).subscribe((res) => {
      this.dataProfile = res["data"];
      console.log(this.dataProfile, "dataProfile");
      let findStatus = _.find(this.arrStatus, {
        value: res["data"].user.id_workflow_status,
      });
      if (findStatus) res["data"].user.status_name = findStatus.label;

      let findReligion = _.find(this.arrReligion, {
        id_religion: res["data"].profile.id_religion,
      });
      if (findReligion)
        res["data"].profile.name_religion = findReligion.name_religion;

      let findMarriage = _.find(this.arrMariege, {
        id_marriage_status: res["data"].profile.id_marriage_status,
      });
      if (findMarriage)
        res["data"].profile.marriage_status_name =
          findMarriage.marriage_status_name;

      let findDomicili = _.find(this.arrDomicile, {
        id_domicile_address_status:
          res["data"].profile.id_domicile_address_status,
      });
      if (findDomicili)
        res["data"].profile.domicili_name =
          findDomicili.name_domicile_address_status;

      let findGrade = _.find(this.grades2, {
        value: res["data"].company.id_grade,
      });
      if (findGrade) res["data"].company.grade_name = findGrade.label;
      let { grades2 } = this;
      console.log({ findGrade, grades2 });
      console.log("Data Company =>", res["data"].company);

      setTimeout(() => {
        let findRole = _.find(this.arrRole, {
          id_role_master: res["data"].user.id_role_master,
        });
        if (findRole)
          res["data"].user.role_master_name = findRole.name_role_master;

        if (res["data"].salary) {
          let findGradeSlary = _.find(this.grades2, {
            value: res["data"].salary.id_grade,
          });
          if (findGradeSlary)
            res["data"].salary.salary_grade_name = findGradeSlary.label;
        }

        this.selectedCompany2 = this.dataProfile.company.id_company;
        this.position = this.dataProfile.company.position;
        this.division = this.dataProfile.company.division;
      }, 1000);
      this.fetchSallary(this.id_user);
      this.fetchCompany();
    });
  }
  tabChange() {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 100);
  }

  // Create Mutation Employee
  // ======================== //
  public isSubmitMutation: boolean = false;
  public idEmployee = null;
  public division = null;
  public position = null;
  public selectedCompany2 = null;
  public imgEmployee = null;
  public display: boolean = false;
  createMutation() {
    this.isSubmitMutation = true;
    let obj = {
      id: this.selectedItem.id_user,
      id_employee: this.idEmployee,
      id_company: this.selectedCompany2,
      company_identity_photo: this.imgEmployee,
      division: this.division,
      position: this.position,
    };
    this.memberService.updateMutation(obj).subscribe(
      (res) => {
        this.isSubmitMutation = false;
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Mutasi karyawan berhasil",
        });
        this.display = false;
      },
      (err) => {
        this.isSubmitMutation = false;
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Mutasi karyawan gagal, silakan coba lagi",
        });
      }
    );
  }

  // Select Company Mutation Employee
  // ============================== //
  changeCompany2(e) {
    this.selectedCompany2 = e.value;
  }

  onChangeValue(val) {
    var checkedNew = val.split(",").join("");
    checkedNew = checkedNew.split(".").join("");
    if (
      parseInt(checkedNew) > this.dataDetailLoan.current_loan.requested_loan
    ) {
      this.approved_loan = this.dataDetailLoan.current_loan.requested_loan;
    }
    console.log(checkedNew);
  }
  // Fetch Salary
  // ============================ //
  fetchSallary(id) {
    this.memberService.getSallary(id).subscribe(
      (res) => {
        this.dataProfile["salary"] = res["data"];
        this.dataProfile["salary"].amount = Number(res["data"].salary_amount);
        this.id_user_company_salary = Number(
          res["data"].id_user_company_salary
        );
        this.selectedGrade = res["data"].id_grade;
        this.dataProfile["salary"].rp_amount = Number(
          res["data"].salary_amount
        ).toLocaleString();
        this.dataProfile["salary"].last_updated_grade = res["data"]
          .last_updated_grade
          ? moment(res["data"].last_updated_grade).format("DD MMM YYYY HH:mm")
          : "-";
        this.dataProfile["salary"].last_updated = res["data"]
          .last_updated_salary.date
          ? moment(res["data"].last_updated_salary.date).format(
              "DD MMM YYYY HH:mm"
            )
          : "-";

        this.fetchBank(id);
      },
      (err) => {
        if (err.status == 401)
          this.memberService.updateToken(err.error.data.token);
      }
    );
  }

  // Fetch Bank
  // ============================ //
  fetchBank(id) {
    this.memberService.getBank(id).subscribe(
      (res) => {
        this.dataProfile["bank"] = res["data"];
        this.fetchDocument(id);
      },
      (err) => {
        if (err.status == 401)
          this.memberService.updateToken(err.error.data.token);
      }
    );
  }

  // Fetch Document
  // ============================ //
  public isViewDocument: boolean = false;
  public openTabDocument: boolean = false;
  public selectedDocument = null;
  public imgDocument = null;
  public isSubmitDocument: boolean = false;
  public arrDocumentType = [];
  public selectedDocumentType = null;
  public isUpdateDocument: boolean = false;

  fetchDocument(id) {
    this.memberService.getDocument(id).subscribe(
      (res) => {
        res["data"].map((x) => {
          x["disable"] = false;

          let find = _.find(this.arrDocumentType, {
            value: x.id_document_type,
          });
          x["document_name"] = find ? find.label : null;
        });
        this.dataProfile["document"] = res["data"];
        this.loading = false;
        this.openTabDocument = false;
        setTimeout(() => {
          window.dispatchEvent(new Event("resize"));
        }, 100);
      },
      (err) => {
        if (err.status == 401)
          this.memberService.updateToken(err.error.data.token);
      }
    );
  }
  removeDocument(e) {
    this.selectedDocument = e;
    this.selectedDocument.disable = true;
    this.memberService
      .deleteDocument(
        Number(this.selectedDocument.id_user),
        this.selectedDocument.id_user_document
      )
      .subscribe(
        (res) => {
          this.fetchDocument(Number(this.selectedDocument.id_user));
          this.selectedDocument = null;
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Berhasil hapush dokumen",
          });
        },
        (err) => {
          this.selectedDocument.disable = false;
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: "Gagal hapush dokumen",
          });
        }
      );
  }
  viewDocument(e) {
    this.widthDisplay = 800;
    this.selectedDocument = e;
    this.isViewDocument = true;
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 100);
  }
  closeViewDocument() {
    this.widthDisplay = 1200;
    this.isViewDocument = false;
    this.openTabDocument = true;
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 100);
  }
  changeDocumentType(e) {
    this.selectedDocumentType = e.value;
  }
  cancelUpdate() {
    this.confirmUpdate = false;
    this.isSubmitDocument = false;
  }
  updateDocProses() {
    let obj = {
      id: this.id_user,
      id_document_type: this.selectedDocumentType,
      doc_photo: this.imgDocument,
    };
    this.loanService.updateDocument(obj).subscribe(
      (res) => {
        this.isSubmitDocument = false;
        this.confirmUpdate = false;
        this.fetchDocument(this.id_user);
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Berhasil Update dokumen",
        });
        this.cancelUpdateDoc();
      },
      (err) => {
        this.confirmUpdate = false;
        this.isSubmitDocument = false;
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Gagal tambah dokumen",
        });
      }
    );
  }
  createDocument() {
    let obj = {
      id: this.id_user,
      id_document_type: this.selectedDocumentType,
      doc_photo: this.imgDocument,
    };
    this.isSubmitDocument = true;
    console.log(obj);
    this.loanService.postDocumentAdd(obj).subscribe(
      (res) => {
        this.isSubmitDocument = false;
        this.fetchDocument(this.id_user);
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Berhasil tambah dokumen",
        });
        this.cancelUpdateDoc();
      },
      (err) => {
        this.confirmUpdate = true;
      }
    );
    //
    // if(this.isUpdateDocument){

    // }else{
    // 	this.memberService.postDocument(obj).subscribe(res =>{
    // 		this.isSubmitDocument = false;
    // 		this.fetchDocument(this.selectedItem.id_user);
    // 		this.messageService.add({severity:'success', summary: 'Success', detail:'Berhasil tambah dokumen'});
    // 	}, err =>{
    // 		this.isSubmitDocument = false;
    // 		this.messageService.add({severity:'error', summary: 'Error', detail:'Gagal tambah dokumen'});
    // 	});
    // }
  }
  handleInputChange(e) {
    this.imgDocument = e.path;
  }
  readThis(e) {
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    var cw = canvas.width;
    var ch = canvas.height;
    var maxW = 1000;
    var maxH = 1000;
    var img = new Image();
    img.onload = () => {
      var iw = img.width;
      var ih = img.height;
      var scale = Math.min(maxW / iw, maxH / ih);
      var iwScaled = iw * scale;
      var ihScaled = ih * scale;
      canvas.width = iwScaled;
      canvas.height = ihScaled;
      ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
      let tmp = canvas.toDataURL("image/png", 0.5);
      this.imgDocument = tmp;
    };
    img.src = URL.createObjectURL(e[0]);
  }
  editDocument(e) {
    this.isUpdateDocument = true;
    this.selectedDocumentType = e.id_document_type;
    this.imgDocument = e.path;
  }
  cancelUpdateDoc() {
    this.isUpdateDocument = false;
    this.imgDocument = null;
  }

  // Update Sallary
  // ========================= //
  public loadingUpdateSallary: boolean = false;
  public selectedGrade = null;

  updateSallary() {
    this.loadingUpdateSallary = true;
    let obj = {
      id_grade: this.selectedGrade,
      id: this.id_user,
      id_user_company_salary: this.id_user_company_salary,
      salary_amount: this.dataProfile.salary.amount.toString(),
    };
    this.memberService.updateDirectSalary(obj).subscribe(
      (res) => {
        this.loadingUpdateSallary = false;
        if (res["status"] == 0) {
          this.fetchLoanDetail(this.id_user, this.id_loan);
          this.disablebtnSalary = true;
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: res["message"],
          });
        } else {
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: res["message"],
          });
          this.fetchLoanDetail(this.id_user, this.id_loan);
          this.fetchSallary(this.id_user);
        }
      },
      (err) => {
        console.log(err);
        this.loadingUpdateSallary = false;
      }
    );
  }

  // Pinjaman Aktif non koperasi
  // ========================= //
  public selectedNonCoperate = null;
  public total_pinjaman = 0;
  public total_tagihan = 0;
  public mulai_pinjaman = new Date();
  public akhir_pinjaman = new Date();
  public loadingPostLoanCooperactive: boolean = false;

  saveLoanCooperative() {
    let obj = {
      id_user: this.id_user,
      id_type_pinjaman_non_koperasi: this.selectedNonCoperate,
      id_workflow_status: "MLTSTS07",
      amount_per_month: this.total_tagihan,
      amount_total: this.total_pinjaman,
      starting_date: moment(this.mulai_pinjaman).format("YYYY-MM-DD"),
      end_date: moment(this.akhir_pinjaman).format("YYYY-MM-DD"),
    };
    this.loadingPostLoanCooperactive = true;
    this.loanService.postPostLoanNoncooperative(obj).subscribe(
      (res) => {
        this.loadingPostLoanCooperactive = false;
        this.isPopupVisible = false;
        this.fetchLoanDetail(this.id_user, this.id_loan);
        this.fetchDocumentLoan(this.id_user, this.loan_type);
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Data pinjaman non koperasi berhasil disimpan.",
        });
      },
      (err) => {
        this.loadingPostLoanCooperactive = false;
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail:
            "Data pinjaman non koperasi gagal disimpan., silakan coba lagi",
        });
      }
    );
  }

  // Approve Loan
  // ========================= //
  public tanggal_pencairan = new Date();
  public discount = 0;
  public discount_edit = 0;
  public discount_submit = 0;
  public term = 0;
  public term_edit = 0;
  public term_submit = 0;
  public sallary = 1;
  public grade = 1;
  public loadingSubmitApprove: boolean = false;
  public loadingSubmitReject: boolean = false;

  submitApproveLoan() {
    if (this.id_role_master == "ROLE904" && !this.disbursement_date_edit) {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Mohon isi Tanggal Pencairan",
      });
      return;
    }
    let obj;
    if (this.oldInterestChanged) {
      obj = {
        id_loan: this.id_loan,
        approved_loan: this.approved_loan_submit,
        term: this.term_submit,
        disbursement_date: this.disbursement_date_submit
          ? moment(this.disbursement_date_submit).format("YYYY-MM-DD")
          : null,
        discount: this.discount_submit,
      };
    } else {
      obj = {
        id_loan: this.id_loan,
        approved_loan: this.approved_loan_submit,
        term: this.term_submit,
        discount: this.discount_submit,
        disbursement_date: this.disbursement_date_submit
          ? moment(this.disbursement_date_submit).format("YYYY-MM-DD")
          : null,
      };
    }

    console.log(obj);

    this.loadingSubmitApprove = true;
    this.loanService.postApproveLoan(obj).subscribe(
      (res) => {
        if (res["status"] == 1) {
          this.loading = true;
          this.statusAction = true;
          this.fetchLoanDetail(this.id_user, this.id_loan);
          this.fetchDocumentLoan(this.id_user, this.loan_type);
          this.loadingSubmitApprove = false;
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Pengajuan pinjaman berhasil disetujui.",
          });
        } else {
          this.loadingSubmitApprove = false;
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: res["message"],
          });
        }
      },
      (err) => {
        console.log(err);
        this.loadingSubmitApprove = false;
        this.messageService.add({
          severity: "error",
          summary: "Pengajuan pinjaman gagal disetujui, silakan coba lagi.",
          detail: err.error.message,
        });
      }
    );
  }

  public reasonReject = "";
  submitApproveReject() {
    let obj = {
      id_loan: this.id_loan,
      notes: this.reasonReject,
    };
    this.loadingSubmitReject = true;
    this.loanService.postRejectLoan(obj).subscribe(
      (res) => {
        this.loading = true;
        this.statusAction = true;
        this.fetchLoanDetail(this.id_user, this.id_loan);
        this.fetchDocumentLoan(this.id_user, this.loan_type);
        this.loadingSubmitReject = false;
        this.isPopupReject = false;
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Pengajuan pinjaman berhasil ditolak.",
        });
      },
      (err) => {
        console.log(err);
        this.loadingSubmitReject = false;
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Pengajuan pinjaman gagal ditolak, silakan coba lagi.",
        });
      }
    );
  }

  postSimulator() {
    this.isSimulationSubmit = true;
    let data;
    if (this.disbursement_date_edit != null) {
      data = {
        id_loan: this.id_loan,
        principal: this.approved_loan,
        period: this.term_edit,
        interest: this.discount_edit,
        disbursement_date: this.disbursement_date_edit,
      };
    } else {
      data = {
        id_loan: this.id_loan,
        principal: this.approved_loan,
        period: this.term_edit,
        interest: this.discount_edit,
      };
    }

    if (this.oldInterest != this.interest) {
      this.oldInterestChanged = true;
    } else {
      this.oldInterestChanged = false;
    }
    console.log(data);
    this.loanService.postLoanSimulation(data).subscribe(
      (res) => {
        this.dataEdit = false;
        this.isSimulationSubmit = false;
        if (res["status"] == 0) {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: res["message"],
          });
        } else {
          this.approved_loan_submit = this.approved_loan;
          this.discount_submit = this.discount_edit;
          let data_res = res["data"];
          console.log(data_res);
          this.dataDetailLoan.current_loan.term = data_res.term;
          this.term = this.dataDetailLoan.current_loan.term;
          this.term_edit = this.dataDetailLoan.current_loan.term;
          this.term_submit = this.dataDetailLoan.current_loan.term;
          this.dataDetailLoan.rp_received_loan = data_res.received
            ? "Rp " + data_res.received.toLocaleString()
            : "Rp 0";
          this.disbursement_date = data_res.disbursement_date
            ? moment(data_res.disbursement_date).format("YYYY-MM-DD")
            : null;
          this.disbursement_date_edit = data_res.disbursement_date
            ? moment(data_res.disbursement_date).format("YYYY-MM-DD")
            : null;
          this.disbursement_date_submit = data_res.disbursement_date
            ? moment(data_res.disbursement_date).format("YYYY-MM-DD")
            : null;
          // this.disbursement_date = data_res.disbursement_date;
          this.dataDetailLoan.rp_installments =
            "Rp " + data_res.installments.toLocaleString();
          this.dataDetailLoan.last_periode_date = data_res.last_periode
            ? data_res.last_periode
            : null;
          this.dataDetailLoan.rp_total_interest = data_res.total_interest
            ? "Rp " + data_res.total_interest.toLocaleString()
            : "Rp 0";
          this.discount = data_res.discount;
          this.dataDetailLoan.rp_approved_loan = data_res.loan_approved
            ? "Rp " + data_res.loan_approved.toLocaleString()
            : "Rp 0";
          // this.dataDetailLoan.rp_requested_loan = data_res.requested_loan ? 'Rp ' + data_res.requested_loan.toLocaleString(): '';
          // this.dataDetailLoan.rp_total_installment = this.dataDetailLoan.current_sum_installment ? 'Rp ' + this.dataDetailLoan.current_sum_installment.toLocaleString(): '';
          this.dataDetailLoan.rp_total_loan = data_res.total_loan
            ? "Rp " + data_res.total_loan.toLocaleString()
            : "Rp 0";
          this.dataDetailLoan.rp_total_offset = data_res.total_offset
            ? "Rp " + data_res.total_offset.toLocaleString()
            : "Rp 0";
          // this.dataDetailLoan.rp_salary = this.dataDetailLoan.salary ? 'Rp ' + this.dataDetailLoan.salary.toLocaleString(): '';
          // this.dataDetailLoan.rp_current_strength = this.dataDetailLoan.current_val_strength ? 'Rp ' + this.dataDetailLoan.current_val_strength.toLocaleString(): '';
          // this.dataDetailLoan.rp_final_installment = this.dataDetailLoan.final_sum_installment ? 'Rp ' + this.dataDetailLoan.final_sum_installment.toLocaleString(): '';
          console.log(this.dataDetailLoan);
          if (data_res.fee) {
            let tmps = data_res.fee;
            var result = Object.entries(tmps);
            this.fees = result;
          }
        }
      },
      (err) => {}
    );
  }

  changeValue() {
    this.dataEdit = true;
    // this.interest =
    this.approved_loan = this.dataDetailLoan.current_loan.approved_loan;
    // this.discount =
  }
  resetDiscount() {
    this.discount_edit = 0;
  }
  // Fetch Master
  // ========================= //
  fetchCompany() {
    this.memberService.getCompany().subscribe(
      (res) => {
        this.companies = [{ label: "Semua Perusahaan", value: null }];
        _.map(res["data"], (x) => {
          this.companies.push({ label: x.name_company, value: x.id_company });
        });

        try {
          let findCompany = _.find(this.companies, {
            value: this.dataProfile.company.id_company,
          });
          if (findCompany)
            this.dataProfile.company.company_name = findCompany.label;
        } catch (err) {
          console.log(err);
        }
      },
      (err) => {
        if (err.status == 401)
          this.memberService.updateToken(err.error.data.token);
      }
    );

    this.memberService.getCompany2().subscribe(
      (res) => {
        _.map(res["data"], (x) => {
          this.companies2.push({ label: x.name_company, value: x.id_company });
        });
      },
      (err) => {
        if (err.status == 401)
          this.memberService.updateToken(err.error.data.token);
      }
    );
  }
  fetchStatus() {
    this.memberService.getStatus().subscribe(
      (res) => {
        this.arrStatus = [{ label: "Semua Status", value: null }];
        _.map(res["data"], (x) => {
          let obj = {
            label: x.workflow_status_name,
            value: x.id_workflow_status,
          };
          this.arrStatus.push(obj);
        });

        _.map(this.data, (x) => {
          x["status_name"] = _.find(this.arrStatus, {
            value: x.id_workflow_status,
          }).label;
        });
      },
      (err) => {
        if (err.status == 401)
          this.memberService.updateToken(err.error.data.token);
      }
    );
  }
  fetchGrade() {
    this.memberService.getGrade().subscribe(
      (res) => {
        this.originGrades = res["data"];
        this.grades2 = [];
        _.map(res["data"], (x) => {
          let obj = { label: x.name_grade, value: x.id_grade };
          this.grades.push(obj);
          this.grades2.push(obj);
        });
        this.selectedGrade = this.grades[0].value;
      },
      (err) => {
        if (err.status == 401)
          this.memberService.updateToken(err.error.data.token);
      }
    );
  }
  fetchDomicile() {
    this.memberService.getDomicile().subscribe(
      (res) => {
        this.arrDomicile = res["data"];
      },
      (err) => {
        if (err.status == 401)
          this.memberService.updateToken(err.error.data.token);
      }
    );
  }
  fetchReligion() {
    this.memberService.getReligion().subscribe(
      (res) => {
        this.arrReligion = res["data"];
      },
      (err) => {
        if (err.status == 401)
          this.memberService.updateToken(err.error.data.token);
      }
    );
  }
  fetchMarriage() {
    this.memberService.getMarriage().subscribe(
      (res) => {
        this.arrMariege = res["data"];
      },
      (err) => {
        if (err.status == 401)
          this.memberService.updateToken(err.error.data.token);
      }
    );
  }
  fetchRole() {
    this.memberService.getRole().subscribe(
      (res) => {
        this.arrRole = res["data"];
      },
      (err) => {
        if (err.status == 401)
          this.memberService.updateToken(err.error.data.token);
      }
    );
  }
  fetchMasterDocumentType() {
    this.memberService.getMstDocument().subscribe(
      (res) => {
        _.map(res["data"], (x) => {
          let obj = { label: x.document_name, value: x.id_document_type };
          this.arrDocumentType.push(obj);
        });
        this.selectedDocumentType = this.arrDocumentType[0].value;
      },
      (err) => {
        if (err.status == 401)
          this.memberService.updateToken(err.error.data.token);
      }
    );
  }
  fetchLoanNoncoperative() {
    // this.loanService.getNoncoperateLoan().subscribe(res =>{
    // 	_.map(res['data'], (x)=>{
    // 		let obj = {label:x.name_pinjaman_
    //       _koperasi,value:x.id_type_pinjaman_non_koperasi};
    // 		this.arrlanNonCoperative.push(obj);
    // 	});
    // });
  }

  loadingBtnReject: boolean = false;
  isReject: boolean = false;
  notes: string = "";
  submitApprove(val) {
    this.loadingBtnReject = true;
    const params: any = {
      id_loan: this.id_loan,
      notes: val.notes,
    };
    this.isReject
      ? (params.id_workflow_status = "MLTREJECTDOC")
      : (params.id_workflow_status = "MLTAPPROVEDOC");
    this.loanService.postNoteReject(params).subscribe(
      (res) => {
        if (res.status == 1) {
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: res.message,
          });
          this.notes = "";
          this.getDownloadPath();
        }
        this.isReject = false;
        this.displayReject = false;
        this.loadingBtnReject = false;
      },
      (err: Response) => {
        this.loadingBtnReject = false;
        console.log(err.statusText);
      }
    );
  }

  loadingDownload: Boolean = false;
  async downloadDocument() {
    this.loadingDownload = true;
    var zip = new JSZip();
    let splitFiles = this.dataDocument.doc_uploaded_file.split(";");
    let the_arr: any;
    let nameFromURL = [];
    let count2 = 0,
      count = 0;
    for (var i = 0; i < splitFiles.length; i++) {
      the_arr = splitFiles[i].split("/");
      nameFromURL[i] = the_arr.pop();
    }

    _.map(splitFiles, (val: any) => {
      let filename = nameFromURL[count2];
      count2++;
      JSZipUtils.getBinaryContent(val, function (err, data) {
        if (err) {
          throw err; // or handle the error
        }
        zip.file(filename, data, { binary: true });
        count++;
        console.log(count == splitFiles.length);

        if (count == splitFiles.length) {
          zip.generateAsync({ type: "blob" }).then(function (content) {
            saveAs(content, "Dokumen Loan.zip");
          });
        }
      });
    });
    setTimeout(() => {
      this.loadingDownload = false;
    }, 1300);
  }
}
