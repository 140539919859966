import { Component, OnInit } from "@angular/core";
import { MemberService } from "../../member/member.service";
import { MasterService } from "../master.service";
import { MessageService } from "primeng/components/common/messageservice";
import * as _ from "lodash";
import * as moment from "moment";
import { LoanService } from "../../loan/loan.service";

@Component({
  selector: "app-core-product",
  templateUrl: "./core-product.component.html",
  styleUrls: ["./core-product.component.scss"],
  providers: [MessageService],
})
export class CoreProductComponent implements OnInit {
  public data: any[] = [];
  public columns: any = [];
  public activePaycuts: any = [];
  public display = false;
  public displayImg = false;
  public isAddMulai = false;
  public isEditMulai = false;
  public approveDialoge = false;
  public rejectDialoge = false;
  public addModal = false;
  public selectedColumns: any[];
  public loading: boolean = false;
  public selectedItem = null;
  public selectedImg = null;
  public grades = [];
  public grades2 = [];
  public selectedGrade = null;
  public companies = [];
  public all_companies = [];
  public roles = [];
  public list_unit = [];
  public companies2 = [];
  public all_loan_type = [];
  public selectedCompany = null;
  public originGrades = [];
  public arrStatus = [];
  public arrReligion = [];
  public masterDocument = [];
  public filteredDocument = [];
  public activeDocument = [];
  public id_documents = [];
  public allCoreTerm = [];
  public all_term = [];
  public arrDomicile = [];
  public arrMariege = [];
  public MasterFee = [];
  public coreProduct = [];
  public statusOpsi = [];
  public arrRole = [];
  public selectedStatus = null;
  public date: Date = null;
  public date1: Date = null;
  public date2: Date = null;
  public minDate = moment().add("days", -1)["_d"];
  public isSubmitApprove = false;
  public isSubmitReject = false;
  public viewDetail = false;
  public totalCount = 0;
  public start = 0;
  public pageLength = 10;
  public availabelColumn: Number;
  public widthDisplay: number;
  public roleId;
  public status = "";
  public id_company = "";
  public id_loan_type = "";
  public id_max_term = "";
  public fee_name = "";
  public min_plafond = "";
  public product_name = "";
  public max_plafond = "";
  public is_bonus_paycut = false;
  public is_down_payment = false;
  public down_payment = null;
  public interests = [];
  public activeInterests = [];
  public activeFees = [];
  public fees = [];
  public paycuts = [];
  public term_name = "";
  public loan_type_name = "";
  public term_count = "";
  public unit_value = "";
  public is_kopnit = 0;
  public kopnitArray = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];
  public isDigisign = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];
  public name_company = "";
  public phone_number = "";
  public document_name = "";
  public loan_power = "";
  public id_holding = "HLD001";
  public address = "";
  public dataProfile = null;
  private objFilter = {};
  public displayForm = false;
  public isSubmitRegis = false;
  public name;
  public company;
  public widthTable = 1000;
  public email;
  public identity_photo;
  public company_identity_photo;
  public personal_photo;
  public role;
  public imageID = null;
  public imageKTP = null;
  public imagePersonal = null;
  listPaycuts: any[];
  loadingContractTemplate: boolean = false;
  arrContractTemplate: any[] = [];
  selectedDigisign: boolean = false;
  selectedContractTemplate: string = null;

  constructor(
    private loanService: LoanService,
    private messageService: MessageService,
    private masterService: MasterService,
    private memberService: MemberService
  ) {}

  ngOnInit() {
    // this.fetchComapany();
    this.listPaycuts = [
      { label: "Januari", cut_period: 1, status: true, cut_multiplier: "" },
      { label: "Februari", cut_period: 2, status: true, cut_multiplier: "" },
      { label: "Maret", cut_period: 3, status: true, cut_multiplier: "" },
      { label: "April", cut_period: 4, status: true, cut_multiplier: "" },
      { label: "Mei", cut_period: 5, status: true, cut_multiplier: "" },
      { label: "Juni", cut_period: 6, status: true, cut_multiplier: "" },
      { label: "Juli", cut_period: 7, status: true, cut_multiplier: "" },
      { label: "Agustus", cut_period: 8, status: true, cut_multiplier: "" },
      { label: "September", cut_period: 9, status: true, cut_multiplier: "" },
      { label: "Oktober", cut_period: 10, status: true, cut_multiplier: "" },
      { label: "November", cut_period: 11, status: true, cut_multiplier: "" },
      { label: "Desember", cut_period: 12, status: true, cut_multiplier: "" },
    ];

    this.fetchProduct(1);
    this.fetchGrade();
    this.fetchDocument();
    this.fetchLoanType();
    this.fetchTerm();

    this.roleId = localStorage.getItem("id_role_master");
    this.widthDisplay = 1200;
    this.list_unit = [{ label: "0 - Other", value: 0 }];
    this.list_unit.push({ label: "1 - Currency", value: 1 });
    this.list_unit.push({ label: "2 - Percentage", value: 2 });
    this.list_unit.push({ label: "3 - Permil", value: 3 });

    this.columns = [
      { field: "product_name", header: "Product Name", show: true },
      { field: "loan_type_name", header: "Loan Type Name", show: false },
      { field: "min_term_count", header: "Min Tenor Count", show: false },
      { field: "max_term_count", header: "Max Tenor Count", show: false },
      { field: "min_plafond", header: "Min Plafond", show: false },
      { field: "max_plafond", header: "Max Plafond", show: false },
      { field: "id_product_opusb", header: "ID OpusB", show: true },
      { field: "is_digisign", header: "Digisign", show: true },
      { field: "contract_template", header: "Contract Template", show: true },
    ];

    this.availabelColumn = _.filter(this.columns, { show: true }).length + 1;
    this.selectedColumns = _.filter(this.columns, { show: true });
  }

  getListStatus() {
    this.selectedStatus = "";
    setTimeout(() => {
      _.forEach(this.listPaycuts, (x) => {
        x.status = true;
        x.cut_multiplier = "";
      });
    }, 400);
  }

  changeMonth(val: any) {
    setTimeout(() => {
      _.forEach(this.listPaycuts, (x: any) => {
        x.status = true;
        x.cut_multiplier = "";
      });

      val.status = false;
    }, 400);
  }
  // Save
  // ========================= //
  save(e) {
    this.isSubmitRegis = true;
    e["identity_photo"] = this.imageID.imageSrc;
    e["company_identity_photo"] = this.imageKTP.imageSrc;
    e["personal_photo"] = this.imagePersonal.imageSrc;
    e["role"] = "ROLE001";

    this.memberService.postUser(e).subscribe(
      (res) => {
        this.isSubmitRegis = false;
        this.displayForm = false;
        this.fetchProduct(1);
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "User has been added",
        });
      },
      (err) => {
        console.log(err);

        this.isSubmitRegis = false;
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Please try again",
        });
      }
    );
  }
  // Toggle Columns
  // ====================== //
  changeColumn(e) {
    const find = _.find(this.columns, e.itemValue);
    if (e.itemValue != undefined) {
      if (e.itemValue.show) {
        find.show = false;
      } else {
        find.show = true;
      }
    }
    this.availabelColumn = _.filter(this.columns, { show: true }).length + 1;
    this.selectedColumns = _.filter(this.columns, { show: true });
  }

  // Convert Image
  // ========================= //
  private typeupload = null;
  handleInputChange(e, type) {
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    const pattern = /image-*/;
    const reader = new FileReader();
    if (file != undefined) {
      if (!file.type.match(pattern)) {
        alert("invalid format");
        return;
      }
    }

    this.typeupload = type;
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  _handleReaderLoaded(e, type) {
    const reader = e.target;
    switch (this.typeupload) {
      case "company":
        this.imgEmployee = reader.result;
        break;
      case "document":
        this.imgDocument = reader.result;
        break;
    }

    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 500);
  }
  pilihImg(val) {
    this.selectedImg = val;
    this.displayImg = true;
  }
  // Fetching User
  // ========================= //
  fetchComapany() {
    this.loanService.getCompany().subscribe(
      (res) => {
        this.all_companies = this.companies;
        this.companies = [{ label: "Semua Perusahaan", value: null }];
        this.companies2 = [];
        _.map(res["data"], (x) => {
          this.companies.push({ label: x.name_company, value: x.id_company });
          this.companies2.push({ label: x.name_company, value: x.id_company });
        });
      },
      (err) => {
        console.log(err);
        // this.fetchLoan();
      }
    );
  }

  paginate(e) {
    this.start = e.page * this.pageLength;
    this.fetchProduct(e.page + 1);
  }

  approveItem(val) {
    this.approveDialoge = true;
    this.selectedItem = val;
  }

  rejectItem(val) {
    this.rejectDialoge = true;
    this.selectedItem = val;
  }

  tambahNew() {
    this.product_name = "";
    this.id_loan_type = "";
    this.id_max_term = "";
    this.min_plafond = "";
    this.max_plafond = "";
    this.is_bonus_paycut = false;
    this.is_down_payment = false;
    this.down_payment = null;
    this.activeInterests = JSON.parse(JSON.stringify(this.grades2));
    this.activeFees = JSON.parse(JSON.stringify(this.MasterFee));
    this.isAddMulai = false;
    this.addModal = true;
  }

  prosesAdd() {
    this.isEditMulai = true;
    this.isEditMulai = true;
    let fees = [];
    _.map(this.activeFees, (x) => {
      if (x.status.length > 0) {
        fees.push({ id_fee: x.id_fee, fee_value: x.fee_value });
      }
    });
    let interests = [];
    _.map(this.activeInterests, (x) => {
      if (x.status.length > 0) {
        interests.push({ id_grade: x.id_grade, interest: x.interest });
      }
    });

    let find = _.find(this.listPaycuts, { cut_period: this.selectedStatus });
    let paycuts = [];
    if (find != undefined) {
      paycuts.push({
        cut_period: find.cut_period,
        cut_multiplier: find.cut_multiplier,
      });
    }

    const body = {
      id_max_term: this.id_max_term,
      id_loan_type: this.id_loan_type,
      product_name: this.product_name,
      min_plafond: this.min_plafond,
      max_plafond: this.max_plafond,
      is_bonus_paycut: this.is_bonus_paycut,
      is_down_payment: this.is_down_payment,
      down_payment: this.down_payment,
      is_digisign: this.selectedDigisign,
      id_contract_template: this.selectedDigisign
        ? this.selectedContractTemplate
        : null,
      fees,
      interests,
      paycuts,
    };

    this.masterService.postCoreProduct(body).subscribe(
      (res: any) => {
        if (res["status"] == 400) {
          this.isAddMulai = false;
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: res.errors,
          });
        } else {
          this.addModal = false;
          this.isAddMulai = false;
          this.fetchProduct(1);
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Data Registered Successfully",
          });
        }
      },
      (err) => {
        console.log(err);
        this.isAddMulai = false;
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: err.error.message,
        });
      }
    );
  }

  prosesEdit() {
    this.isEditMulai = true;

    const body = {
      id_product: this.selectedItem.id_product,
      product_name: this.product_name,
      id_contract_template: this.selectedContractTemplate,
    };

    this.masterService.putCoreProduct(body).subscribe(
      (res) => {
        if (res["status"] == 0) {
          this.isEditMulai = false;
          this.viewDetail = false;
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: res["message"],
          });
        } else {
          this.addModal = false;
          this.isEditMulai = false;
          this.fetchProduct(1);
          this.viewDetail = false;
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Data Update Successfully",
          });
        }
      },
      (err) => {
        console.log(err);
        this.isEditMulai = false;
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: err.error.message,
        });
      }
    );
  }

  prosesActive() {
    this.isSubmitApprove = true;
    const body = {
      id: this.selectedItem["id_user"],
    };
    this.memberService.activeUserBe(body).subscribe(
      (res) => {
        this.viewDetail = false;
        this.isSubmitApprove = false;
        this.fetchProduct(1);
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "User Activated",
        });
      },
      (err) => {
        this.isSubmitApprove = false;
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Please try again",
        });
      }
    );
  }

  prosesDeactive() {
    this.isSubmitReject = true;
    const body = {
      id: this.selectedItem["id_user"],
    };
    this.memberService.deactiveUserBe(body).subscribe(
      (res) => {
        this.viewDetail = false;
        this.isSubmitReject = false;
        this.fetchProduct(1);
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "User Deactivated",
        });
      },
      (err) => {
        this.isSubmitApprove = false;
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Please try again",
        });
      }
    );
  }
  // Select Item / User
  // ======================== //
  selectItem(e) {
    const {
      interests,
      fees,
      is_bonus_paycut,
      paycuts,
      product_name,
      id_loan_type,
      id_max_term,
      min_plafond,
      max_plafond,
      is_down_payment,
      down_payment,
      is_digisign,
      id_contract_template,
    } = JSON.parse(JSON.stringify(e));
    this.selectedItem = JSON.parse(JSON.stringify(e));
    this.listPaycuts = JSON.parse(JSON.stringify(this.listPaycuts));
    this.selectContractTemplate();

    if (interests != null && interests.length > 0) {
      let findGreades = JSON.parse(JSON.stringify(this.grades2));
      findGreades.forEach((z) => {
        let awal = interests.filter((y) => {
          return z.id_grade == y.id_grade;
        });
        if (awal.length > 0) {
          z.status.push("1");
          z.interest = awal[0].interest;
        }
      });
      setTimeout(() => {
        this.activeInterests = findGreades;
      }, 100);
    } else {
      this.activeInterests = JSON.parse(JSON.stringify(this.grades2));
    }

    if (fees != null && fees.length > 0) {
      let sementara = JSON.parse(JSON.stringify(this.MasterFee));
      sementara.forEach((z) => {
        let awal = fees.filter((y) => {
          return z.id_fee == y.id_fee;
        });
        if (awal.length > 0) {
          z.status.push("1");
          z.fee_value = awal[0].fee_value;
        }
      });
      setTimeout(() => {
        this.activeFees = sementara;
      }, 100);
    } else {
      this.activeFees = JSON.parse(JSON.stringify(this.MasterFee));
    }

    if (is_bonus_paycut) {
      let findPaycuts = _.forEach(this.listPaycuts, (x: any, i) => {
        let findMonth =
          paycuts != null && _.find(paycuts, { cut_period: x.cut_period });

        if (findMonth != undefined) {
          this.selectedStatus = findMonth.cut_period;
          x.status = false;
          x.cut_multiplier = findMonth.cut_multiplier;
        } else {
          x.status = true;
          x.cut_multiplier = "";
        }
      });

      setTimeout(() => {
        this.listPaycuts = findPaycuts;
      }, 100);
    } else {
      this.listPaycuts = JSON.parse(JSON.stringify(this.listPaycuts));
    }

    this.product_name = product_name;
    this.id_loan_type = id_loan_type;
    this.id_max_term = id_max_term;
    this.min_plafond = min_plafond;
    this.max_plafond = max_plafond;
    this.is_bonus_paycut = is_bonus_paycut;
    this.is_down_payment = is_down_payment;
    this.down_payment = down_payment;
    this.selectedDigisign = is_digisign;
    this.selectedContractTemplate = id_contract_template;
    setTimeout(() => {
      this.viewDetail = true;
    }, 500);
  }

  tabChange() {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 100);
  }

  // Create Mutation Employee
  // ======================== //
  public isSubmitMutation = false;
  public idEmployee = null;
  public division = null;
  public position = null;
  public selectedCompany2 = null;
  public imgEmployee = null;
  createMutation() {
    this.isSubmitMutation = true;
    const obj = {
      id: this.selectedItem.id_user,
      id_employee: this.idEmployee,
      // id_company: this.selectedCompany2,
      company_identity_photo: this.imgEmployee,
      division: this.division,
      position: this.position,
    };
    console.log(obj);
    this.memberService.updateMutation(obj).subscribe(
      (res) => {
        this.isSubmitMutation = false;
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Mutasi karyawan berhasil",
        });
        this.display = false;
        this.fetchProduct(1);
      },
      (err) => {
        this.isSubmitMutation = false;
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Mutasi karyawan gagal, silakan coba lagi",
        });
      }
    );
  }

  // Select Company Mutation Employee
  // ============================== //
  changeCompany2(e) {
    this.selectedCompany2 = e.value;
  }

  // Fetch Salary
  // ============================ //
  fetchSallary(id) {
    this.memberService.getSallary(id).subscribe(
      (res) => {
        this.dataProfile["salary"] = res["data"];
        this.dataProfile["salary"].amount = Number(
          res["data"].salary_amount
        ).toLocaleString();
        this.fetchBank(id);
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }

  // Fetch Bank
  // ============================ //
  fetchBank(id) {
    this.memberService.getBank(id).subscribe(
      (res) => {
        this.dataProfile["bank"] = res["data"];
        this.fetchDocument();
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }

  // Fetch Document
  // ============================ //
  public isViewDocument = false;
  public openTabDocument = false;
  public selectedDocument = null;
  public imgDocument = null;
  public isSubmitDocument = false;
  public arrDocumentType = [];
  public selectedDocumentType = null;
  public isUpdateDocument = false;

  fetchDocument() {
    this.masterService.getCoreFee({ page: 1, row: 100 }).subscribe(
      (res) => {
        this.MasterFee = [];
        this.activeFees = [];
        _.map(res["data"].detail, (x) => {
          let IsFeeValue =
            x.unit_value == "1" ? "(Rp)" : x.unit_value == "2" ? "(%)" : "(‰)";
          const obj2 = {
            label: `${x.fee_name} ${IsFeeValue}`,
            id_fee: x.id_fee,
            fee_value: "",
            status: [],
          };
          this.MasterFee.push(obj2);
        });
        setTimeout(() => {
          window.dispatchEvent(new Event("resize"));
        }, 100);
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }

  fetchProduct(page) {
    this.loading = true;
    const param = { page, row: -1 };
    this.masterService.getCoreProduct(param).subscribe(
      (res: any) => {
        if (res["status"] == 0) {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: res["message"],
          });
          this.loading = false;
          return;
        }
        this.data = (res.data && res.data.detail) || [];
        this.totalCount = res["data"].count;
        this.loading = false;
      },
      (err) => {
        // this.fetchProduct(1);
        this.loading = false;
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }

  viewDocument(e) {
    this.widthDisplay = 800;
    this.selectedDocument = e;
    this.isViewDocument = true;
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 100);
  }

  closeViewDocument() {
    this.widthDisplay = 1200;
    this.isViewDocument = false;
    this.openTabDocument = true;
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 100);
  }

  changeDocumentType(e) {
    this.selectedDocumentType = e.value;
  }

  editDocument(e) {
    this.isUpdateDocument = true;
    this.selectedDocumentType = e.id_document_type;
    this.imgDocument = e.path;
  }

  cancelUpdateDoc() {
    this.isUpdateDocument = false;
    this.imgDocument = null;
  }

  // Fetch Master
  // ========================= //
  fetchStatus() {
    this.memberService.getStatus().subscribe(
      (res) => {
        this.arrStatus = [{ label: "Semua Status", value: null }];
        _.map(res["data"], (x) => {
          const obj = {
            label: x.workflow_status_name,
            value: x.id_workflow_status,
          };
          this.arrStatus.push(obj);
        });

        _.map(this.data, (x) => {
          x["status_name"] = _.find(this.arrStatus, {
            value: x.id_workflow_status,
          }).label;
        });
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }

  fetchGrade() {
    this.memberService.getGrade().subscribe(
      (res) => {
        this.originGrades = res["data"];
        this.grades = [{ label: "Semua Golongan", value: null }];
        this.grades2 = [];
        _.map(res["data"], (x) => {
          const obj = { label: x.name_grade, value: x.id_grade };
          const obj2 = {
            label: x.name_grade,
            id_grade: x.id_grade,
            interest: "",
            status: [],
          };
          this.grades.push(obj);
          this.grades2.push(obj2);
        });
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }

  fetchLoanType() {
    this.masterService.getCoreLoanType({ page: 1, row: 100 }).subscribe(
      (res) => {
        console.log({ res });
        // this.all_loan_type = res['data'];

        this.all_loan_type = [];
        this.all_loan_type.push({ label: "- Pilih -", value: "" });
        _.map(res["data"].detail, (x) => {
          this.all_loan_type.push({
            label: x.loan_type_name,
            value: x.id_loan_type,
          });
        });
      },
      (err) => {
        // this.fetchProduct(1);
        this.loading = false;
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }

  fetchTerm() {
    this.masterService.getCoreTerm({ page: 1, row: 100 }).subscribe(
      (res) => {
        // this.allCoreTerm = res['data'];
        this.allCoreTerm = [];
        this.allCoreTerm.push({ label: "- Pilih -", value: "" });
        _.map(res["data"].detail, (x) => {
          this.allCoreTerm.push({ label: x.term_name, value: x.id_term });
        });
      },
      (err) => {
        // this.fetchComapany();
        this.loading = false;
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }

  fetchDomicile() {
    this.memberService.getDomicile().subscribe(
      (res) => {
        this.arrDomicile = res["data"];
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }

  fetchReligion() {
    this.memberService.getReligion().subscribe(
      (res) => {
        this.arrReligion = res["data"];
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }

  fetchMarriage() {
    this.memberService.getMarriage().subscribe(
      (res) => {
        this.arrMariege = res["data"];
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }

  fetchRole() {
    this.memberService.getRole().subscribe(
      (res) => {
        this.arrRole = res["data"];
        this.roles = [];
        _.map(res["data"], (x) => {
          this.roles.push({
            label: x.name_role_master,
            value: x.id_role_master,
          });
        });
        this.role = this.roles[0].value;
        console.log(this.role);
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }

  fetchMasterDocumentType() {
    this.memberService.getMstDocument().subscribe(
      (res) => {
        _.map(res["data"], (x) => {
          const obj = { label: x.document_name, value: x.id_document_type };
          this.arrDocumentType.push(obj);
        });
        this.selectedDocumentType = this.arrDocumentType[0].value;
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }

  // Filter List
  // ========================== //
  private typingTimer;
  private doneTypingInterval = 1000;
  onSearchName(searchValue: string) {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      if (searchValue) {
        this.objFilter["nama"] = searchValue;
      } else {
        delete this.objFilter["nama"];
      }
      this.start = 0;
      this.fetchProduct(1);
    }, this.doneTypingInterval);
  }
  onSearchNik(searchValue: string) {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      if (searchValue) {
        this.objFilter["no_anggota"] = searchValue;
      } else {
        delete this.objFilter["no_anggota"];
      }
      this.start = 0;
      this.fetchProduct(1);
    }, this.doneTypingInterval);
  }
  changeCompany(e) {
    if (e.value) {
      this.objFilter["company"] = e.value;
    } else {
      delete this.objFilter["company"];
    }

    this.start = 0;
    this.fetchProduct(1);
  }
  changeStatus(e) {
    if (e.value) {
      this.objFilter["status"] = e.value;
    } else {
      delete this.objFilter["status"];
    }

    this.start = 0;
    this.fetchProduct(1);
  }
  changeCompanyAdd(e) {
    this.company = e.value;
  }
  changeRoleAdd(e) {
    this.role = e.value;
  }
  changeGrade(e) {
    if (e.value) {
      this.objFilter["golongan"] = e.value;
    } else {
      delete this.objFilter["golongan"];
    }
    this.start = 0;
    this.fetchProduct(1);
  }
  // changeStatus(e) {
  //   if (e.value) {
  //     this.objFilter['status'] = e.value;
  //   } else {
  //     delete this.objFilter['status'];
  //   }
  //   this.start = 0;
  //   this.fetchProduct(1);
  // }
  onSelectTglMasuk(e) {
    if (this.date1[1]) {
      const date1 = moment(this.date1[0]).format("YYYY-MM-DD");
      const date2 = moment(this.date1[1]).format("YYYY-MM-DD");
      this.objFilter["tgl_masuk"] = "2019-02-01 - 2019-02-15";
    } else {
      delete this.objFilter["tgl_masuk"];
    }
    this.start = 0;
    this.fetchProduct(1);
  }
  onSelectTglPengajuan(e) {
    if (this.date2[1]) {
      const date1 = moment(this.date2[0]).format("YYYY-MM-DD");
      const date2 = moment(this.date2[1]).format("YYYY-MM-DD");
      this.objFilter["tgl_pengajuan"] = "2019-02-01 - 2019-02-15";
    } else {
      delete this.objFilter["tgl_pengajuan"];
    }
    this.start = 0;
    this.fetchProduct(1);
  }
  reset() {
    this.selectedStatus = null;
  }

  selectContractTemplate() {
    this.loadingContractTemplate = true;
    this.masterService.getContractTemplate().subscribe(
      (res: any) => {
        this.arrContractTemplate =
          res.data &&
          _.forEach(res.data.detail, (x) => {
            x.label = x.name;
            x.value = x.id_contract_template;
          });
        this.loadingContractTemplate = false;
      },
      (err: Response) => {
        console.log(err);
        this.selectContractTemplate();
      }
    );
  }
}
