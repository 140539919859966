
import { Component, OnInit } from '@angular/core';
import { MemberService } from '../../member/member.service';
import {MasterService} from '../master.service';
import { MessageService } from 'primeng/components/common/messageservice';
import * as _ from 'lodash';
import * as moment from 'moment';
import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { LoanService } from '../../loan/loan.service';

@Component({
  selector: 'app-produc-company',
  templateUrl: './produc-company.component.html',
  styleUrls: ['./produc-company.component.scss'],
  providers: [MessageService]
})

export class ProducCompanyComponent implements OnInit {

  public data: any = [];
  public columns: any = [];
  public activePaycuts: any = [];
  public display = false;
  public displayImg = false;
  public isAddMulai = false;
  public isEditMulai = false;
  public approveDialoge = false;
  public rejectDialoge = false;
  public addModal = false;
  public selectedColumns: any[];
  public loading: boolean;
  public selectedItem = null;
  public selectedImg = null;
  public grades = [];
  public grades2 = [];
  public selectedGrade = null;
  public companies = [];
  public all_companies = [];
  public roles = [];
  public list_unit = [];
  public companies2 = [];
  public all_loan_type = [];
  public selectedCompany = null;
  public originGrades = [];
  public arrStatus = [];
  public arrReligion = [];
  public masterProduct = [];
  public freeProduct = [];
  public masterDocument = [];
  public filteredDocument = [];
  public activeDocument = [];

  public id_documents = [];
  public allCoreTerm = [];
  public all_term = [];
  public arrDomicile = [];
  public arrMariege = [];
  public activeCompanies = [];
  public selectedCompanies = [];
  public MasterFee = [];
  public coreProduct = [];
  public statusOpsi = [];
  public arrRole = [];
  public selectedStatus = null;
  public date: Date = null;
  public date1: Date = null;
  public date2: Date = null;
  public minDate = moment().add('days', -1)['_d'];
  public isSubmitApprove = false;
  public isSubmitReject = false;
  public viewDetail = false;
  public totalCount = 0;
  public start = 0;
  public pageLength = 10;
  public availabelColumn: Number;
  public widthDisplay: number;
  public roleId;
  public status='';
  public id_company='';
  public cari_company='';
  public id_loan_type='';
  public id_product='';
  public id_max_term='';
  public fee_name='';
  public min_plafond='';
  public max_plafond='';
  public is_bonus_paycut='';
  public is_down_payment='';
  public down_payment='';
  public interests=[];
  public activeInterests=[];
  public activeFees=[];
  public fees=[];
  public paycuts=[];
  public term_name='';
  public loan_type_name='';
  public term_count='';
  public unit_value='';
  public is_kopnit=0;
  public kopnitArray = [
    {label: 'Yes', value: true},
    {label: 'No', value: false},
  ];
  public listPaycuts = [
    {label: 'Januari', cut_period: 1, status:[], cut_multiplier:''},
    {label: 'Februari', cut_period: 2 ,status:[], cut_multiplier:''},
    {label: 'Maret', cut_period: 3 ,status:[], cut_multiplier:''},
    {label: 'April', cut_period: 4 ,status:[], cut_multiplier:''},
    {label: 'Mei', cut_period: 5 ,status:[], cut_multiplier:''},
    {label: 'Juni', cut_period: 6 ,status:[], cut_multiplier:''},
    {label: 'Juli', cut_period: 7 ,status:[], cut_multiplier:''},
    {label: 'Agustus', cut_period: 8 ,status:[], cut_multiplier:''},
    {label: 'September', cut_period: 9 ,status:[], cut_multiplier:''},
    {label: 'Oktober', cut_period: 10 ,status:[], cut_multiplier:''},
    {label: 'November', cut_period: 11 ,status:[], cut_multiplier:''},
    {label: 'Desember', cut_period: 12,status:[], cut_multiplier:''}
  ];
  public name_company ='';
  public phone_number='';
  public document_name='';
  public loan_power='';
  public id_holding='HLD001';
  public address='';
  public dataProfile = null;
  private objFilter = {};

  public displayForm = false;
  public isSubmitRegis = false;

  public name;
  public company;
  public widthTable = 1000;
  public email;
  public identity_photo;
  public company_identity_photo;
  public personal_photo;
  public role;



  public imageID = null;
  public imageKTP = null;
  public imagePersonal = null;

  constructor(
    private loanService: LoanService,
    private messageService: MessageService,
    private masterService: MasterService,
    private memberService: MemberService
  ) { }

  ngOnInit() {
    this.fetchComapany();
    this.fetchProduct(1);
    this.fetchProductMaster();
    // this.fetchDocument();
    // this.fetchLoanType();
    // this.fetchTerm();
    this.roleId = localStorage.getItem('id_role_master');
    this.widthDisplay = 1200;
    this.list_unit = [{label: '0 - Other', value: 0}];
    this.list_unit.push({label: '1 - Currency', value: 1});
    this.list_unit.push({label: '2 - Percentage', value: 2});
    this.list_unit.push({label: '3 - Permil', value: 3});

    // company_name: "KOPERASI ASTRA INTERNATIONAL"
    // id_company: "COMP361"
    // id_product: 1
    // id_product_company: 1
    // product_name: "MULTIGUNA KAI"
    this.columns = [

      {field: 'product_name', header: 'Product Name', show: true},
    ];

    this.availabelColumn = _.filter(this.columns, {show: true}).length + 1;
    this.selectedColumns = _.filter(this.columns, {show: true});
  }

  // Save
  // ========================= //
  save(e) {
    this.isSubmitRegis = true;
    e['identity_photo'] = this.imageID.imageSrc;
    e['company_identity_photo'] = this.imageKTP.imageSrc;
    e['personal_photo'] = this.imagePersonal.imageSrc;
    e['role'] = 'ROLE001';

    this.memberService.postUser(e).subscribe(res => {

      this.isSubmitRegis = false;
      this.displayForm = false;
      this.fetchProduct(1);
      this.messageService.add({severity: 'success', summary: 'Success', detail: 'User has been added'});
    }, err => {
      console.log(err);

      this.isSubmitRegis = false;
      this.messageService.add({severity: 'error', summary: 'Error', detail: 'Please try again'});
    });
  }
  // Toggle Columns
  // ====================== //
  changeColumn(e) {
    console.log({e})
    const find = _.find(this.activeCompanies, e.itemValue);
    if (e.itemValue != undefined) {
      if (e.itemValue.status) {
        find.status = false;
      } else {
        find.status = true;
      }
    }
    this.availabelColumn = _.filter(this.activeCompanies, {status: true}).length + 1;
    this.selectedCompanies = e.value;
  }

  // Convert Image
  // ========================= //
  private typeupload = null;
  handleInputChange(e, type) {
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    const pattern = /image-*/;
    const reader = new FileReader();
    if (file != undefined) {
      if (!file.type.match(pattern)) {
        alert('invalid format');
        return;
      }
    }

    this.typeupload = type;
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  _handleReaderLoaded(e, type) {
    const reader = e.target;
    switch (this.typeupload) {
      case 'company' :
        this.imgEmployee = reader.result;
        break;
      case 'document' :
        this.imgDocument = reader.result;
        break;
    }

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 500);
  }
  pilihImg(val) {
    this.selectedImg = val;
    this.displayImg = true;
  }
  // Fetching User
  // ========================= //
  fetchComapany() {
    this.loanService.getCompany().subscribe(res => {
      this.all_companies = this.companies;
      this.companies = [{label: 'Semua Perusahaan', value: null}];
      this.companies2 = [];
      _.map(res['data'], (x) => {
        this.companies.push({label: x.name_company, value: x.id_company,status:false, field:x.id_company,header:x.name_company});
        this.companies2.push({label: x.name_company, value: x.id_company,status:false,  field:x.id_company,header:x.name_company});
      });
    }, err => {
      console.log(err);
      // this.fetchLoan();
    });
  }
  paginate(e) {
    this.start = e.page * this.pageLength;
    this.fetchProduct(e.page+1);
  }
  approveItem(val) {
    this.approveDialoge = true;
    this.selectedItem = val;
  }
  rejectItem(val) {
    this.rejectDialoge = true;
    this.selectedItem = val;
  }
  tambahNew() {

    this.id_product = "";
    let tmp = this.masterProduct.filter(z=>{
      return !this.data.some(x=>{
        return x.id_product === z.value;
      });
    });
    console.log(this.data);
    console.log(this.masterProduct);

    // let inserted = this.masterProduct.find((o, i) => o.value !== this.data[i].id_product);
    console.log({tmp});
    this.freeProduct = JSON.parse(JSON.stringify(tmp));
    if(tmp.length>0){
      this.id_product = this.freeProduct[0].value;
    }
    this.activeCompanies = JSON.parse(JSON.stringify(this.companies2));
    this.selectedCompanies=[];
    this.isAddMulai = false;
    this.addModal = true;
  }
  prosesAdd() {
    this.isAddMulai = true;
    console.log(this.activeCompanies);
    let id_companies = [];
    // _.map(this.activeCompanies,(x)=>{
    //   if (x.status==true) {
    //     id_companies.push(x.value);
    //   }
    // })
    _.map(this.selectedCompanies,(x)=>{
      id_companies.push(x.value);
    })

    const body = {
      id_product : this.id_product,
      id_companies
    };
    // console.log(body);
    this.masterService.postCoreProductCompany(body).subscribe(res => {
      if(res['status']==0){
        this.isAddMulai = false;
        this.messageService.add({severity: 'error', summary: 'Error', detail: res['message']});
      }else{
        this.addModal = false;
        this.isAddMulai = false;
        this.fetchProduct(1);
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'Data Registered Successfully'});
      }
    }, err => {
      console.log(err);
      this.isAddMulai = false;
      this.messageService.add({severity: 'error', summary: 'Error', detail: err.error.message});
    });
  }
  prosesEdit() {
    this.isEditMulai = true;
    console.log(this.activeDocument);
    let id_companies = [];
    // _.map(this.activeCompanies,(x)=>{
    //   if (x.status==true) {
    //     id_companies.push(x.value);
    //   }
    // })
    _.map(this.selectedCompanies,(x)=>{
      id_companies.push(x.value);
    })

    const body = {
      id_product : this.id_product,
      id_companies
    };
    console.log(body);
    this.masterService.putCoreProductCompany(body).subscribe(res => {
      if(res['status']==0){

        this.isEditMulai = false;
        this.viewDetail = false;
        this.messageService.add({severity: 'error', summary: 'Error', detail: res['message']});
      }else{
        this.addModal = false;
        this.isEditMulai = false;
        this.fetchProduct(1);
        this.viewDetail = false;
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'Data Update Successfully'});
      }
    }, err => {
      console.log(err);
      this.isEditMulai = false;
      this.messageService.add({severity: 'error', summary: 'Error', detail: err.error.message});
    });
  }
  prosesActive() {
    this.isSubmitApprove = true;
    const body = {
      id: this.selectedItem['id_user']
    };
    this.memberService.activeUserBe(body).subscribe(res => {
      this.viewDetail = false;
      this.isSubmitApprove = false;
      this.fetchProduct(1);
      this.messageService.add({severity: 'success', summary: 'Success', detail: 'User Activated'});
    }, err => {
      this.isSubmitApprove = false;
      this.messageService.add({severity: 'error', summary: 'Error', detail: 'Please try again'});
    });
  }
  prosesDeactive() {
    this.isSubmitReject = true;
    const body = {
      id: this.selectedItem['id_user']
    };
    this.memberService.deactiveUserBe(body).subscribe(res => {
      this.viewDetail = false;
      this.isSubmitReject = false;
      this.fetchProduct(1);
      this.messageService.add({severity: 'success', summary: 'Success', detail: 'User Deactivated'});
    }, err => {
      this.isSubmitApprove = false;
      this.messageService.add({severity: 'error', summary: 'Error', detail: 'Please try again'});
    });
  }
  // Select Item / User
  // ======================== //
  selectItem(e) {

    let tmp  = JSON.parse(JSON.stringify(e));
    this.selectedItem = JSON.parse(JSON.stringify(e));

    this.id_product = tmp.id_product;
    // this.activePaycuts = JSON.parse(JSON.stringify(this.listPaycuts));
    this.freeProduct = JSON.parse(JSON.stringify(this.masterProduct));
    // this.activeCompanies = JSON.parse(JSON.stringify(this.companies2));
    if(tmp.companies){
      let sementara = JSON.parse(JSON.stringify(this.companies2));
      sementara.forEach(z=>{
        let awal = tmp.companies.filter(y=>{return z.value==y.id_company})
        if(awal.length>0){
          z.status=true;
        }
      })
      setTimeout(()=>{
        this.activeCompanies = sementara;
        this.selectedCompanies = _.filter(this.activeCompanies, {status: true});
      },100)
    }else{
      this.activeCompanies = JSON.parse(JSON.stringify(this.companies2));
    }


    this.id_product = tmp.id_product;
    setTimeout(()=>{
      this.viewDetail = true;
    },500);

  }
  tabChange() {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);
  }

  // Create Mutation Employee
  // ======================== //
  public isSubmitMutation = false;
  public idEmployee = null;
  public division = null;
  public position = null;
  public selectedCompany2 = null;
  public imgEmployee = null;
  createMutation() {
    this.isSubmitMutation = true;
    const obj = {
      id: this.selectedItem.id_user,
      id_employee: this.idEmployee,
      // id_company: this.selectedCompany2,
      company_identity_photo: this.imgEmployee,
      division: this.division,
      position: this.position
    };
    console.log(obj);
    this.memberService.updateMutation(obj).subscribe(res => {
      this.isSubmitMutation = false;
      this.messageService.add({severity: 'success', summary: 'Success', detail: 'Mutasi karyawan berhasil'});
      this.display = false;
      this.fetchProduct(1);
    }, err => {
      this.isSubmitMutation = false;
      this.messageService.add({severity: 'error', summary: 'Error', detail: 'Mutasi karyawan gagal, silakan coba lagi'});
    });
  }

  // Select Company Mutation Employee
  // ============================== //
  changeCompany2(e) {
    this.selectedCompany2 = e.value;
  }

  // Fetch Salary
  // ============================ //
  fetchSallary(id) {
    this.memberService.getSallary(id).subscribe(res => {
      this.dataProfile['salary'] = res['data'];
      this.dataProfile['salary'].amount = Number(res['data'].salary_amount).toLocaleString();
      this.fetchBank(id);
    }, err => {
      if (err.status == 401) { this.memberService.updateToken(err.error.data.token); }
    });
  }

  // Fetch Bank
  // ============================ //
  fetchBank(id) {
    this.memberService.getBank(id).subscribe(res => {
      this.dataProfile['bank'] = res['data'];
      this.fetchDocument();
    }, err => {
      if (err.status == 401) { this.memberService.updateToken(err.error.data.token); }
    });
  }

  // Fetch Document
  // ============================ //
  public isViewDocument = false;
  public openTabDocument = false;
  public selectedDocument = null;
  public imgDocument = null;
  public isSubmitDocument = false;
  public arrDocumentType = [];
  public selectedDocumentType = null;
  public isUpdateDocument = false;

  fetchDocument() {
    this.masterService.getCoreFee({page:1}).subscribe(res => {
      this.MasterFee = [];
      this.activeFees = [];
      _.map(res['data'], (x) => {
        const obj2 = {label: x.fee_name, id_fee: x.id_fee, fee_value:'', status:[]};
        this.MasterFee.push(obj2);
      });
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 100);
    }, err => {
      if (err.status == 401) { this.memberService.updateToken(err.error.data.token); }
    });
  }
  fetchProduct(page) {
    const param = {page,  row: 10};
    this.masterService.getCoreProductCompany(param).subscribe(res => {

      this.data = res['data']['detail'];

      this.totalCount = res['data'].count;
      this.loading = false;

    }, err => {
      // this.fetchProduct(1);
      this.loading = false;
      if (err.status == 401) { this.memberService.updateToken(err.error.data.token); }
    });
  }
  viewDocument(e) {
    this.widthDisplay = 800;
    this.selectedDocument = e;
    this.isViewDocument = true;
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);
  }
  closeViewDocument() {
    this.widthDisplay = 1200;
    this.isViewDocument = false;
    this.openTabDocument = true;
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);
  }
  changeDocumentType(e) {
    this.selectedDocumentType = e.value;
  }
  editDocument(e) {
    this.isUpdateDocument = true;
    this.selectedDocumentType = e.id_document_type;
    this.imgDocument = e.path;
  }
  cancelUpdateDoc() {
    this.isUpdateDocument = false;
    this.imgDocument = null;
  }

  // Fetch Master
  // ========================= //
  fetchStatus() {
    this.memberService.getStatus().subscribe(res => {
      this.arrStatus = [{label: 'Semua Status', value: null}];
      _.map(res['data'], (x) => {
        const obj = {label: x.workflow_status_name, value: x.id_workflow_status};
        this.arrStatus.push(obj);
      });

      _.map(this.data, (x) => {
        x['status_name'] = _.find(this.arrStatus, {value: x.id_workflow_status}).label;
      });
    }, err => {
      if (err.status == 401) { this.memberService.updateToken(err.error.data.token); }
    });
  }
  fetchProductMaster() {
    this.masterService.getCoreProduct({page:1}).subscribe(res => {
      this.masterProduct = [];
      console.log(res['data']['detail'])
      _.map(res['data']['detail'], (x) => {
        const obj = {label: x.product_name, value: x.id_product};

        this.masterProduct.push(obj);
      });
      console.log(this.masterProduct);
    }, err => {
      if (err.status == 401) { this.memberService.updateToken(err.error.data.token); }
    });
  }
  fetchLoanType() {
    this.masterService.getCoreLoanType({page:1}).subscribe(res => {
      console.log({res});
      // this.all_loan_type = res['data'];
      this.all_loan_type = [];
      _.map(res['data'], (x) => {
        this.all_loan_type.push({label: x.loan_type_name, value: x.id_loan_type});
      });
    }, err => {
      // this.fetchProduct(1);
      this.loading = false;
      if (err.status == 401) { this.memberService.updateToken(err.error.data.token); }
    });
  }
  fetchTerm() {
    this.masterService.getCoreTerm({page:1, row:100}).subscribe(res => {
      console.log({res});
      // this.allCoreTerm = res['data'];
      this.allCoreTerm = [];
      _.map(res['data'], (x) => {
        this.allCoreTerm.push({label: x.term_name, value: x.id_term});
      });
    }, err => {
      // this.fetchComapany();
      this.loading = false;
      if (err.status == 401) { this.memberService.updateToken(err.error.data.token); }
    });
  }
  fetchDomicile() {
    this.memberService.getDomicile().subscribe(res => {
      this.arrDomicile = res['data'];
    }, err => {
      if (err.status == 401) { this.memberService.updateToken(err.error.data.token); }
    });
  }
  fetchReligion() {
    this.memberService.getReligion().subscribe(res => {
      this.arrReligion = res['data'];
    }, err => {
      if (err.status == 401) { this.memberService.updateToken(err.error.data.token); }
    });
  }
  fetchMarriage() {
    this.memberService.getMarriage().subscribe(res => {
      this.arrMariege = res['data'];
    }, err => {
      if (err.status == 401) { this.memberService.updateToken(err.error.data.token); }
    });
  }
  fetchRole() {
    this.memberService.getRole().subscribe(res => {
      this.arrRole = res['data'];
      this.roles = [];
      _.map(res['data'], (x) => {
        this.roles.push({label: x.name_role_master, value: x.id_role_master});

      });
      this.role = this.roles[0].value;
      console.log(this.role);
    }, err => {
      if (err.status == 401) { this.memberService.updateToken(err.error.data.token); }
    });
  }
  fetchMasterDocumentType() {
    this.memberService.getMstDocument().subscribe(res => {
      _.map(res['data'], (x) => {
        const obj = {label: x.document_name, value: x.id_document_type};
        this.arrDocumentType.push(obj);
      });
      this.selectedDocumentType = this.arrDocumentType[0].value;
    }, err => {
      if (err.status == 401) { this.memberService.updateToken(err.error.data.token); }
    });
  }

  // Filter List
  // ========================== //
  private typingTimer;
  private doneTypingInterval = 1000;
  onSearchName(searchValue: string ) {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      if (searchValue) {
        this.objFilter['nama'] = searchValue;
      } else {
        delete this.objFilter['nama'];
      }
      this.start = 0;
      this.fetchProduct(1);
    }, this.doneTypingInterval);
  }
  onSearchNik(searchValue: string ) {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      if (searchValue) {
        this.objFilter['no_anggota'] = searchValue;
      } else {
        delete this.objFilter['no_anggota'];
      }
      this.start = 0;
      this.fetchProduct(1);
    }, this.doneTypingInterval);
  }
  changeCompany(e) {
    if (e.value) {
      this.objFilter['company'] = e.value;
    } else {
      delete this.objFilter['company'];
    }

    this.start = 0;
    this.fetchProduct(1);
  }
  changeStatus(e) {
    if (e.value) {
      this.objFilter['status'] = e.value;
    } else {
      delete this.objFilter['status'];
    }

    this.start = 0;
    this.fetchProduct(1);
  }
  changeCompanyAdd(e) {
    this.company = e.value;
  }
  changeRoleAdd(e) {
    this.role = e.value;
  }
  changeGrade(e) {
    if (e.value) {
      this.objFilter['golongan'] = e.value;
    } else {
      delete this.objFilter['golongan'];
    }
    this.start = 0;
    this.fetchProduct(1);
  }
  // changeStatus(e) {
  //   if (e.value) {
  //     this.objFilter['status'] = e.value;
  //   } else {
  //     delete this.objFilter['status'];
  //   }
  //   this.start = 0;
  //   this.fetchProduct(1);
  // }
  onSelectTglMasuk(e) {
    if (this.date1[1]) {
      const date1 = moment(this.date1[0]).format('YYYY-MM-DD');
      const date2 = moment(this.date1[1]).format('YYYY-MM-DD');
      this.objFilter['tgl_masuk'] = '2019-02-01 - 2019-02-15';
    } else {
      delete this.objFilter['tgl_masuk'];
    }
    this.start = 0;
    this.fetchProduct(1);
  }
  onSelectTglPengajuan(e) {
    if (this.date2[1]) {
      const date1 = moment(this.date2[0]).format('YYYY-MM-DD');
      const date2 = moment(this.date2[1]).format('YYYY-MM-DD');
      this.objFilter['tgl_pengajuan'] = '2019-02-01 - 2019-02-15';
    } else {
      delete this.objFilter['tgl_pengajuan'];
    }
    this.start = 0;
    this.fetchProduct(1);
  }


}

