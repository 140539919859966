import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Message } from "primeng/primeng";
import { SignService } from "../sign.service";
import { APIService } from "../../../service/api.service";
import { store } from "../../../service/store.service";

@Component({
  selector: "app-signin",
  templateUrl: "./signin.component.html",
  styleUrls: ["./signin.component.scss"],
})
export class SigninComponent implements OnInit {
  public email: string = "";
  public password: string = "";
  public isLoading: boolean = false;
  public errorUser: boolean = false;
  msgs: Message[] = [];

  constructor(
    private APIService: APIService,
    private signService: SignService,
    private router: Router
  ) {}

  ngOnInit() {}

  onSubmit(e) {
    this.isLoading = true;
    this.errorUser = false;
    this.signService.postLogin(e).subscribe(
      (res) => {
        console.log(res);
        if (res["status"] != 1) {
          this.msgs = [];
          this.msgs.push({
            severity: "error",
            summary: "Error",
            detail: res["message"],
          });
          this.isLoading = false;
          return false;
        }

        localStorage.setItem("token", res["data"].token);
        localStorage.setItem("id_role_master", res["data"].id_role_master);
        localStorage.setItem("is_new_user", res["data"].is_new_user);
        this.isLoading = false;
        this.router.navigate(["/main/"]);
      },
      (err) => {
        console.log(err);
        this.errorUser = true;
        this.isLoading = false;
      }
    );
  }
}
