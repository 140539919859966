import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { APIService } from "../../service/api.service";


@Injectable({
  providedIn: "root",
})
export class SignService {


  constructor(
    private API: APIService
    , private http: HttpClient
    ) {}

  private urlLogin: string = this.API.hostAuth + "/auth_backend";
  private urlSendEmail : string= this.API.hostAuth + "/profile/send-email";
  private urlCheckToken : string= this.API.hostAuth + "/profile/check-token";
  private urlPutPassword : string= this.API.hostAuth + "/profile/forgot-password";


  // private urlPutPassword =
  // this.APIService["hostAuth"] + "/profile/change-passwordlend";
  

  postLogin(obj) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    let body = JSON.stringify(obj);
    return this.http.post(this.urlLogin, body, options);
  }



  sendEmail(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
      }),
    };
    const body = obj;
    return this.http.post(this.urlSendEmail, body, options);
  }


  checkToken(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
      }),
    };
    const body = obj;
    return this.http.post(this.urlCheckToken, body, options);
  }

  updatePassword(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",

      }),
    };
    const body = obj;
    return this.http.post(this.urlPutPassword, body, options);
  }
}
