import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportPaymentComponent } from './report-payment/report-payment.component';
import { MainReportComponent } from './main-report/main-report.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ReportService } from './report.service';
import { IconsModule } from '../../icons/icons.module';

import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { ToastModule } from 'primeng/toast';
import { SidebarModule } from 'primeng/sidebar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { SpinnerModule } from 'primeng/spinner';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CustomFormsModule } from 'ng2-validation';
import {ListboxModule} from 'primeng/listbox';
import {MasterService} from '../master/master.service';
import {RouterModule} from '@angular/router';
import {InputTextModule} from 'primeng/inputtext';
import {CheckboxModule} from 'primeng/checkbox';
import { OrderBillerComponent } from './order-biller/order-biller.component';
import { OrderBillerDetailComponent } from './order-biller-detail/order-biller-detail.component';

@NgModule({
  imports: [
    RouterModule,
    FormsModule,
    CommonModule,
    BrowserAnimationsModule,
    IconsModule,
    HttpClientModule,
    InputTextModule,
    PaginatorModule,
    CustomFormsModule,
    ReactiveFormsModule,
    ListboxModule,
    TableModule,
    ToastModule,
    SidebarModule,
    ProgressSpinnerModule,
    MultiSelectModule,
    DropdownModule,
    CalendarModule,
    DialogModule,
    SpinnerModule,
    FileUploadModule,
    InputSwitchModule,
    CheckboxModule
  ],
  declarations: [ReportPaymentComponent, MainReportComponent, OrderBillerComponent, OrderBillerDetailComponent],
  providers: [ReportService]
})
export class ReportModule { }
