import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { APIService } from "../../service/api.service";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class LoanNewService {
  
  
  private urlGetLoanTipeAll = this.APIService["hostLoanNew"] + "/loan/tipe-all";
  private urlGetLoanCompanies = this.APIService["hostLoanNew"] + "/loan/companies";
  private urlGetLoanGolonganKai = this.APIService["hostLoanNew"] + "/loan/golongan-kai";
  private urlGetLoanMasterWorkflowStatus = this.APIService["hostLoanNew"] + "/pelunasan/master-workflow-status";
  private urlGetLoanNewAll = this.APIService["hostLoanNew"] + "/pelunasan/list-approval";
  private urlGetLoanDetail = this.APIService["hostLoanNew"] + "/pelunasan/detail";
  private urlPostKonfirmasiPelunasan = this.APIService["hostLoanNew"] + "/pelunasan/konfirmasi";
  private urlPostRevisiPelunasanData = this.APIService["hostLoanNew"] + "/pelunasan/revisi-ar";


  private urlGetLoanOffset = this.APIService["hostLoanNew"] + "/loan/offset";
  private urlGetAlasanBatal = this.APIService["hostLoanNew"] + "/loan/reason-cancel";

  private urlPostUploadDocument = this.APIService["hostLoanNew"] + "/loan-new/upload-document";
  private urlPostUpdateLoanMembership = this.APIService["hostLoanNew"] + "/loan/update-loan-membership";
  private urlPostUpdateLoanData = this.APIService["hostLoanNew"] + "/loan-new/update-loan-data";

  private urlPostSimulation = this.APIService["hostLoanNew"] + "/loan/simulationhr";


  constructor(private APIService: APIService, private http: HttpClient) {}

  setOptions(options = null) {
    const _defaultOption = {
      headers: new HttpHeaders({ accept: "application/json", Authorization: localStorage.getItem("token")}),
    };
    if(options && options != '') {
      return {..._defaultOption, ...options};
    }
    return _defaultOption;
  }
  // Update Token
  // ======================== //
  updateToken(token) {
    localStorage.setItem("token", token);
    location.reload();
  }

  bikinParam(param) {
    let str = "";
    for (const key in param) {
      if (param[key] != "" && param[key] != undefined) {
        if (str != "") {
          str += "&";
        }
        str += key + "=" + encodeURIComponent(param[key]);
      }
    }
    return str;
  }
  
  // Get Loan New All
  // ====================== //
  getLoanNew(start, rows, param) {
    let filter = param != undefined ? param : "";
    // const filter = this.bikinParam(param);

    return this.http.get(this.urlGetLoanNewAll + "?page=" + start + "&size=" + rows + filter, this.setOptions());
    
  }

  // Get Jenis Pinjaman All
  // ====================== //
  getLoanTipeAll() {
    return this.http.get(this.urlGetLoanTipeAll, this.setOptions());
  }

  // Get List Perusahaan
  // ====================== //
  getLoanCompanies() {
    return this.http.get(this.urlGetLoanCompanies, this.setOptions());
  }

  // Get List Golongan KAI
  // ====================== //
  getLoanGolonganKai(loanNewId) {
    return this.http.get(this.urlGetLoanGolonganKai + '?loan_new_id=' + loanNewId, this.setOptions());
  }

  // Get List Golongan KAI
  // ====================== //
  getLoanMasterWorkflowStatus() {
    return this.http.get(this.urlGetLoanMasterWorkflowStatus, this.setOptions());
  }

  // Get List Detail
  // ====================== //
  getLoanDetail(loanNewId) {
    return this.http.get(this.urlGetLoanDetail + '?loan_new_id=' + loanNewId, this.setOptions());
  }

  // Get Loan Offset Anggota
  // ====================== //
  getLoanOffset(idKoperasi) {
    return this.http.get(this.urlGetLoanOffset + '?id_koperasi=' + idKoperasi, this.setOptions());
  }

  getAlasanBatal() {
    return this.http.get(this.urlGetAlasanBatal, this.setOptions());
  }

  postUpdateLoanMembership(bodyParams) {
    return this.http.post(this.urlPostUpdateLoanMembership, bodyParams, this.setOptions());
  }

  postUpdateLoanData(bodyParams) {
    return this.http.post(this.urlPostUpdateLoanData, bodyParams, this.setOptions());
  }

  postKonfirasiPelunasan(bodyParams) {
    return this.http.post(this.urlPostKonfirmasiPelunasan, bodyParams, this.setOptions());
  }

  postRevisiPelunasanData(bodyParams) {
    return this.http.post(this.urlPostRevisiPelunasanData, bodyParams, this.setOptions());
  }

  postUploadDocument(bodyParams) {
    return this.http.post(this.urlPostUploadDocument, bodyParams, this.setOptions());
  }

  postSimulation(bodyParams) {
    return this.http.post(this.urlPostSimulation, bodyParams, this.setOptions());
  }
}
