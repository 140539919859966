import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "primeng/components/common/messageservice";
import { MemberService } from "../../member/member.service";
import { LoanNewService } from "../loan-new.service";
import { store } from "../../../service/store.service";
import * as _ from "lodash";
import * as moment from "moment";

@Component({
  selector: "app-detail-loan",
  templateUrl: "./loan-new-detail.component.html",
  styleUrls: ["./loan-new-detail.component.scss"],
  providers: [MessageService],
})
export class LoanNewDetailComponent implements OnInit {
  public loading: boolean;
  public dialogEditPinjamanShow: boolean = false;
  public dialogOffsetShow: boolean = false;

  public id_user;
  public id_koperasi;
  public loan_new_id;
  public id_role_master;
  public widthDisplay: number;

  public DOC_TYPE_FIX = 'fix';
  public DOC_TYPE_UMUM = 'umum';
  public DOC_TYPE_KHUSUS = 'khusus';
  public DOC_TYPE_FIX_ID_IDENTITY = 'ktp';
  public DOC_TYPE_FIX_ID_CARD = 'card';
  public DOC_TYPE_FIX_PERSONAL = 'personal';

  public is_verified_affco;
  public isno_kartap_astra;

  public dataDetailLoan = null;
  public dataDetailLoanTmp = null;
  public dataLoanOffsets = null;
  public golonganKaiList = [{ label: "Pilih Golongan", value: null }];
  public alasanBatalList = [];
  public selectedOffset: string[] = [];
  public selectedGolongan = null;
  public selectedAlasanBatal = null;
  public verifiedAffco = null;
  public isnoKartapAstra = null;
  public tanggalKartap = null;
  public selectedJenis = null;
  public errMessage = null;
  

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private memberService: MemberService,
    private messageService: MessageService,
    private loanService: LoanNewService,
  ) {
    store.subscribe(() => {
      this.id_role_master = localStorage.getItem("id_role_master");
    });
  }

  ngOnInit() {
    this.id_role_master = localStorage.getItem("id_role_master");
    
    this.route.queryParamMap.subscribe((queryParams) => {
      this.id_user = queryParams.get("id_user");
      this.id_koperasi = queryParams.get("id_koperasi");
    });
    this.route.params.subscribe((params) => {
      this.loan_new_id = params["id"];
    });

    this.selectedOffset = [];
    this.widthDisplay = 1200;

    this.fetchLoanDetail(this.loan_new_id, (res) => {
      this.fetchLoanOffset(this.id_koperasi, () => {
        this.loading = false;
      });
      this.fetchGolonganKai(this.loan_new_id);
      this.fetchAlasanBatal();
    });
  }

  formatCurrency(value) {
    if(value && value != '') {
      value = parseInt(value || 0);
      return "Rp " + (parseInt(value || 0)).toLocaleString();
    }
    return 0;
  }
  parseDouble(value) {
    if(value && value != '') {
      return parseFloat(value || 0);
    }
    return 0;
  }

  // Fetch Loan Detail
  // ======================== //
  fetchLoanDetail(loanNewid, callback) {
    this.loading = true;
    this.loanService.getLoanDetail(loanNewid).subscribe((res) => {
      if (res["status"] == 0) {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: res["message"],
        });
        this.loading = false;
        return;
      }
      
      this.dataDetailLoan = res["data"];
      this.setDataDetaiLoan();
      this.setdocumentList();
      this.setKontrakList();
      this.is_verified_affco = this.dataDetailLoan.verified_affco;

      if(typeof callback === 'function') {
        callback(this.dataDetailLoan);
      } else {
        this.loading = false;
      }
    });
  }
  setDataDetaiLoan() {
    this.dataDetailLoan.salary = parseInt(this.dataDetailLoan.salary || 0);
    this.dataDetailLoan.applied_amount = parseInt(this.dataDetailLoan.applied_amount || 0);
    this.dataDetailLoan.approved_amount = parseInt(this.dataDetailLoan.approved_amount || 0);
    this.dataDetailLoan.approved_tenor = parseInt(this.dataDetailLoan.approved_tenor || 0);
    this.dataDetailLoan.total_pinjaman = parseInt(this.dataDetailLoan.total_pinjaman || 0);
    this.dataDetailLoan.angsuran_bulan = parseInt(this.dataDetailLoan.angsuran_bulan || 0);
    this.dataDetailLoan.total_offset = parseInt(this.dataDetailLoan.total_offset || 0);
    this.dataDetailLoan.jumlah_offset = parseInt(this.dataDetailLoan.jumlah_offset || 0);
    this.dataDetailLoan.pt_bns_tahun = parseInt(this.dataDetailLoan.pt_bns_tahun || 0);
    this.dataDetailLoan.biaya_admin = parseInt(this.dataDetailLoan.biaya_admin || 0);
    this.dataDetailLoan.provisi = parseInt(this.dataDetailLoan.provisi || 0);
    this.dataDetailLoan.applied_dana_diterima = parseInt(this.dataDetailLoan.applied_dana_diterima || 0);

    this.dataDetailLoan.potongan_perusahaan = parseInt(this.dataDetailLoan.potongan_perusahaan || 0);
    this.dataDetailLoan.potongan_kopnit = parseInt(this.dataDetailLoan.potongan_kopnit || 0);
    this.dataDetailLoan.rekomendasi_plafon = parseInt(this.dataDetailLoan.rekomendasi_plafon || 0);
    this.dataDetailLoan.nilai_pelunasan_kopnit = parseInt(this.dataDetailLoan.nilai_pelunasan_kopnit || 0);
    this.dataDetailLoan.dana_diterima_anggota = parseInt(this.dataDetailLoan.dana_diterima_anggota || 0);

    this.dataDetailLoan.applied_amount_text = this.formatCurrency(this.dataDetailLoan.applied_amount);
    this.dataDetailLoan.approved_amount_text = this.formatCurrency(this.dataDetailLoan.approved_amount);
    this.dataDetailLoan.total_pinjaman_text = this.formatCurrency(this.dataDetailLoan.total_pinjaman);
    this.dataDetailLoan.angsuran_bulan_text = this.formatCurrency(this.dataDetailLoan.angsuran_bulan);
    this.dataDetailLoan.total_offset_text = this.formatCurrency(this.dataDetailLoan.total_offset);
    this.dataDetailLoan.jumlah_offset_text = this.formatCurrency(this.dataDetailLoan.jumlah_offset);
    this.dataDetailLoan.pt_bns_tahun_text = this.formatCurrency(this.dataDetailLoan.pt_bns_tahun);
    this.dataDetailLoan.biaya_admin_text = this.formatCurrency(this.dataDetailLoan.biaya_admin);
    this.dataDetailLoan.provisi_text = this.formatCurrency(this.dataDetailLoan.provisi);
    this.dataDetailLoan.biaya_asuransi_text = this.formatCurrency(this.dataDetailLoan.biaya_asuransi);
    this.dataDetailLoan.dana_diterim_text = this.formatCurrency(this.dataDetailLoan.dana_deterima);
    this.dataDetailLoan.applied_dana_diterima_text = this.formatCurrency(this.dataDetailLoan.applied_dana_diterima);
    this.dataDetailLoan.nilai_pelunasan_kopnit_text = this.formatCurrency(this.dataDetailLoan.nilai_pelunasan_kopnit);
    this.dataDetailLoan.dana_diterima_anggota_text = this.formatCurrency(this.dataDetailLoan.dana_diterima_anggota);

    this.dataDetailLoan.angsuran_aktif2_text = this.formatCurrency(this.dataDetailLoan.angsuran_aktif2);
    this.dataDetailLoan.est_angsuran_bulan_text = this.formatCurrency(this.dataDetailLoan.est_angsuran_bulan);
    this.dataDetailLoan.angsuran_bulan_new_text = this.formatCurrency(this.dataDetailLoan.angsuran_bulan_new);
    this.dataDetailLoan.offset_pinjaman_text = '';
    if(this.dataDetailLoan.offset_pinjaman && this.dataDetailLoan.offset_pinjaman != '') {
      const offset_pinjaman_arr = this.dataDetailLoan.offset_pinjaman.split(',');
      this.dataDetailLoan.offset_pinjaman_text = offset_pinjaman_arr.length + ' Pinjaman';
    }
    this.dataDetailLoan.approved_tenor_text = parseInt(this.dataDetailLoan.approved_tenor || 0);
    this.dataDetailLoan.updated_keanggotaan_text = (this.dataDetailLoan.updated_keanggotaan && this.dataDetailLoan.updated_keanggotaan != '')
            ? moment(this.dataDetailLoan.updated_keanggotaan).format("DD/MMM/YYYY hh:mm") : '';

    this.selectedAlasanBatal = this.dataDetailLoan.alasan_batal;
    this.selectedJenis = this.dataDetailLoan.approved_code_sim_perhitungan == null ? 'Angsuran' : this.dataDetailLoan.approved_code_sim_perhitungan;
    
    if (this.dataDetailLoan.verified_affco) {
      this.verifiedAffco = this.dataDetailLoan.verified_affco;
      this.selectedGolongan = this.dataDetailLoan.golongan_affco;
      this.tanggalKartap = this.dataDetailLoan.tanggal_kartap ? moment(this.dataDetailLoan.tanggal_kartap).format('DD-MM-YYYY') : null;
      this.isno_kartap_astra = this.dataDetailLoan.isno_kartap_astra;
    }
    this.isnoKartapAstra = this.dataDetailLoan.isno_kartap_astra === '1' ? true : false;
  }

  changeJenis(e: any) {
    this.loadingSubmitDoc = true;
    
    this.loadingHitungUlangLoan = true;

    var formData =
      {
        "loan_new_id": this.loan_new_id,
        "applied_amount": this.selectedJenis == 'Angsuran' ? this.dataDetailLoanTmp.approved_amount : this.dataDetailLoanTmp.dana_deterima,
        "applied_tenor":this.dataDetailLoanTmp.applied_tenor,
        "id_koperasi": this.id_koperasi,
        "is_offset": this.dataDetailLoanTmp.offset_pinjaman == '' ? 'N' : 'Y',
        "kode_pinjaman": this.dataDetailLoanTmp.kode_pinjaman,
        "no_pinjaman": this.dataDetailLoanTmp.offset_pinjaman == '' ? '' : this.dataDetailLoanTmp.offset_pinjaman,
        "validation":  this.dataDetailLoanTmp.verified_affco,
        "calculation_date":  moment().format('DD-MM-YYYY'),
        "applied_code_sim_perhitungan": this.selectedJenis
    }
    // console.log('formData',formData);
    
    this.loanService.postSimulation(formData).subscribe((res) => {
      // console.log('formData2',res["status"]);
      
      if (res["status"] == false) {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: res["message"],
        });
        this.errMessage = res["message"];
        this.loadingHitungUlangLoan = false;
        return;
      }

      var tempLoan = res['data'];
      this.dataDetailLoanTmp.approved_amount = parseInt(tempLoan.applied_amount)
      this.dataDetailLoanTmp.dana_deterima = parseInt(tempLoan.applied_dana_diterima)
      this.dataDetailLoanTmp.applied_dana_diterima = parseInt(tempLoan.applied_dana_diterima)
      this.loadingSubmitDoc = false;
      this.loadingHitungUlangLoan = false;
      this.errMessage = null;
      
    },
    (err) => {
      console.log(err);
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: err.error.message,
      });
      this.errMessage = err.error.message;
      this.loadingHitungUlangLoan = false;
    });
    
  }

  handleIsNoAstra(e: any){
    this.kartapAstra = false;
    
  }

  hitungUlang(e: any) {
    this.loadingSubmitDoc = true;
    
    this.loadingHitungUlangLoan = true;


    var formData =
      {
        "loan_new_id": this.loan_new_id,
        "applied_amount": this.selectedJenis == 'Angsuran' ? this.dataDetailLoanTmp.approved_amount : this.dataDetailLoanTmp.dana_deterima,
        "applied_tenor":this.dataDetailLoanTmp.applied_tenor,
        "id_koperasi": this.id_koperasi,
        "is_offset": this.dataDetailLoanTmp.offset_pinjaman == '' ? 'N' : 'Y',
        "kode_pinjaman": this.dataDetailLoanTmp.kode_pinjaman,
        "no_pinjaman": this.dataDetailLoanTmp.offset_pinjaman == '' ? '' : this.dataDetailLoanTmp.offset_pinjaman,
        "validation":  this.dataDetailLoanTmp.verified_affco,
        "calculation_date":  moment().format('DD-MM-YYYY'),
        "applied_code_sim_perhitungan": this.selectedJenis,
        "nilai_pelunasan_kopnit":this.dataDetailLoanTmp.nilai_pelunasan_kopnit,
        "dana_diterima_anggota": parseFloat(this.dataDetailLoanTmp.dana_deterima) - parseFloat(this.dataDetailLoanTmp.nilai_pelunasan_kopnit),
    }
  
    
    this.loanService.postSimulation(formData).subscribe((res) => {
      // console.log('formData2',res);
      
      if (res["status"] == false) {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: res["message"],
        });
        return;
      }

  
      var tempLoan = res['data'];
      this.dataDetailLoanTmp.approved_amount = parseInt(tempLoan.applied_amount)
      this.dataDetailLoanTmp.dana_deterima = parseInt(tempLoan.applied_dana_diterima)
      this.dataDetailLoanTmp.applied_dana_diterima = parseInt(tempLoan.applied_dana_diterima)
      this.dataDetailLoanTmp.dana_diterima_anggota = formData['dana_diterima_anggota']
      this.loadingHitungUlangLoan = false;
      this.errMessage = null;
      
    },
    (err) => {
      console.log(err);
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: err.error.message,
      });
      this.errMessage = err.error.message;
      this.loadingHitungUlangLoan = false;
    });
    
  }


  // Fetch Loan Offset
  // ======================== //
  fetchLoanOffset(idKoperasi, callback) {
    this.loanService.getLoanOffset(idKoperasi).subscribe((res) => {
      if (res["status"] == 0) {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: res["message"],
        });
        this.loading = false;
        return;
      }
      
      this.dataLoanOffsets = res["data"];
      if(this.dataLoanOffsets && this.dataLoanOffsets.length > 0) {
        this.dataLoanOffsets = this.dataLoanOffsets.map((it) => {
          it.offset_amount_text = this.formatCurrency(it.offset_amount);
          return it;
        });
      }
      if(typeof callback === 'function') {
        callback(this.dataLoanOffsets);
      } else {
        this.loading = false;
      }
    });
  }

  // Fetch Golongan KAI
  // ========================= //
  fetchGolonganKai(loanNewid) {
    this.loanService.getLoanGolonganKai(loanNewid).subscribe(
      (res) => {
        _.map(res["data"], (x) => {
          this.golonganKaiList.push({ label: x.golongan_affco, value: x.golongan_affco });
        });
        if (this.dataDetailLoan.verified_affco) {
          this.selectedGolongan = parseInt(this.dataDetailLoan.golongan_affco);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  fetchAlasanBatal() {
    this.loanService.getAlasanBatal().subscribe(
      (res) => {
        this.alasanBatalList = [{ label: "Kategori", value: null }];
        _.map(res["data"], (x) => {
          this.alasanBatalList.push({ label: x.reason, value: x.id });
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // Document Loan
  // ========================== //
  public dataDocuments = null;
  public selectedDocumentLoan = null;
  public dialogDocumentShow: boolean = false;
  public isViewDocumentLoan: boolean = false;
  public isUpdateDocument: boolean = false;
  public imgDocument = null;
  public loadingSubmitDoc: boolean = false;
  public imgDocumentName = '';

  setdocumentList() {
    const _docList = [
        { priority: 1, id: null, keyId: this.DOC_TYPE_FIX_ID_IDENTITY, docNo: '', title: 'KTP', source: this.dataDetailLoan.id_identity_photo, type: this.DOC_TYPE_FIX, model: null },
        { priority: 2, id: null, keyId: this.DOC_TYPE_FIX_ID_CARD, docNo: '', title: 'ID CARD', source: this.dataDetailLoan.id_card_photo, type: this.DOC_TYPE_FIX, model: null },
        { priority: 3, id: null, keyId: this.DOC_TYPE_FIX_PERSONAL, docNo: '', title: 'Foto Selfi', source: this.dataDetailLoan.personal_photo, type: this.DOC_TYPE_FIX, model: null },
    ];
    let lastPriority = _docList.length;

    const {persyaratan_umum, persyaratan_khusus} = this.dataDetailLoan;
    if(persyaratan_umum != null && persyaratan_umum.length > 0) {
        for (const ix in persyaratan_umum) {
            lastPriority++;
            _docList.push({
                priority: lastPriority,
                id: persyaratan_umum[ix].id_loan_persyaratan_umum,
                keyId: persyaratan_umum[ix].cms_persyaratan_umum_id,
                docNo: persyaratan_umum[ix].no_dokumen,
                title: persyaratan_umum[ix].description_persyaratan_umum,
                source: persyaratan_umum[ix].persy_umum_photo,
                type: this.DOC_TYPE_UMUM,
                model: persyaratan_umum[ix],
            });
        }
    }

    if(persyaratan_khusus != null && persyaratan_khusus.length > 0) {
        for (const ix in persyaratan_khusus) {
            lastPriority++;
            _docList.push({
                priority: lastPriority,
                id: persyaratan_khusus[ix].id_loan_persyaratan_kusus,
                keyId: persyaratan_khusus[ix].cms_persyaratan_khusus_id,
                docNo: persyaratan_khusus[ix].no_dokumen,
                title: persyaratan_khusus[ix].description_persyaratan_kusus,
                source: persyaratan_khusus[ix].persy_khusus_photo,
                type: this.DOC_TYPE_KHUSUS,
                model: persyaratan_khusus[ix],
            });
        }
    }
    // console.log('this.dataDocuments', _docList);
    this.dataDocuments = _docList
  }
  viewDocumentLoan(e) {
    this.dialogDocumentShow = true;
    this.selectedDocumentLoan = e;
    this.isViewDocumentLoan = true;
    this.imgDocumentName = null;
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 100);
  }
  closeViewDetailDocument() {
    this.isViewDocumentLoan = false;
    this.selectedDocumentLoan = null;
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 1000);
  }
  editDocumentLoan(e) {
    this.dialogDocumentShow = true;
    this.selectedDocumentLoan = e;
    this.isUpdateDocument = true;
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 100);
  }
  closeEditViewDetailDocument() {
    this.isUpdateDocument = false;
    this.selectedDocumentLoan = null;
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 1000);
  }
  handleFileDocumentInputChange(file) {
    this.imgDocumentName = file[0].name;
    this.getBase64(file[0]);
  }
  getBase64(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      
      this.imgDocument = reader.result
      
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
      this.showErrorMessage(error);
    };
  }
  uploadDocument() {
    let obj = {
      loan_new_id: this.loan_new_id,
      type: this.selectedDocumentLoan.type,
      key_id: this.selectedDocumentLoan.keyId,
      content: this.imgDocument,
      persyaratan_umum: null,
      persyaratan_khusus: null,
    };
    if(this.selectedDocumentLoan.type == this.DOC_TYPE_UMUM) {
      obj.persyaratan_umum = this.selectedDocumentLoan.model
    }
    if(this.selectedDocumentLoan.type == this.DOC_TYPE_KHUSUS) {
      obj.persyaratan_khusus = this.selectedDocumentLoan.model
    }

    // console.log('obj', obj, this.selectedDocumentLoan);

    this.loadingSubmitDoc = true;
    this.loanService.postUploadDocument(obj).subscribe(
      (res) => {
        this.selectedDocumentLoan.source = res['data'].link_url;
        this.updateDocument(this.selectedDocumentLoan);
        this.loadingSubmitDoc = false;
        this.closeEditViewDetailDocument();
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Berhasil Update dokumen",
        });
      },
      (err) => {
        this.loadingSubmitDoc = false;
        this.showErrorMessageEx(err);
      }
    );
  }
  updateDocument(selected) {
    
    if(this.dataDocuments && this.dataDocuments.length > 0) {
      const dataDocuments = JSON.parse(JSON.stringify(this.dataDocuments));
      for(const ix in dataDocuments) {
        if(selected.type == dataDocuments[ix].type && selected.keyId == dataDocuments[ix].keyId) {
          dataDocuments[ix].source = selected.source + '?' + Math.floor(Date.now() / 1000);
          return;
        }
      }
      this.dataDocuments = JSON.parse(JSON.stringify(dataDocuments));
    }
  }
  getImageUrl(it) {
    // return it.source + '?' + Math.floor(Date.now() / 1000);
    let link = it.source;
    if(!link || link == '') return link;
    if(!link.includes('?')) {
        link += '?_v=' + (new Date()).getTime();
    } else {
        link += '&_v=' + (new Date()).getTime();
    }
    return link;
  }
  refreshPage() {
    window.location.reload();
  }

   // Kontrak Loan
  // ========================== //
  public dataKontrak = null;
  public selecteKontrakLoan = null;
  public dialogKontrakShow: boolean = false;
  public isViewKontrakLoan: boolean = false;

  setKontrakList() {
    const _docList = [];
    if(this.dataDetailLoan.kontrak1 && this.dataDetailLoan.kontrak1 != '') {
      _docList.push({ priority: 1, id: null, keyId: 'kontrak1', type: 'image', title: 'Halaman 1', source: this.dataDetailLoan.kontrak1, })
    }
    if(this.dataDetailLoan.kontrak2 && this.dataDetailLoan.kontrak2 != '') {
      _docList.push({ priority: 2, id: null, keyId: 'kontrak2', type: 'image', title: 'Halaman 2', source: this.dataDetailLoan.kontrak2, })
    }
    let lastPriority = _docList.length;

    const {loan_digisign_file} = this.dataDetailLoan;
    if(loan_digisign_file != null && loan_digisign_file.length > 0) {
        for (const ix in loan_digisign_file) {
            lastPriority++;
            _docList.push({
                priority: lastPriority,
                id: loan_digisign_file[ix].id_loan_digisign_file,
                keyId: loan_digisign_file[ix].id_loan_digisign_file,
                type: 'file',
                title: 'Kontrak Digisign',
                source: loan_digisign_file[ix].digisign_file,
            });
        }
    }
    this.dataKontrak = _docList
  }
  viewKontrakLoan(e) {
    this.dialogKontrakShow = true;
    this.selecteKontrakLoan = e;
    this.isViewKontrakLoan = true;
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 100);
  }
  closeViewDetailKontrak() {
    this.isViewKontrakLoan = false;
    this.selecteKontrakLoan = null;
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 1000);
  }
  
  toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }
  isAllowLihatKontrak() {
    // if(['ROLE003', 'ROLE007'].includes(this.id_role_master)) {
    //   return false;
    // }
    return true;
  }

  // Edit Anggota
  // ========================= //
  public loadingUpdateAnggota: boolean = false;
  public disablebtnAnggota: boolean = true;
  updateAnggota() {
    if (!this.selectedGolongan || !this.tanggalKartap) {
      this.showErrorMessage({
        message: 'Silahkan melakukan update golongan dan tanggal karyawan tetap sebelum memperbarui data Anggota'
      });
      return;
    }
    this.loadingUpdateAnggota = true;
    const bodyParams = {
      loan_new_id: this.loan_new_id,
      id_koperasi: this.id_koperasi,
      golongan: this.selectedGolongan,
      salary: this.dataDetailLoan.salary,
      verified_affco: true,
      gol_kai: this.dataDetailLoan.golongan_kai,
      tanggal_kartap: this.tanggalKartap ? moment(this.tanggalKartap, 'DD-MM-YYYY').format('YYYY-MM-DD') : null,
      isno_kartap_astra: this.isnoKartapAstra
    };

    this.loanService.postUpdateLoanMembership(bodyParams).subscribe((res) => {
      if (res["status"] == 0) {
        this.loadingUpdateAnggota = false;
        this.showErrorMessage(res);
        return;
      }
      
      this.loadingUpdateAnggota = false;
      this.dataDetailLoan.golongan_affco = parseInt(this.selectedGolongan);
      this.dataDetailLoan.updated_keanggotaan = res['data'].updated_keanggotaan;
      this.showSuccessMessage("Data anggota berhasil diupdate.");
      this.refreshPage();
    },
    (err) => {
      this.loadingUpdateAnggota = false;
      this.showErrorMessageEx(err);
    });
    
  }
  
  isAllowEditAnggota() {
    return this.dataDetailLoan.status_loan == 'MLTSTS01' && this.dataDetailLoan.is_allowed_approve_hr;;
  }

  // Offset Pinjaman
  showDialogLoanOffset() {
    if(this.dataDetailLoan.offset_pinjaman && this.dataDetailLoan.offset_pinjaman != '') {
      this.selectedOffset = this.dataDetailLoan.offset_pinjaman.split(',');
    } else {
      this.selectedOffset = [];
    }
    this.dialogOffsetShow = true;
  }
  submitDialogLoanOffset() { 
    this.dialogOffsetShow = false;
    let totalOffset = 0;
    if(
      (this.dataLoanOffsets && this.dataLoanOffsets.length > 0) &&
      (this.selectedOffset && this.selectedOffset.length > 0)
    ) {
      for(const ix in this.selectedOffset) {
        for(const ij in this.dataLoanOffsets) {
          if(this.selectedOffset[ix] == this.dataLoanOffsets[ij].loan_no) {
            totalOffset += this.dataLoanOffsets[ij].offset_amount;
          }
        }
      }
    }

    this.dataDetailLoanTmp.offset_pinjaman = this.selectedOffset.join();
    this.dataDetailLoanTmp.total_offset = totalOffset;
    if(this.dataDetailLoanTmp.offset_pinjaman && this.dataDetailLoanTmp.offset_pinjaman != '') {
      const offset_pinjaman_arr = this.dataDetailLoanTmp.offset_pinjaman.split(',');
      this.dataDetailLoanTmp.offset_pinjaman_text = offset_pinjaman_arr.length + ' Pinjaman';
    } else {
      this.dataDetailLoanTmp.offset_pinjaman_text = '';
    }
    this.dataDetailLoan.offset_pinjaman = this.selectedOffset.join();
    this.dataDetailLoan.total_offset = totalOffset;
    if(this.dataDetailLoan.offset_pinjaman && this.dataDetailLoan.offset_pinjaman != '') {
      const offset_pinjaman_arr = this.dataDetailLoan.offset_pinjaman.split(',');
      this.dataDetailLoan.offset_pinjaman_text = offset_pinjaman_arr.length + ' Pinjaman';
    } else {
      this.dataDetailLoan.offset_pinjaman_text = '';
    }
  }

  // Edit Loan
  // ========================= //
  public loadingSubmitEditLoan: boolean = false;
  public loadingHitungUlangLoan: boolean = false;
  public kartapAstra: boolean = true;
  isAllowEditPinjaman() {
    return (this.id_role_master == 'ROLE003' || this.id_role_master == 'ROLE007') 
      && this.dataDetailLoan.status_loan == 'MLTSTS01'
      && this.dataDetailLoan.is_allowed_approve_hr;
  }
  showDialogEditPinjaman() {
    this.dataDetailLoanTmp = JSON.parse(JSON.stringify(this.dataDetailLoan));
    this.dialogEditPinjamanShow = true;
  }
  submitEditLoan() {
    this.loadingSubmitEditLoan = true;

    this.dataDetailLoan = JSON.parse(JSON.stringify(this.dataDetailLoanTmp));

    if(!this.dataDetailLoan.approved_amount || this.dataDetailLoan.approved_amount == '') {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Nilai Pinjaman wajib diisi ",
      });
      this.loadingSubmitEditLoan = false;
      return;
    }
    if(!this.dataDetailLoan.approved_tenor || this.dataDetailLoan.approved_tenor == '') {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Tenor wajib diisi ",
      });
      this.loadingSubmitEditLoan = false;
      return;
    }

  const bodyParams = {
    loan_new_id: this.loan_new_id,
    id_koperasi: this.id_koperasi,
    potongan_kopnit: this.dataDetailLoan.potongan_kopnit,
    potongan_perusahaan: this.dataDetailLoan.potongan_perusahaan,
    rekomendasi_plafon: this.dataDetailLoan.rekomendasi_plafon,
    catatan_hrd: this.dataDetailLoan.catatan_hrd,
    offset_pinjaman: this.dataDetailLoan.offset_pinjaman, 
    total_offset: this.dataDetailLoan.total_offset,
    kode_pinjaman: this.dataDetailLoan.kode_pinjaman,
    approved_amount: this.dataDetailLoanTmp.approved_amount,
    approved_tenor: this.dataDetailLoan.approved_tenor,
    applied_code_sim_perhitungan: this.dataDetailLoan.applied_code_sim_perhitungan,
    approved_simulasi_perhitungan : this.selectedJenis == 'Angsuran' ? 'Pokok Pinjaman (Gross)' : 'Dana Diterima (Nett)',
    approved_code_sim_perhitungan: this.selectedJenis,
    applied_dana_diterima: this.dataDetailLoanTmp.applied_dana_diterima,
    nilai_pelunasan_kopnit: this.dataDetailLoan.nilai_pelunasan_kopnit,
    dana_diterima_anggota: parseFloat(this.dataDetailLoanTmp.dana_deterima) - parseFloat(this.dataDetailLoanTmp.nilai_pelunasan_kopnit),

  };
  
  this.loanService.postUpdateLoanData(bodyParams).subscribe((res) => {
    if (res["status"] == 0) {
      this.loadingSubmitEditLoan = false;
      this.showErrorMessage(res);
      return;
    }
    
    this.loadingSubmitEditLoan = false;
    this.dialogEditPinjamanShow = false;
    this.dataDetailLoan = res['data'];
    this.setDataDetaiLoan();
    this.showSuccessMessage("Data Pinjaman berhasil diupdate.");
    // this.refreshPage();
  },
  (err) => {
    this.loadingSubmitEditLoan = false;
    this.showErrorMessageEx(err);
  });

  }

  // Reject Loan
  // ========================= //
  public dialogRejectLoanShow: boolean = false;
  public loadingSubmitReject: boolean = false;
  public detailAlasanBatal = "";

  submitApproveReject() {
    this.loadingSubmitReject = true;
    // console.log('this.dataDetailLoan', this.dataDetailLoan);
    this.dataDetailLoan.alasan_batal = this.selectedAlasanBatal;
    this.dataDetailLoan.detail_alasan = this.detailAlasanBatal;
    const bodyParams = {
      loan_new_id: this.loan_new_id,
      id_koperasi: this.id_koperasi,
      alasan_batal: this.dataDetailLoan.alasan_batal,
      detail_alasan: this.dataDetailLoan.detail_alasan,
    };

    this.loanService.postRejectLoanData(bodyParams).subscribe((res) => {
      if (res["status"] == 0) {
        this.loadingSubmitReject = false;
        this.showErrorMessage(res);
        return;
      }
      
      this.loadingSubmitReject = false;
      this.dialogRejectLoanShow = false;
      this.dataDetailLoan = res['data'];
      this.setDataDetaiLoan();
      this.showSuccessMessage("Data Pinjaman berhasil ditolak.");
    },
    (err) => {
      this.loadingSubmitReject = false;
      this.showErrorMessageEx(err);
    });
  }

  // Approve Loan
  // ========================= //
  public loadingSubmitApprove: boolean = false;
  submitApproveLoan() {
    if (this.id_role_master !== 'ROLE007') {
      if (!this.verifiedAffco) {
        this.showErrorMessage({
          message: 'Silahkan melakukan update golongan dan tanggal karyawan tetap sebelum menyetujui pinjaman'
        });
        return;
      }
    }

    if (this.isnoKartapAstra) {
      this.showErrorMessage({
        message: 'Pinjaman tidak dapat disetujui, Anggota bukan karyawan Astra'
      });
      return;
    }

    this.loadingSubmitApprove = true;
    const bodyParams = {
      loan_new_id: this.loan_new_id,
      id_koperasi: this.id_koperasi,
      approved_amount: this.dataDetailLoan.approved_amount,
      approved_tenor: this.dataDetailLoan.approved_tenor,
    };

    this.loanService.postApproveLoanData(bodyParams).subscribe((res) => {
      if (res["status"] == 0) {
        this.loadingSubmitApprove = false;
        this.showErrorMessage(res);
        return;
      }
      
      this.loadingSubmitApprove = false;
      this.dataDetailLoan = res['data'];
      this.setDataDetaiLoan();
      this.showSuccessMessage("Data Pinjaman berhasil disetujui.");
      // this.refreshPage();
      // this.router.navigate(['/main/loan-new/list']);
    },
    (err) => {
      this.loadingSubmitApprove = false;
      this.showErrorMessageEx(err);
    });
  }

  onClickBtnReject() {
    if (this.id_role_master !== 'ROLE007') {
      if (!this.verifiedAffco && !this.isnoKartapAstra) {
        this.showErrorMessage({
          message: 'Silahkan melakukan update golongan dan tanggal karyawan tetap sebelum membatalkan pinjaman'
        });
        return;
      }
    }

    this.dialogRejectLoanShow = true
  }

  showErrorMessage(res) {
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: res["message"],
    });
  }
  showSuccessMessage(message) {
    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: message,
    });
  }
  showErrorMessageEx(err) {
    let message = 'Failed. Server Error';
    try{
      message = err.error.message
    } catch {
      message = `Backend returned code ${err.status}, body was: ${err.error}`
    }
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: message,
    });
    console.log('err', err, err.error.message)
  }

}
