import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { APIService } from "../../service/api.service";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class LoanService {
  private urlGetLoan =
    this.APIService["hostLoanCoreNew"] + "/loan/backend/index";
  private urlGetLoanDetail =
    this.APIService["hostLoanCore"] + "/loan/backend/detail";
  private urlGetLoanDocument =
    this.APIService["hostLoanCore"] + "/loan/backend/document";
  private urlGetGrade = this.APIService["hostAuth"] + "/mst/grade";
  private urlGetCompany = this.APIService["hostAuth"] + "/company/auth/get";
  private urlGetCompany2 = this.APIService["hostAuth"] + "/company/get";
  private urlUploadDoc = this.APIService["hostAuth"] + "/profile/document/add";
  private urlUploadDocUpdate =
    this.APIService["hostAuth"] + "/profile/document/update";
  private urlPathDownload =
    this.APIService["hostAuth"] + "/loan/profile/detail";
  private urlStatusApproval =
    this.APIService["hostLoanCoreNew"] + "/loan/status-approval";

  private urlGetNoncooperative =
    this.APIService["hostLoanCore"] + "/master/loan/noncooperative/type";
  private urlPostLoanNoncooperative =
    this.APIService["hostLoanCore"] + "/noncoorperative/loan";
  private urlPutApprove =
    this.APIService["hostLoanCore"] + "/loan/backend/approve";
  private urlPutReject =
    this.APIService["hostLoanCore"] + "/loan/backend/reject";
  private getMasterLoanStatus =
    this.APIService["hostLoanCore"] + "/master/loan/status/index";
  private urlPostLoanSimulation =
    this.APIService["hostLoanCore"] + "/loan/backend/simulation";
  private getDownloadExcelLink =
    this.APIService["hostLoanCore"] + "/loan/backend/index/download";

  private urlPostSalary = this.APIService["hostAuth"] + "/salary/req";

  private urlGetStatus = this.APIService["hostLoanNew"] + "/status/get";

  constructor(private APIService: APIService, private http: HttpClient) {}

  // Update Token
  // ======================== //
  updateToken(token) {
    localStorage.setItem("token", token);
    location.reload();
  }

  // Post Sallary
  // ====================== //
  postSalary(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    return this.http.post(this.urlPostSalary, obj, options);
  }
  // Post Document
  // ====================== //
  postDocumentAdd(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    return this.http.post(this.urlUploadDoc, obj, options);
  }
  updateDocument(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    return this.http.post(this.urlUploadDocUpdate, obj, options);
  }

  getDownloaReportPoint(param) {
    let filter = param != undefined ? param : "";

    return 'https://u.koperasi-astra.com/download-file-rekap?' + filter;
  }

  getStatus() {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    return this.http.get(this.urlGetStatus, options);
  }

  // U[date] Approve Loan
  // ====================== //
  postRejectLoan(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    const body = obj;
    return this.http.put(this.urlPutReject, body, options);
  }

  // Update Approve Loan
  // ====================== //
  postApproveLoan(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    const body = obj;
    return this.http.put(this.urlPutApprove, body, options);
  }
  postLoanSimulation(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    const body = obj;
    return this.http.post(this.urlPostLoanSimulation, body, options);
  }

  // Post Loan Non Cooperative
  // ====================== //
  postPostLoanNoncooperative(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    const body = obj;
    return this.http.post(this.urlPostLoanNoncooperative, body, options);
  }

  bikinParam(param) {
    let str = "";
    for (const key in param) {
      if (param[key] != "" && param[key] != undefined) {
        if (str != "") {
          str += "&";
        }
        str += key + "=" + encodeURIComponent(param[key]);
      }
    }
    return str;
  }
  // Get Loan
  // ====================== //
  // getLoan(start, length, objFilter) {
  getLoan(start, rows, param) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    let filter = param != undefined ? param : "";
    // const filter = this.bikinParam(param);
    // return this.http.get(this.urlGetLoan + '?start=' + start +  '&length=' + length + '&sort=request_date,desc&manual_filter=' + filter, options);
    return this.http.get(
      this.urlGetLoan + "?page=" + start + "&rows=" + rows + filter,
      options
    );
  }
  getNoncoperateLoan() {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    return this.http.get(this.urlGetNoncooperative, options);
  }
  getLoadDocument(id_user, loan_type) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    return this.http.get(
      this.urlGetLoanDocument +
        "?id_user=" +
        id_user +
        "&loanType=" +
        loan_type,
      options
    );
  }
  getLoadDetail(id_user, id_loan) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    return this.http.get(
      this.urlGetLoanDetail + "?id_user=" + id_user + "&id_loan=" + id_loan,
      options
    );
  }

  getPathDownloadDocument(id_loan) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    return this.http
      .get<any>(this.urlPathDownload + "?id_loan=" + id_loan, options)
      .pipe(map((res) => res));
  }

  getDownloaReport(param) {
    let filter = param != undefined ? param : "";

    return 'https://u.koperasi-astra.com/download-file-report?' + filter;
  }

  postNoteReject(params) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };

    let body = JSON.stringify(params);
    return this.http
      .post<any>(this.urlStatusApproval, body, options)
      .pipe(map((res) => res));
  }
  // Get Master
  // ====================== //
  getGrade() {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    return this.http.get(this.urlGetGrade, options);
  }
  getStatusLoan() {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    return this.http.get(this.getMasterLoanStatus, options);
  }
  getDownloadLink() {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    return this.http.get(this.getDownloadExcelLink, options);
  }
  getCompany() {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    return this.http.get(this.urlGetCompany, options);
  }
  getCompany2() {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    return this.http.get(this.urlGetCompany2, options);
  }
}
