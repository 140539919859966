/* tslint:disable:whitespace */
import { Component, OnInit, ViewChild, ElementRef} from "@angular/core";
import { Router } from "@angular/router";
import { LoanReportService } from "../loan-report.service";
import { LoanNewService } from "../../../admin-page/loan-new/loan-new.service";
import { MessageService } from "primeng/components/common/messageservice";
import * as _ from "lodash";
import * as moment from "moment";
declare var $: any;
import { Paginator } from "primeng/primeng";

@Component({
  selector: "app-loan-report-progress",
  templateUrl: "./loan-report-progress.component.html",
  styleUrls: ["./loan-report-progress.component.scss"],
  providers: [MessageService],
})
export class LoanReportProgressComponent implements OnInit {
  private objFilter: any;
  public id_role_master;
  public loading: boolean;
  public date_range = null;
  public id_koperasi = null;
  public linkDownload = '';
  public companies = [];
  public loanTypeAllList = [];
  public selectedLoanType = null;
  public selectedCompany = null;

  @ViewChild("p") pagination: Paginator;
  @ViewChild('hiddenLink') hiddenLink: ElementRef<HTMLAnchorElement>;

  constructor(
    private loanReportService: LoanReportService,
    private loanService: LoanNewService,
    private router: Router, 
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.id_role_master = localStorage.getItem("id_role_master");
    let endDate = new Date();
    let startDate = new Date();
    startDate.setDate(endDate.getDate() - 7);
    this.date_range = [ startDate, endDate];
    console.log('date_range', this.date_range);

    this.fetchCompany();
    this.fetchLoanTipeAll();
  }

  // Fetch Company
  // ========================= //
  fetchCompany() {
    this.loading = true;
    this.loanService.getLoanCompanies().subscribe(
      (res) => {
        this.loading = false;
        if(['ROLE003', 'ROLE007'].includes(this.id_role_master)) {
          this.companies = [];
          _.map(res["data"], (x) => {
            this.companies.push({ label: x.perusahaan, value: x.perusahaan });
          });
          this.selectedCompany = res["data"] && res["data"].length > 0 ? res["data"][0].perusahaan : null;
        } else {
          this.companies = [{ label: "Semua Perusahaan", value: null }];
          _.map(res["data"], (x) => {
            this.companies.push({ label: x.perusahaan, value: x.perusahaan });
          });
        }
      },
      (err) => {
        this.loading = false;
        console.log(err);
      }
    );
  }

  // Fetch Jenis Pinjaman All
  // ========================= //
  fetchLoanTipeAll() {
    this.loanService.getLoanTipeAll().subscribe(
      (res) => {
        this.loanTypeAllList = [{ label: "Semua Pinjaman", value: null }];
        _.map(res["data"], (x) => {
          this.loanTypeAllList.push({ label: x.nama_pinjaman, value: x.kode_pinjaman });
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }

  fetchDownloadReportProgress() {
    this.hiddenLink.nativeElement.href = this.loanReportService.getDownloaReportProgress(this.objFilter);
    this.hiddenLink.nativeElement.click();
  }

  donwloadReport(valFilter: any) {
    if(!this.companies || this.companies.length == 0) {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: 'Perusahaan tidak boleh kosong',
      });
      console.log('Perusahaan tidak boleh kosong')
      return;
    }

    const {
      id_koperasi,
      date_range,
      perusahaan,
      kode_pinjaman,
    } = valFilter;

    let qIdKoperasi = id_koperasi != null ? `&id_koperasi=${id_koperasi}` : "";
    let qPerusahaan = perusahaan != undefined ? `&perusahaan=${perusahaan}` : "";
    let qKodePinjaman = kode_pinjaman != undefined ? `&kode_pinjaman=${kode_pinjaman}` : "";
    let qDateRange: string = "";
    if (date_range != undefined) {
      qDateRange = date_range[1] != null
          ? `&start_date=${moment(date_range[0]).format("YYYY-MM-DD")}&end_date=${moment(date_range[1]).format("YYYY-MM-DD")}`
          : `&start_date=${moment(date_range[0]).format("YYYY-MM-DD")}&end_date=${moment(date_range[0]).format("YYYY-MM-DD")}`;
    }
    this.objFilter = `${qIdKoperasi}${qPerusahaan}${qKodePinjaman}${qDateRange}`;
    console.log('date_range', date_range)
    this.fetchDownloadReportProgress();
  }

  resetFilter() {
    this.objFilter = "";
  }

  showErrorMessageEx(err) {
    let message = 'Failed. Server Error';
    try{
      message = err.error.message
    } catch {
      message = `Backend returned code ${err.status}, body was: ${err.error}`
    }
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: message,
    });
    console.log('err', err, err.error.message)
  }
  
}
