import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainTicketComponent } from './main-ticket/main-ticket.component';
import { TicketPesawatComponent } from './ticket-pesawat/ticket-pesawat.component';
import { TicketKeretaComponent } from './ticket-kereta/ticket-kereta.component';
import { TicketPesawatDetailComponent } from './ticket-pesawat-detail/ticket-pesawat-detail.component';
import { TicketKeretaDetailComponent } from './ticket-kereta-detail/ticket-kereta-detail.component';

import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { IconsModule } from '../../icons/icons.module';
import { TicketsService } from './tickets.service';

import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { ToastModule } from 'primeng/toast';
import { SidebarModule } from 'primeng/sidebar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { SpinnerModule } from 'primeng/spinner';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CustomFormsModule } from 'ng2-validation';

import {PaginatorModule} from 'primeng/paginator';
import { BookingHotelComponent } from './booking-hotel/booking-hotel.component';
import { BookingHotelDetailComponent } from './booking-hotel-detail/booking-hotel-detail.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    CommonModule,
    BrowserAnimationsModule,
    IconsModule,
    HttpClientModule,
    CustomFormsModule,
    ReactiveFormsModule,

    TableModule,
    ToastModule,
    SidebarModule,
    ProgressSpinnerModule,
    MultiSelectModule,
    DropdownModule,
    CalendarModule,
    DialogModule,
    SpinnerModule,
    FileUploadModule,
    InputSwitchModule,
    PaginatorModule,
  ],
  declarations: [MainTicketComponent, TicketPesawatComponent, TicketKeretaComponent, TicketPesawatDetailComponent, TicketKeretaDetailComponent, BookingHotelComponent, BookingHotelDetailComponent],
  providers: [TicketsService]
})
export class TicketsModule { }
