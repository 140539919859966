import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { IconsModule } from '../../icons/icons.module';

import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { ToastModule } from 'primeng/toast';
import { SidebarModule } from 'primeng/sidebar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { PaginatorModule } from 'primeng/paginator';
import { TabViewModule } from 'primeng/tabview';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SpinnerModule } from 'primeng/spinner';
import { CustomFormsModule } from 'ng2-validation';
import {CheckboxModule} from 'primeng/checkbox';
import { RadioButtonModule } from "primeng/primeng";

import { MainPelunasanComponent } from './main-pelunasan/main-pelunasan.component';
import { PelunasanListComponent } from './pelunasan-list/pelunasan-list.component';
import { PelunasanDetailComponent } from './pelunasan-detail/pelunasan-detail.component';

@NgModule({
	imports: [
		RouterModule,
		FormsModule,
		CommonModule,
		BrowserAnimationsModule,
		IconsModule,
		HttpClientModule,
		CustomFormsModule,
		ReactiveFormsModule,
		CheckboxModule,
		RadioButtonModule,

		TableModule,
		ToastModule,
		SidebarModule,
		ProgressSpinnerModule,
		MultiSelectModule,
		DropdownModule,
		CalendarModule,
		DialogModule,
		SpinnerModule,
		FileUploadModule,
		PaginatorModule,
		TabViewModule,
		InputSwitchModule
	],
	declarations: [
		MainPelunasanComponent, 
		PelunasanListComponent, 
		PelunasanDetailComponent
	]
})
export class PelunasanModule { }
