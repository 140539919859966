import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-setting-product',
  templateUrl: './setting-product.component.html',
  styleUrls: ['./setting-product.component.scss']
})
export class SettingProductComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
