import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ticket-kereta-detail',
  templateUrl: './ticket-kereta-detail.component.html',
  styleUrls: ['./ticket-kereta-detail.component.scss']
})
export class TicketKeretaDetailComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
