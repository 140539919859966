import { Component, OnInit } from '@angular/core';
import { SignService } from "../sign.service";
import { Message,MessageService } from "primeng/components/common/api";
import * as _ from "lodash";
interface selectedItem {
    email: string;
    status: number;6
  }
  
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  providers: [MessageService],
})
export class SignupComponent implements OnInit {
	// Input Dropdown
	// ====================== //
    selectedItem: any = {};
    public message: Message[] = [];
    loading: boolean = false;
    loadingSave: boolean = false;
    displayForm: boolean = false;
    isEdit: boolean = false;
    constructor(
        private signService: SignService,
        private messageService: MessageService
      ) {}
	ngOnInit() {
	}
    showThis(val: selectedItem) {
      
      }  
  async save(e: any) {
    this.loadingSave = true;
    const param = {
        email: e.email
    };
      this.signService.sendEmail(param).subscribe((res) => {
        alert('Email terkirim! Silahkan cek email anda!');
       this.resetForm();
       this.loadingSave = false;
       window.location.href=location.origin+"/sign/signin";
      },
      (err) => {
        alert(err.error.message);
      });
    

}

  resetForm() {
    // this.loading = false;
    // this.loadingSave = false;
    // this.selectedThumbnail = null;
    this.selectedItem = "";
    // this.isEdit = false;
    // this.idContent = null;
    // this.displayForm = false;
    this.ngOnInit();

  }


    


    

}
