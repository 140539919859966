/* tslint:disable:whitespace */
import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { LoanService } from "../loan.service";
import { MessageService } from "primeng/components/common/messageservice";
import * as _ from "lodash";
import * as moment from "moment";
declare var $: any;
import { Paginator } from "primeng/primeng";

@Component({
  selector: "app-microloan",
  templateUrl: "./microloan.component.html",
  styleUrls: ["./microloan.component.scss"],
  providers: [MessageService],
})
export class MicroloanComponent implements OnInit {
  private objFilter: any;
  public data: any = [];
  public columns: any = [];
  public selectedColumns: any[];
  public loading: boolean;
  public loading_pagination = false;
  public selectedItem = null;
  public grades = [];
  public statusList = [];
  public companies = [];
  public start = 1;

  public totalCount = 0;
  public pageLength = 10;
  public originGrades = [];
  public selectedGrade = null;
  public urlDownload = null;
  public date: Date = null;
  public date1: Date = null;
  public date2: Date = null;
  public minDate = moment().add("days", -1)["_d"];
  public isSubmitApprove = false;
  public isSubmitReject = false;
  public widthTable = 0;
  public name = "";
  public loan_number = "";
  public id_company = "";
  public start_date = "";
  public end_date = "";
  public id_grade = "";
  public id_workflow_status = "";
  public id_loan_type = "";
  public nik = "";
  @ViewChild("p") pagination: Paginator;

  public loanTypes = [
    { label: "Semua tipe Loan", value: null },
    { label: "Microloan", value: 1 },
    { label: "Middle Loan", value: 2 },
  ];
  public selectedLoan = null;

  public arrStatus = [
    { label: "All", value: null },
    { label: "Menunggu pembayaran", value: "a" },
    { label: "Menunggu Approval HR", value: "b" },
    { label: "Menunggu Approval Keanggotaan", value: "c" },
  ];
  public selectedStatus = null;

  constructor(private loanService: LoanService, private router: Router) {}

  ngOnInit() {
    const date1 = moment().subtract(4, "months").format("YYYY-MM-[01]");
    const date2 = moment().add(1, "days").format("YYYY-MM-DD");
    this.start_date = date1;
    this.end_date = date2;
    this.fetchLoan();
    this.fetchCompany();
    this.fetchStatusLoan();

    this.fetchGrade();
    this.columns = [
      { field: "last_approval", header: "Last Approval", show: true },
      { field: "next_approval", header: "Next Approval", show: true },
      { field: "number", header: "No Pinjaman", show: true },
      { field: "name_company", header: "Company", show: true },
      { field: "id_koperasi", header: "No Anggota", show: true },
      { field: "name", header: "Nama Anggota", show: true },
      { field: "position", header: "Jabatan", show: true },
      { field: "name_grade", header: "Golongan", show: true },
      { field: "id_employee", header: "NIK", show: true },
      { field: "loan_type_name", header: "Tipe Pinjaman", show: true },
      {
        field: "requested_loan",
        header: "Total Pinjaman yg di ajukan",
        show: true,
      },
      {
        field: "requested_installment",
        header: "Angsuran Perbulan",
        show: true,
      },
      {
        field: "approved_loan",
        header: "Pinjaman yang di setujui",
        show: true,
      },
      { field: "loan_actual", header: "Pinjaman Aktual", show: true },

      {
        field: "approved_installment",
        header: "Angsuran Perbulan yang disetujui",
        show: true,
      },
      // {field: 'microloan_amount', header: 'Jumlah Microloan', show: true},
      { field: "term", header: "Tenor", show: true },
      { field: "interest", header: "Bunga", show: false },
      { field: "request_date", header: "Tgl Pengajuan", show: true },
      { field: "workflow_status_name", header: "Status", show: true },
      // {field: 'name_loan_type', header: 'Produk Pinjaman', show:true},
    ];
    this.selectedColumns = _.filter(this.columns, { show: true });

    const widthTB = $("#tab-card").width();
    this.widthTable = widthTB;
    $(window).on("resize", function () {
      const widthTB = $("#tab-card").width();
      this.widthTable = widthTB;
    });
  }

  // Toggle Columns
  // ====================== //
  changeColumn(e) {
    const find = _.find(this.columns, e.itemValue);
    if (e.itemValue != undefined) {
      if (e.itemValue.show) {
        find.show = false;
      } else {
        find.show = true;
      }
    } else {
    }

    this.selectedColumns = _.filter(this.columns, { show: true });
  }
  compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const bandA = a.request_date.toUpperCase();
    const bandB = b.request_date.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison * -1;
  }
  // Fetching Loan
  // ========================= //
  fetchLoan() {
    this.loading = true;
    this.loading_pagination = true;
    this.loanService
      .getLoan(this.start, this.pageLength, this.objFilter)
      .subscribe(
        (res: any) => {
          this.data = res["data"]["data"];
          this.totalCount = res.data && res.data.count_all;
          // const tmp = this.data.sort(this.compare);
          const tmp = this.data;
          _.map(tmp, (x, i) => {
            x.number = x.loan_number;
            x.approved_loan = "Rp " + x.approved_loan.toLocaleString();
            x.requested_installment =
              "Rp " + x.requested_installment.toLocaleString();
            x.approved_installment =
              "Rp " + x.approved_installment.toLocaleString();
            x.requested_loan = "Rp " + x.requested_loan.toLocaleString();
            x.loan_actual = "Rp " + x.loan_actual.toLocaleString();
            // x.microloan_amount = 'Rp ' + x.microloan_amount.toLocaleString();

            x.request_date = moment(x.request_date).format("DD MMM YYYY");
          });

          // store.dispatch({ type: 'COUNTER', jmlCalongAnggota: Number(res['data'].count_all) });
          this.loading = false;
          this.loading_pagination = false;
        },
        (err) => {
          this.loading = false;
          this.loading_pagination = false;
        }
      );
  }

  // Select Item / User
  // ======================== //
  selectItem(e) {
    console.log(e);
    this.router.navigate(["/main/loan/detail/" + e.id_loan], {
      queryParams: {
        type: e.id_loan_type,
        user: e.id_user,
        idemployee: e.id_employee,
      },
    });
  }

  // Fetch Grade
  // ========================= //
  fetchGrade() {
    this.loanService.getGrade().subscribe(
      (res) => {
        this.originGrades = res["data"];
        this.grades = [{ label: "Semua Golongan", value: null }];
        _.map(res["data"], (x) => {
          const obj = { label: x.name_grade, value: x.id_grade };
          this.grades.push(obj);
        });
      },
      (err) => {
        // this.fetchGrade();
        console.log(err);
      }
    );
  }
  downloadExcel() {
    this.loanService.getDownloadLink().subscribe(
      (res) => {
        this.urlDownload = res["data"];
      },
      (err) => {
        // this.fetchGrade();
        console.log(err);
      }
    );
  }

  // "id_workflow_status": "string",
  // "workflow_status_name": "string"
  fetchStatusLoan() {
    this.loanService.getStatusLoan().subscribe(
      (res) => {
        this.statusList = [{ label: "Semua Status", value: null }];
        _.map(res["data"], (x) => {
          const obj = {
            label: x.workflow_status_name,
            value: x.id_workflow_status,
          };
          this.statusList.push(obj);
        });
      },
      (err) => {
        // this.fetchGrade();
        console.log(err);
      }
    );
  }

  // Fetch Company
  // ========================= //
  fetchCompany() {
    this.loanService.getCompany().subscribe(
      (res) => {
        this.companies = [{ label: "Semua Perusahaan", value: null }];
        _.map(res["data"], (x) => {
          this.companies.push({ label: x.name_company, value: x.id_company });
        });
      },
      (err) => {
        console.log(err);
        // this.fetchLoan();
      }
    );
  }

  paginate(e) {
    this.start = e.page + 1;
    this.fetchLoan();
  }

  // FILTER
  submitFilter(valFilter: any) {
    const {
      nama,
      id_koperasi,
      tgl_masuk,
      loan_number,
      id_workflow_status,
      id_grade,
      company,
    } = valFilter;
    let name = nama != null ? `&name=${nama}` : "";
    let noAnggota = id_koperasi != null ? `&id_koperasi=${id_koperasi}` : "";
    let loanNumber = loan_number != null ? `&loan_number=${loan_number}` : "";
    let idWorkflowStatus =
      id_workflow_status != undefined
        ? `&id_workflow_status=${id_workflow_status}`
        : "";
    let grade = id_grade != undefined ? `&id_grade=${id_grade}` : "";
    let companies = company != undefined ? `&id_company=${company}` : "";
    let dateRange: string = "";
    if (tgl_masuk != undefined) {
      dateRange =
        tgl_masuk[1] != null
          ? `&start_date=${moment(tgl_masuk[0]).format(
              "YYYY-MM-DD"
            )}&end_date=${moment(tgl_masuk[1]).format("YYYY-MM-DD")}`
          : `&start_date=${moment(tgl_masuk[0]).format(
              "YYYY-MM-DD"
            )}&end_date=${moment(tgl_masuk[0]).format("YYYY-MM-DD")}`;
    }
    this.objFilter = `${name}${noAnggota}${loanNumber}${idWorkflowStatus}${grade}${companies}${dateRange}`;
    this.pagination.changePageToFirst(event);
    this.start = 1;
    this.fetchLoan();
  }

  resetFilter() {
    this.objFilter = "";
    this.pagination.changePageToFirst(event);
    this.fetchLoan();
    this.start = 1;
  }

  // private typingTimer;
  // private doneTypingInterval = 1000;
  // onSearchName(searchValue: string) {
  //   clearTimeout(this.typingTimer);
  //   this.typingTimer = setTimeout(() => {
  //     this.name = searchValue;
  //     if (searchValue) {
  //       this.objFilter["nama"] = searchValue;
  //     } else {
  //       delete this.objFilter["nama"];
  //     }
  //     this.start = 0;
  //     this.loading_pagination = true;
  //     this.fetchLoan(1);
  //   }, this.doneTypingInterval);
  // }
  // onSearchPinjaman(searchValue: string) {
  //   clearTimeout(this.typingTimer);
  //   this.typingTimer = setTimeout(() => {
  //     this.loan_number = searchValue;
  //     if (searchValue) {
  //       this.objFilter["loan_number"] = searchValue;
  //     } else {
  //       delete this.objFilter["loan_number"];
  //     }
  //     this.start = 0;
  //     this.loading_pagination = true;
  //     this.fetchLoan(1);
  //   }, this.doneTypingInterval);
  // }
  // onSearchNik(searchValue: string) {
  //   clearTimeout(this.typingTimer);
  //   this.typingTimer = setTimeout(() => {
  //     if (searchValue) {
  //       this.objFilter["no_anggota"] = searchValue;
  //     } else {
  //       delete this.objFilter["no_anggota"];
  //     }
  //     this.nik = searchValue;
  //     this.start = 0;
  //     this.loading_pagination = true;
  //     this.fetchLoan(1);
  //   }, this.doneTypingInterval);
  // }
  // changeCompany(e) {
  //   if (e.value) {
  //     this.objFilter["company"] = e.value;
  //   } else {
  //     delete this.objFilter["company"];
  //   }
  //   this.id_company = e.value;
  //   this.start = 0;
  //   this.loading_pagination = true;
  //   this.fetchLoan(1);
  // }
  // changeGrade(e) {
  //   if (e.value) {
  //     this.objFilter["golongan"] = e.value;
  //   } else {
  //     delete this.objFilter["golongan"];
  //   }
  //   this.id_grade = e.value;
  //   this.start = 0;
  //   this.loading_pagination = true;
  //   this.fetchLoan(1);
  // }
  // changeStatus(e) {
  //   if (e.value) {
  //     this.objFilter["id_workflow_status"] = e.value;
  //   } else {
  //     delete this.objFilter["id_workflow_status"];
  //   }
  //   this.id_workflow_status = e.value;
  //   this.start = 0;
  //   this.fetchLoan(1);
  // }
  // onSelectTglMasuk(e) {
  //   if (this.date1) {
  //     if (this.date1[1]) {
  //       const date1 = moment(this.date1[0]).format("YYYY-MM-DD");
  //       const date2 = moment(this.date1[1]).format("YYYY-MM-DD");
  //       this.start_date = date1;
  //       this.end_date = date2;
  //       this.loading_pagination = true;
  //       this.fetchLoan(1);
  //       this.objFilter["tgl_masuk"] = "2019-02-01 - 2019-02-15";
  //     } else {
  //       this.start_date = "";
  //       this.end_date = "";
  //       delete this.objFilter["tgl_masuk"];
  //     }
  //     this.start = 0;
  //   } else {
  //     this.start_date = "";
  //     this.end_date = "";
  //     this.fetchLoan(1);
  //   }
  // }
  // onClearClick(e) {
  //   this.start_date = "";
  //   this.end_date = "";
  //   this.fetchLoan(1);
  // }
}
