// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//rebuild

export const environment = {
  production: false,
  hostAuth: "https://lendtick-user-staging.koperasi-astra.com",
  // hostAuth: "http://192.168.100.115:7072",
  hostProduct: "https://commerce-kai-product.azurewebsites.net",
  hostLoanCore: "https://commerce-kai-loan-core-opusb-stg.azurewebsites.net",
  hostLoanCodeStaging: "https://commerce-kai-loan-core-opusb-stg.azurewebsites.net",
  hostLoanCoreNew: "https://commerce-kai-loan-new-opusb-staging.azurewebsites.net",
  hostFlight: "https://commerce-kai-flight-staging.azurewebsites.net",
  hostHotel: "https://commerce-kai-order-hotel-staging.azurewebsites.net",
  hostLoan: "https://commerce-kai-loan-staging.azurewebsites.net",
  hostContent: "https://lendtick-catalog.koperasi-astra.com",
  hostLoanNew: "https://loan-new-sadewa-dev.koperasi-astra.com",
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
