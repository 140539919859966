import { Component, OnInit } from "@angular/core";
import { store } from "../../service/store.service";
import { MemberService } from "../member/member.service";
declare var $: any;

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  public roleId;
  public counter: Number;
  public isHR: boolean = false;
  public isHRMenu: boolean = false;
  public isAR: boolean = false;
  public isRole988: boolean = false;
  public rolenya;
  constructor(private memberService: MemberService) {
    store.subscribe(() => {
      this.counter = store.getState().member.jmlCalongAnggota;
    });
  }

  ngOnInit() {
    let role = localStorage.getItem("id_role_master");
    this.rolenya = role;
    console.log(role);
    if (role == "ROLE003" || role == "ROLE904" || role == "ROLE905" || role == "ROLE007" || role == "ROLE006" || role == "ROLE988" )  {
      this.isHR = true;
    } else {
      this.isHR = false;
    }
    if (role == "ROLE003" || role == "ROLE007" || role == "ROLE904" || role == "ROLE905" || role == "ROLE988" )  {
      this.isAR = true;
    } else {
      this.isAR = false;
    }

    if (role == "ROLE006" || role == "ROLE988" )  {
      this.isHRMenu = true;
    } else {
      this.isHRMenu = false;
    }

    if (role == "ROLE003" || role == "ROLE007" || role == "ROLE904" || role == "ROLE905"|| role == "ROLE006"|| role == "ROLE008" )  {
      this.isRole988 = true;
    } else {
      this.isRole988 = false;
    }
    $("nav").coreNavigation({
      layout: "sidebar",
      responsideSlide: true, // true or false
      dropdownEvent: "accordion",
      mode: "fixed",
    });
    $(".dropdown-overlay").hide();
    $(".core-nav-list li.dropdown").each(function () {
      setTimeout(() => {
        $("a.active", this).closest("li.dropdown").addClass("open");
      }, 500);
    });

    this.memberService.getAprrovalUser(0, 1000, null).subscribe((res) => {
      this.counter = res["data"].count_filter;
    });
  }

  ngAfterViewInit() {
    this.roleId = localStorage.getItem("id_role_master");
  }
}
