import { Component, OnInit, ViewChild } from "@angular/core";
import { MemberService } from "../../member/member.service";
import { LoanService } from "../../loan/loan.service";
import { MasterService } from "../master.service";
import { MessageService } from "primeng/components/common/messageservice";
import * as _ from "lodash";
import * as moment from "moment";
import { Dropdown } from "primeng/dropdown";

@Component({
  selector: "app-company",
  templateUrl: "./company.component.html",
  styleUrls: ["./company.component.scss"],
  providers: [MessageService],
})
export class CompanyComponent implements OnInit {
  public data: any = [];
  public columns: any = [];
  public display = false;
  public displayImg = false;
  public isAddMulai = false;
  public isEditMulai = false;
  public approveDialoge = false;
  public rejectDialoge = false;
  public addModal = false;
  public selectedColumns: any[];
  public loading: boolean;
  public selectedItem = null;
  public selectedImg = null;
  public grades = [];
  public parent_company_id = null;
  public grades2 = [];
  public selectedGrade = null;
  public companies = [];
  public roles = [];
  public companies2 = [];
  public selectedCompany = null;
  public originGrades = [];
  public arrStatus = [];
  public arrReligion = [];
  public arrDomicile = [];
  public arrMariege = [];
  public statusOpsi = [];
  public arrRole = [];
  public selectedStatus = null;
  public date: Date = null;
  public date1: Date = null;
  public date2: Date = null;
  public minDate = moment().add("days", -1)["_d"];
  public isSubmitApprove = false;
  public isSubmitReject = false;
  public isChildCompany = false;
  public viewDetail = false;
  public totalCount = 0;
  public isAktif = true;
  public grades3 = [];
  //public filteredProfile = [];
  public start = 0;
  public pageLength = 10;
  public availabelColumn: Number;
  public rateControl: Number;
  public widthDisplay: number;
  public roleId;
  public status = "";
  public pic_email = "";
  public picemail2 = "";
  public picemail3 = "";
  public level = "";
  public level2 = "";
  public level3 = "";
  public pic_email1 = "";
  public pic_email2 = "";
  public pic_email3 = "";
  public pic_email4 = "";
  public pic_email5 = "";

  public pic_email21 = "";
  public pic_email22 = "";
  public pic_email23 = "";
  public pic_email24 = "";
  public pic_email25 = "";

  public pic_email31 = "";
  public pic_email32 = "";
  public pic_email33 = "";
  public pic_email34 = "";
  public pic_email35 = "";

  public level_dari_a ="";
  public level_dari_b ="";
  public level_dari_c ="";

  public level_sampai_a ="";
  public level_sampai_b ="";
  public level_sampai_c ="";


  public is_kopnit = 0;
  public kopnitArray = [
    { label: "Yes", value: 1 },
    { label: "No", value: 0 },
  ];
  public name_company: string = "";
  public phone_number: string = "";
  public loan_power: string = "";
  public id_holding: string = "HLD001";
  public address: string = "";
  public dataProfile = null;
  private objFilter = {};

  public displayForm = false;
  public isSubmitRegis = false;

  public name;
  public gradesarray = [];
  public gradesarray1 = [];
  public company;
  public widthTable = 1000;
  public email;
  public identity_photo;
  public company_identity_photo;
  public personal_photo;
  public role;

  public imageID = null;
  public imageKTP = null;
  public imagePersonal = null;
  @ViewChild("picEmail1") dropdown1: Dropdown;
  @ViewChild("picEmail2") dropdown2: Dropdown;
  @ViewChild("picEmail3") dropdown3: Dropdown;
  @ViewChild("picEmail4") dropdown4: Dropdown;
  @ViewChild("picEmail5") dropdown5: Dropdown;

  @ViewChild("picEmail21") dropdown6: Dropdown;
  @ViewChild("picEmail22") dropdown7: Dropdown;
  @ViewChild("picEmail23") dropdown8: Dropdown;
  @ViewChild("picEmail24") dropdown9: Dropdown;
  @ViewChild("picEmail25") dropdown10: Dropdown;


  @ViewChild("picEmail31") dropdown11: Dropdown;
  @ViewChild("picEmail32") dropdown12: Dropdown;
  @ViewChild("picEmail33") dropdown13: Dropdown;
  @ViewChild("picEmail34") dropdown14: Dropdown;
  @ViewChild("picEmail35") dropdown15: Dropdown;


  @ViewChild("levelsampaia") dropdownGrade1: Dropdown;
  @ViewChild("leveldarib") dropdownGrade2: Dropdown;
  @ViewChild("levelsampaib") dropdownGrade3: Dropdown;
  @ViewChild("leveldaric") dropdownGrade4: Dropdown;
  @ViewChild("levelsampaic") dropdownGrade5: Dropdown;

  constructor(
    private loanService: LoanService,
    private messageService: MessageService,
    private masterService: MasterService,
    private memberService: MemberService
  ) {}

  ngOnInit() {
    this.fetchComapany();
    this.fetchCompanyFull();

    this.roleId = localStorage.getItem("id_role_master");
    this.widthDisplay = 1200;
    this.statusOpsi = [{ label: "Semua Status", value: null }];
    this.statusOpsi.push({ label: "Non Active", value: "MBRSTS04" });
    this.statusOpsi.push({ label: "Active", value: "MBRSTS05" });
    // account_name_bank_main: null
    // account_name_bank_secondary: null
    // account_number_bank_main: null
    // account_number_bank_secondary: null
    // address: "JL MAJAPAHIT NO 26 PETOJO SELATAN - GAMBIR 10160 - JAKARTA"
    // created_at: null
    // created_by: null
    // deleted_at: null
    // deleted_by: null
    // id_bank_main: null
    // id_bank_secondary: null
    // id_company: "COMP1626"
    // id_holding: "HLD001"
    // is_kopnit: null
    // loan_power: null
    // name_company: "ACSET INDONUSA, PT."
    // phone_number: "213511961"
    // status: "1"
    // updated_at: null
    // updated_by: null
    this.columns = [
      { field: "number", header: "No", show: true },
      { field: "name_company", header: "Perusahaan", show: true },
      { field: "is_kopnit_desc", header: "Kopnit", show: true },
      { field: "loan_power_rp", header: "Loan Power", show: true },
      { field: "status", header: "Status", show: true },
    ];
    this.availabelColumn = _.filter(this.columns, { show: true }).length + 1;
    this.selectedColumns = _.filter(this.columns, { show: true });


    this.gradesarray =[
      {label:'Grades 2', value:'2'},
      {label:'Grades 3', value:'3'},
      {label:'Grades 4', value:'4'},
      {label:'Grades 5', value:'5'},
      {label:'Grades 6', value:'6'},
      {label:'Grades 7', value:'7'},
      ];

      this.gradesarray1 =[
        {label:'Grades 1', value:'1'}
        ];
  }

  
  fetchCompanyFull() {
    this.loanService.getCompany().subscribe(
      (res) => {
        this.companies = [];
        this.companies.push({ label: "-Pilih", value: "" });
        _.map(res["data"], (x) => {
          this.companies.push({ label: x.name_company, value: x.id_company });
          this.companies2.push({ label: x.name_company, value: x.id_company });
        });
      },
      (err) => {
        console.log(err);
        // this.fetchLoan();
      }
    );
  }
  // Save
  // ========================= //
  save(e) {
    this.isSubmitRegis = true;
    e["identity_photo"] = this.imageID.imageSrc;
    e["company_identity_photo"] = this.imageKTP.imageSrc;
    e["personal_photo"] = this.imagePersonal.imageSrc;
    e["role"] = "ROLE001";

    this.memberService.postUser(e).subscribe(
      (res) => {
        this.isSubmitRegis = false;
        this.displayForm = false;
        this.fetchComapany();
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "User has been added",
        });
      },
      (err) => {
        console.log(err);

        this.isSubmitRegis = false;
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Please try again",
        });
      }
    );
  }



  // Toggle Columns
  // ====================== //
  changeColumn(e) {
    const find = _.find(this.columns, e.itemValue);
    if (e.itemValue != undefined) {
      if (e.itemValue.show) {
        find.show = false;
      } else {
        find.show = true;
      }
    }
    this.availabelColumn = _.filter(this.columns, { show: true }).length + 1;
    this.selectedColumns = _.filter(this.columns, { show: true });
  }

  // Convert Image
  // ========================= //
  private typeupload = null;
  handleInputChange(e, type) {
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    const pattern = /image-*/;
    const reader = new FileReader();
    if (file != undefined) {
      if (!file.type.match(pattern)) {
        alert("invalid format");
        return;
      }
    }

    this.typeupload = type;
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  _handleReaderLoaded(e, type) {
    const reader = e.target;
    switch (this.typeupload) {
      case "company":
        this.imgEmployee = reader.result;
        break;
      case "document":
        this.imgDocument = reader.result;
        break;
    }

    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 500);
  }
  pilihImg(val) {
    this.selectedImg = val;
    this.displayImg = true;
  }
  // Fetching User
  // ========================= //
  fetchComapany() {
    this.loading = true;
    this.masterService
      .getListCompany(this.start, this.pageLength, this.objFilter)
      .subscribe(
        (res) => {
          _.map(res["data"].data, (x, i) => {
            x["number"] = i + 1;
            x.loan_power_rp = x.loan_power
              ? "Rp " + x.loan_power.toLocaleString()
              : "Rp 0";
            x.is_kopnit_desc = x.is_kopnit
              ? x.is_kopnit == 1
                ? "yes"
                : "No"
              : "-";
          });
          this.data = res["data"].data;
          this.totalCount = Number(res["data"].count_filter);
          this.loading = false;
          this.widthTable = 1200;
        },
        (err) => {
          // this.fetchComapany();
          this.loading = false;
          if (err.status == 401) {
            this.memberService.updateToken(err.error.data.token);
          }
        }
      );
  }
  // filterProfile(event) {
  //   let query = event.query;
  //   let comp = this.selectedItem.id_company;
  //   this.masterService.searchProfile(query, comp).subscribe(
  //     (res) => {
  //       this.filteredProfile = res["data"];
  //     },
  //     (err) => {
  //       // this.fetchComapany();
  //       this.loading = false;
  //       if (err.status == 401) {
  //         this.memberService.updateToken(err.error.data.token);
  //       }
  //     }
  //   );
  // }
  paginate(e) {
    this.start = e.page * this.pageLength;
    this.fetchComapany();
  }
  approveItem(val) {
    this.approveDialoge = true;
    this.selectedItem = val;
  }
  rejectItem(val) {
    this.rejectDialoge = true;
    this.selectedItem = val;
  }
  tambahNew() {
    this.name_company = "";
    this.is_kopnit = 0;
    this.phone_number = "";
    this.pic_email = "";
    this.pic_email1 = "";
    this.pic_email2 = "";
    this.pic_email3 = "";
    this.pic_email4 = "";
    this.pic_email5 = "";

    this.pic_email21 = "";
    this.pic_email22 = "";
    this.pic_email23 = "";
    this.pic_email24 = "";
    this.pic_email25 = "";


    this.pic_email31 = "";
    this.pic_email32 = "";
    this.pic_email33 = "";
    this.pic_email34 = "";
    this.pic_email35 = "";

    this.loan_power = "";
    this.parent_company_id = null;
    this.address = "";
    this.isAddMulai = false;
    this.addModal = true;
  }
  prosesAdd() {
    this.isAddMulai = true;
    this.pic_email = "";
    if (!this.isChildCompany) {
      this.parent_company_id = null;
    }
    this.pic_email = null;
    const body = {
      name_company: this.name_company,
      id_holding: this.id_holding,
      is_kopnit: this.is_kopnit,
      loan_power: this.loan_power,
      pic_email: null,
      parent_company_id: this.parent_company_id,
      phone_number: this.phone_number,
      address: this.address,
      status: this.isAktif ? 1 : 0,
    };
    this.masterService.postCompany(body).subscribe(
      (res) => {
        if (res["status"] == 0) {
          this.isAddMulai = false;
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: res["message"],
          });
        } else {
          this.addModal = false;
          this.isAddMulai = false;
          this.fetchComapany();
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Company Registered Successfully",
          });
        }
      },
      (err) => {
        console.log(err);
        this.isAddMulai = false;
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: err.error.message,
        });
      }
    );
  }
  prosesEdit() {
    if (!this.isChildCompany) {
      this.parent_company_id = null;
    }
    this.isEditMulai = true;
    this.pic_email = "";
    this.level = "";
    let {
      pic_email1,
      pic_email2,
      pic_email3,
      pic_email4,
      pic_email5,

      pic_email21,
      pic_email22,
      pic_email23,
      pic_email24,
      pic_email25,

      pic_email31,
      pic_email32,
      pic_email33,
      pic_email34,
      pic_email35,

      level_dari_a,
      level_sampai_a,
      level_dari_b,
      level_sampai_b,
      level_dari_c,
      level_sampai_c,

    } = this;

    pic_email1 = pic_email1 == null || pic_email1 == "" ? "" : pic_email1;
    pic_email2 = pic_email2 == null || pic_email2 == "" ? "" : ";" + pic_email2;
    pic_email3 = pic_email3 == null || pic_email3 == "" ? "" : ";" + pic_email3;
    pic_email4 = pic_email4 == null || pic_email4 == "" ? "" : ";" + pic_email4;
    pic_email5 = pic_email5 == null || pic_email5 == "" ? "" : ";" + pic_email5;


    pic_email21 = pic_email21 == null || pic_email21 == "" ? "" : pic_email21;
    pic_email22 = pic_email22 == null || pic_email22 == "" ? "" : ";" + pic_email22;
    pic_email23 = pic_email23 == null || pic_email23 == "" ? "" : ";" + pic_email23;
    pic_email24 = pic_email24 == null || pic_email24 == "" ? "" : ";" + pic_email24;
    pic_email25 = pic_email25 == null || pic_email25 == "" ? "" : ";" + pic_email25;

    pic_email31 = pic_email31 == null || pic_email31 == "" ? "" : pic_email31;
    pic_email32 = pic_email32 == null || pic_email32== "" ? "" : ";" + pic_email32;
    pic_email33 = pic_email33 == null || pic_email33 == "" ? "" : ";" + pic_email33;
    pic_email34 = pic_email34 == null || pic_email34 == "" ? "" : ";" + pic_email34;
    pic_email35 = pic_email35 == null || pic_email35 == "" ? "" : ";" + pic_email35;

    level_dari_a = level_dari_a == null || level_dari_a == "" ? "" : level_dari_a;
    level_sampai_a = level_sampai_a == null || level_sampai_a== "" ? "" : ";" + level_sampai_a;
    level_dari_b = level_dari_b == null || level_dari_b == "" ? "" :  level_dari_b;
    level_sampai_b = level_sampai_b == null || level_sampai_b == "" ? "" : ";" + level_sampai_b;
    level_dari_c = level_dari_c == null || level_dari_c == "" ? "" :  level_dari_c;
    level_sampai_c = level_sampai_c == null || level_sampai_c == "" ? "" : ";" + level_sampai_c;

    this.pic_email = `${pic_email1}${pic_email2}${pic_email3}${pic_email4}${pic_email5}`;
    this.picemail2 = `${pic_email31}${pic_email32}${pic_email33}${pic_email34}${pic_email35}`;
    this.picemail3 = `${pic_email21}${pic_email22}${pic_email23}${pic_email24}${pic_email25}`;

    // console.log(this.picemail2);

    this.level = `${level_dari_a}${level_sampai_a}`;
    this.level2 = `${level_dari_b}${level_sampai_b}`;
    this.level3 = `${level_dari_c}${level_sampai_c}`;

    const body = {
      name_company: this.name_company,
      id_holding: this.id_holding,
      is_kopnit: this.is_kopnit ? this.is_kopnit : 0,
      parent_company_id: this.parent_company_id,
      pic_email: this.pic_email,
      picemail2: this.picemail2,
      picemail3: this.picemail3,
      level: this.level,
      level_dari_a: this.level_dari_a,
      level_sampai_a: this.level_sampai_a,
      level2: this.level2,
      level_dari_b: this.level_dari_b,
      level_sampai_b: this.level_sampai_b,
      level3: this.level3,
      level_dari_c: this.level_dari_c,
      level_sampai_c: this.level_sampai_c,
      loan_power: this.loan_power,
      id_company: this.selectedItem.id_company,
      phone_number: this.phone_number,
      address: this.address,
      status: this.isAktif ? 1 : 0,
    };
    this.masterService.putCompany(body).subscribe(
      (res) => {
        if (res["status"] == 0) {
          this.isEditMulai = false;
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: res["message"],
          });
        } else {
          // console.log(res);
          this.addModal = false;
          this.isEditMulai = false;
          this.viewDetail = false;
          this.fetchComapany();
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Company Registered Successfully",
          });
        }
      },
      (err) => {
        console.log(err);
        this.isEditMulai = false;
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: err.error.message,
        });
      }
    );
  }
  prosesActive() {
    this.isSubmitApprove = true;
    const body = {
      id: this.selectedItem["id_user"],
    };
    this.memberService.activeUserBe(body).subscribe(
      (res) => {
        this.viewDetail = false;
        this.isSubmitApprove = false;
        this.fetchComapany();
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "User Activated",
        });
      },
      (err) => {
        this.isSubmitApprove = false;
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Please try again",
        });
      }
    );
  }
  prosesDeactive() {
    this.isSubmitReject = true;
    const body = {
      id: this.selectedItem["id_user"],
    };
    this.memberService.deactiveUserBe(body).subscribe(
      (res) => {
        this.viewDetail = false;
        this.isSubmitReject = false;
        this.fetchComapany();
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "User Deactivated",
        });
      },
      (err) => {
        this.isSubmitApprove = false;
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Please try again",
        });
      }
    );
  }
  // Select Item / User
  // ======================== //
  selectItem(e) {
    this.viewDetail = true;
    let tmp = JSON.parse(JSON.stringify(e));
    console.log(tmp);

    this.parent_company_id = tmp.parent_company_id;
    this.name_company = tmp.name_company;
    this.is_kopnit = tmp.is_kopnit;
    this.phone_number = tmp.phone_number;
    this.loan_power = tmp.loan_power;
    this.address = tmp.address;
    this.isAktif = tmp.status;
    this.grades3 = tmp.grades;
    this.level_dari_a = tmp.level_dari_a;
    this.level_dari_b = tmp.level_dari_b;
    this.level_dari_c = tmp.level_dari_c;
    this.level_sampai_a = tmp.level_sampai_a;
    this.level_sampai_b = tmp.level_sampai_b;
    this.level_sampai_c = tmp.level_sampai_c;
    this.selectedItem = JSON.parse(JSON.stringify(e));

    if (
      this.selectedItem.parent_company_id == null ||
      this.selectedItem.parent_company_id == ""
    ) {
      this.isChildCompany = false;
    } else {
      this.isChildCompany = true;
    }

    this.fetchEmailCompany();
    


    this.pic_email1 = "";
    this.pic_email2 = "";
    this.pic_email3 = "";
    this.pic_email4 = "";
    this.pic_email5 = "";

    this.pic_email21 = "";
    this.pic_email22 = "";
    this.pic_email23 = "";
    this.pic_email24 = "";
    this.pic_email25 = "";


    this.pic_email31 = "";
    this.pic_email32 = "";
    this.pic_email33 = "";
    this.pic_email34 = "";
    this.pic_email35 = "";

    
    this.pic_email = tmp.pic_email;
    this.picemail2 = tmp.picemail2;
    this.picemail3 = tmp.picemail3;

    console.log(this.picemail3);


    if (tmp.pic_email) {
      let pic = tmp.pic_email.split(";");
      if (pic[0]) {
        this.pic_email1 = pic[0];
      }
      if (pic[1]) {
        this.pic_email2 = pic[1];
      }
      if (pic[2]) {
        this.pic_email3 = pic[2];
      }
      if (pic[3]) {
        this.pic_email4 = pic[3];
      }
      if (pic[4]) {
        this.pic_email5 = pic[4];
      }
    }

    if (tmp.picemail2) {
      let pic2 = tmp.picemail2.split(";");
      if (pic2[0]) {
        this.pic_email31 = pic2[0];
      }
      if (pic2[1]) {
        this.pic_email32 = pic2[1];
      }
      if (pic2[2]) {
        this.pic_email33 = pic2[2];
      }
      if (pic2[3]) {
        this.pic_email34 = pic2[3];
      }
      if (pic2[4]) {
        this.pic_email35 = pic2[4];
      }
    }

    if (tmp.picemail3) {
      let pic3 = tmp.picemail3.split(";");
 
      if (pic3[0]) {
        this.pic_email21 = pic3[0];
        console.log(this.pic_email21);
      }
      if (pic3[1]) {
        this.pic_email22 = pic3[1];
      }
      if (pic3[2]) {
        this.pic_email23 = pic3[2];
      }
      if (pic3[3]) {
        this.pic_email24 = pic3[3];
      }
      if (pic3[4]) {
        this.pic_email25 = pic3[4];
      }
    }
   
  }

  tabChange() {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 100);
  }

  arrEmailCompanies: any[] = [];
  

  fetchEmailCompany() {
    let companyId = this.selectedItem.id_company;
    this.masterService.getEmailCompany(companyId).subscribe((res) => {

      this.arrEmailCompanies = [];
      _.map(res["data"], (x) => {
        this.arrEmailCompanies.push({ label: x.email, value: x.email });
      });
    });

;
  }

  arrEmailCompanies2: any[] = [];
  fetchEmailCompany2() {
    let companyId = this.selectedItem.id_company;
    this.masterService.getEmailCompany2(companyId).subscribe((res) => {
      this.arrEmailCompanies = [];
      _.map(res["data"], (x) => {
        this.arrEmailCompanies2.push({ label: x.email, value: x.email });
      });
    });
  }


  //check duplicate

  tmpValueEmail: any[] = [];
  checkIsDuplicate(e: any, isCounter) {
    const {
      pic_email1,
      pic_email2,
      pic_email3,
      pic_email4,
      pic_email5,
      level_dari_a,
      level_sampai_a,
      level_dari_b,
      level_sampai_b,
      level_dari_c,
      level_sampai_c,
      
    } = this;
    let data = [
      pic_email1,
      pic_email2,
      pic_email3,
      pic_email4,
      pic_email5,
      level_dari_a,
      level_sampai_a,
      level_dari_b,
      level_sampai_b,
      level_dari_c,
      level_sampai_c,

    ];
    var filtered = data.filter(function (el) {
      return el != null;
    });
    // if (!level_dari_a || !level_dari_b) {
    //   this.pic_email1 = "";
    //   // this.dropdown1.clear(null);
    //   this.messageService.add({
    //     severity: "warn",
    //     summary: "Perhatian!",
    //     detail: "Isi Grade Terlebih Dahulu!",
    //   });
    // }

    let arrTmpValid = [];
    let chk = filtered.filter((item) => {
      return item.indexOf(e.value) > -1;
    });
    let check = this.findDuplicates(chk);

    if (isCounter == 1 && check) {
   
      this.pic_email1 = "";
      this.dropdown1.clear(null);
    } else {
      arrTmpValid.push(pic_email1);
    }
    if (isCounter == 2 && check) {
      this.pic_email2 = "";
      this.dropdown2.clear(null);
    } else {
      arrTmpValid.push(pic_email2);
    }
    if (isCounter == 3 && check) {
      this.pic_email3 = "";
      this.dropdown3.clear(null);
    } else {
      arrTmpValid.push(pic_email3);
    }
    if (isCounter == 4 && check) {
      this.pic_email4 = "";
      this.dropdown4.clear(null);
    } else {
      arrTmpValid.push(pic_email4);
    }
    if (isCounter == 5 && check) {
      this.pic_email5 = "";
      this.dropdown5.clear(null);
    } else {
      arrTmpValid.push(pic_email5);
    }


    if (check) {
      this.messageService.add({
        severity: "warn",
        summary: "Duplicated",
        detail: "Email sudah dipilih!",
      });
    }

  
  }

  findDuplicates(arr) {
    return arr.some((x) => arr.indexOf(x) !== arr.lastIndexOf(x));
  }




  
  //check duplicate

  tmpValueEmailLower: any[] = [];
  checkIsDuplicateAndLower(e: any, isCounter) {
    const {
      level_dari_a,
      level_sampai_a,
      level_dari_b,
      level_sampai_b,
      level_dari_c,
      level_sampai_c,
      
    } = this;
    let data = [
      level_dari_a,
      level_sampai_a,
      level_dari_b,
      level_sampai_b,
      level_dari_c,
      level_sampai_c,

    ];
    var filtered = data.filter(function (el) {
      return el != null;
    });

    let arrTmpValid = [];
    let chk = filtered.filter((item) => {
      return item.indexOf(e.value) > -1;
    });
    let check = this.findDuplicatesLower(chk);
    // let checkLower = this.findDuplicatesCheckLower(chk);

    if (isCounter == 1 && check ) {
      this.level_sampai_a = "";
      this.dropdownGrade1.clear(null);
    } else {
      arrTmpValid.push(level_sampai_a);
    }
    if (isCounter == 2 && check  ) {
      this.level_dari_b = "";
      this.dropdownGrade2.clear(null);
    } else {
      arrTmpValid.push(level_dari_b);
    }
    if (isCounter == 3 && check  ) {
      this.level_sampai_b = "";
      this.dropdownGrade3.clear(null);
    } else {
      arrTmpValid.push(level_sampai_b);
    }
    if (isCounter == 4 && check ) {
      this.level_dari_c = "";
      this.dropdownGrade4.clear(null);
    } else {
      arrTmpValid.push(level_dari_c);
    }
    if (isCounter == 5 && check ) {
      this.level_sampai_c = "";
      this.dropdownGrade5.clear(null);
    } else {
      arrTmpValid.push(level_sampai_c);
    }


    if (check) {
      this.messageService.add({
        severity: "warn",
        summary: "Duplicated ",
        detail: "Grade sudah dipilih !",
      });
    }

    // if (checkLower) {
    //   this.messageService.add({
    //     severity: "warn",
    //     summary: "not Lower",
    //     detail: "Grade Tidak Boleh Kurang dari Grade Sebelumnya!",
    //   });
    // }

  
  }

  findDuplicatesLower(arr) {
    return arr.some((x) => arr.indexOf(x) !== arr.lastIndexOf(x));
  }

  // findDuplicatesCheckLower(arr) {
  //   return arr.some((x) => arr.indexOf(x) > arr.lastIndexOf(x));
  // }



  
  //check duplicate

  tmpValueEmail2: any[] = [];
  checkIsDuplicate2(e: any, isCounter) {
    const {
      pic_email21,
      pic_email22,
      pic_email23,
      pic_email24,
      pic_email25,

      
    } = this;
    let data2 = [
      pic_email21,
      pic_email22,
      pic_email23,
      pic_email24,
      pic_email25,

    ];
    var filtered2 = data2.filter(function (el) {
      return el != null;
    });

    let arrTmpValid2 = [];
    let chk2 = filtered2.filter((item) => {
      return item.indexOf(e.value) > -1;
    });
    let check2 = this.findDuplicates2(chk2);

    if (isCounter == 6 && check2) {
      this.pic_email21 = "";
      this.dropdown6.clear(null);
    } else {
      arrTmpValid2.push(pic_email21);
    }
    if (isCounter == 7 && check2) {
      this.pic_email22 = "";
      this.dropdown7.clear(null);
    } else {
      arrTmpValid2.push(pic_email22);
    }
    if (isCounter == 8 && check2) {
      this.pic_email23 = "";
      this.dropdown8.clear(null);
    } else {
      arrTmpValid2.push(pic_email23);
    }
    if (isCounter == 9 && check2) {
      this.pic_email24 = "";
      this.dropdown9.clear(null);
    } else {
      arrTmpValid2.push(pic_email24);
    }
    if (isCounter == 10 && check2) {
      this.pic_email25 = "";
      this.dropdown10.clear(null);
    } else {
      arrTmpValid2.push(pic_email25);
    }


    if (check2) {
      this.messageService.add({
        severity: "warn",
        summary: "Duplicated",
        detail: "Email sudah dipilih!",
      });
    }
  }

  findDuplicates2(arr) {
    return arr.some((x) => arr.indexOf(x) !== arr.lastIndexOf(x));
  }



  
  
  //check duplicate

  tmpValueEmail3: any[] = [];
  checkIsDuplicate3(e: any, isCounter) {
    const {
      pic_email31,
      pic_email32,
      pic_email33,
      pic_email34,
      pic_email35,

      
    } = this;
    let data3 = [
      pic_email31,
      pic_email32,
      pic_email33,
      pic_email34,
      pic_email35,

    ];
    var filtered3 = data3.filter(function (el) {
      return el != null;
    });

    let arrTmpValid3 = [];
    let chk3 = filtered3.filter((item) => {
      return item.indexOf(e.value) > -1;
    });
    let check3 = this.findDuplicates2(chk3);

    if (isCounter == 11 && check3) {
      this.pic_email31 = "";
      this.dropdown11.clear(null);
    } else {
      arrTmpValid3.push(pic_email31);
    }
    if (isCounter == 12 && check3) {
      this.pic_email32 = "";
      this.dropdown12.clear(null);
    } else {
      arrTmpValid3.push(pic_email32);
    }
    if (isCounter == 13 && check3) {
      this.pic_email33 = "";
      this.dropdown13.clear(null);
    } else {
      arrTmpValid3.push(pic_email33);
    }
    if (isCounter == 14 && check3) {
      this.pic_email34 = "";
      this.dropdown14.clear(null);
    } else {
      arrTmpValid3.push(pic_email34);
    }
    if (isCounter == 15 && check3) {
      this.pic_email35 = "";
      this.dropdown15.clear(null);
    } else {
      arrTmpValid3.push(pic_email35);
    }


    if (check3) {
      this.messageService.add({
        severity: "warn",
        summary: "Duplicated",
        detail: "Email sudah dipilih!",
      });
    }
  }

  findDuplicates3(arr) {
    return arr.some((x) => arr.indexOf(x) !== arr.lastIndexOf(x));
  }


  // Create Mutation Employee
  // ======================== //
  public isSubmitMutation = false;
  public idEmployee = null;
  public division = null;
  public position = null;
  public selectedCompany2 = null;
  public imgEmployee = null;
  createMutation() {
    this.isSubmitMutation = true;
    const obj = {
      id: this.selectedItem.id_user,
      id_employee: this.idEmployee,
      id_company: this.selectedCompany2,
      company_identity_photo: this.imgEmployee,
      division: this.division,
      position: this.position,
    };
    this.memberService.updateMutation(obj).subscribe(
      (res) => {
        this.isSubmitMutation = false;
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Mutasi karyawan berhasil",
        });
        this.display = false;
        this.fetchComapany();
      },
      (err) => {
        this.isSubmitMutation = false;
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Mutasi karyawan gagal, silakan coba lagi",
        });
      }
    );
  }

  // Select Company Mutation Employee
  // ============================== //
  changeCompany2(e) {
    this.selectedCompany2 = e.value;
  }

  // Fetch Salary
  // ============================ //
  fetchSallary(id) {
    this.memberService.getSallary(id).subscribe(
      (res) => {
        this.dataProfile["salary"] = res["data"];
        this.dataProfile["salary"].amount = Number(
          res["data"].salary_amount
        ).toLocaleString();
        this.fetchBank(id);
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }

  // Fetch Bank
  // ============================ //
  fetchBank(id) {
    this.memberService.getBank(id).subscribe(
      (res) => {
        this.dataProfile["bank"] = res["data"];
        this.fetchDocument(id);
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }

  // Fetch Document
  // ============================ //
  public isViewDocument = false;
  public openTabDocument = false;
  public selectedDocument = null;
  public imgDocument = null;
  public isSubmitDocument = false;
  public arrDocumentType = [];
  public selectedDocumentType = null;
  public isUpdateDocument = false;

  fetchDocument(id) {
    this.memberService.getDocument(id).subscribe(
      (res) => {
        res["data"].map((x) => {
          x["disable"] = false;
          x["document_name"] = _.find(this.arrDocumentType, {
            value: x.id_document_type,
          }).label;
        });
        this.dataProfile["document"] = res["data"];
        this.display = true;
        this.loading = false;
        this.openTabDocument = false;
        setTimeout(() => {
          window.dispatchEvent(new Event("resize"));
        }, 100);
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }
  removeDocument(e) {
    this.selectedDocument = e;
    this.selectedDocument.disable = true;
    this.memberService
      .deleteDocument(
        Number(this.selectedDocument.id_user),
        this.selectedDocument.id_user_document
      )
      .subscribe(
        (res) => {
          this.fetchDocument(Number(this.selectedDocument.id_user));
          this.selectedDocument = null;
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Berhasil hapush dokumen",
          });
        },
        (err) => {
          this.selectedDocument.disable = false;
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: "Gagal hapush dokumen",
          });
        }
      );
  }
  viewDocument(e) {
    this.widthDisplay = 800;
    this.selectedDocument = e;
    this.isViewDocument = true;
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 100);
  }
  closeViewDocument() {
    this.widthDisplay = 1200;
    this.isViewDocument = false;
    this.openTabDocument = true;
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 100);
  }
  changeDocumentType(e) {
    this.selectedDocumentType = e.value;
  }
  createDocument() {
    const obj = {
      id: this.selectedItem.id_user,
      id_document_type: this.selectedDocumentType,
      doc_photo: this.imgDocument,
    };
    this.isSubmitDocument = true;

    if (this.isUpdateDocument) {
      this.memberService.updateDocument(obj).subscribe(
        (res) => {
          this.isSubmitDocument = false;
          this.fetchDocument(this.selectedItem.id_user);
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Berhasil tambah dokumen",
          });
          this.cancelUpdateDoc();
        },
        (err) => {
          this.isSubmitDocument = false;
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: "Gagal tambah dokumen",
          });
        }
      );
    } else {
      this.memberService.postDocument(obj).subscribe(
        (res) => {
          this.isSubmitDocument = false;
          this.fetchDocument(this.selectedItem.id_user);
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Berhasil tambah dokumen",
          });
        },
        (err) => {
          this.isSubmitDocument = false;
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: "Gagal tambah dokumen",
          });
        }
      );
    }
  }
  editDocument(e) {
    this.isUpdateDocument = true;
    this.selectedDocumentType = e.id_document_type;
    this.imgDocument = e.path;
  }
  cancelUpdateDoc() {
    this.isUpdateDocument = false;
    this.imgDocument = null;
  }

  // Fetch Master
  // ========================= //
  fetchStatus() {
    this.memberService.getStatus().subscribe(
      (res) => {
        this.arrStatus = [{ label: "Semua Status", value: null }];
        _.map(res["data"], (x) => {
          const obj = {
            label: x.workflow_status_name,
            value: x.id_workflow_status,
          };
          this.arrStatus.push(obj);
        });

        _.map(this.data, (x) => {
          x["status_name"] = _.find(this.arrStatus, {
            value: x.id_workflow_status,
          }).label;
        });
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }
  fetchGrade() {
    this.memberService.getGrade().subscribe(
      (res) => {
        this.originGrades = res["data"];
        this.grades = [{ label: "Semua Golongan", value: null }];
        this.grades2 = [];
        _.map(res["data"], (x) => {
          const obj = { label: x.name_grade, value: x.id_grade };
          this.grades.push(obj);
          this.grades2.push(obj);
        });
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }
  fetchDomicile() {
    this.memberService.getDomicile().subscribe(
      (res) => {
        this.arrDomicile = res["data"];
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }
  fetchReligion() {
    this.memberService.getReligion().subscribe(
      (res) => {
        this.arrReligion = res["data"];
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }
  fetchMarriage() {
    this.memberService.getMarriage().subscribe(
      (res) => {
        this.arrMariege = res["data"];
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }
  fetchRole() {
    this.memberService.getRole().subscribe(
      (res) => {
        this.arrRole = res["data"];
        this.roles = [];
        _.map(res["data"], (x) => {
          this.roles.push({
            label: x.name_role_master,
            value: x.id_role_master,
          });
        });
        this.role = this.roles[0].value;

      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }
  fetchMasterDocumentType() {
    this.memberService.getMstDocument().subscribe(
      (res) => {
        _.map(res["data"], (x) => {
          const obj = { label: x.document_name, value: x.id_document_type };
          this.arrDocumentType.push(obj);
        });
        this.selectedDocumentType = this.arrDocumentType[0].value;
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }

  // Filter List
  // ========================== //
  private typingTimer;
  private doneTypingInterval = 1000;
  onSearchName(searchValue: string) {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      if (searchValue) {
        this.objFilter["nama"] = searchValue;
      } else {
        delete this.objFilter["nama"];
      }
      this.start = 0;
      this.fetchComapany();
    }, this.doneTypingInterval);
  }
  onSearchNik(searchValue: string) {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      if (searchValue) {
        this.objFilter["no_anggota"] = searchValue;
      } else {
        delete this.objFilter["no_anggota"];
      }
      this.start = 0;
      this.fetchComapany();
    }, this.doneTypingInterval);
  }
  changeCompany(e) {
    if (e.value) {
      this.objFilter["company"] = e.value;
    } else {
      delete this.objFilter["company"];
    }

    this.start = 0;
    this.fetchComapany();
  }
  changeStatus(e) {
    if (e.value) {
      this.objFilter["status"] = e.value;
    } else {
      delete this.objFilter["status"];
    }

    this.start = 0;
    this.fetchComapany();
  }
  changeCompanyAdd(e) {
    this.company = e.value;
  }
  changeRoleAdd(e) {
    this.role = e.value;
  }
  changeGrade(e) {
    if (e.value) {
      this.objFilter["golongan"] = e.value;
    } else {
      delete this.objFilter["golongan"];
    }
    this.start = 0;
    this.fetchComapany();
  }
  // changeStatus(e) {
  //   if (e.value) {
  //     this.objFilter['status'] = e.value;
  //   } else {
  //     delete this.objFilter['status'];
  //   }
  //   this.start = 0;
  //   this.fetchComapany();
  // }
  onSelectTglMasuk(e) {
    if (this.date1[1]) {
      const date1 = moment(this.date1[0]).format("YYYY-MM-DD");
      const date2 = moment(this.date1[1]).format("YYYY-MM-DD");
      this.objFilter["tgl_masuk"] = "2019-02-01 - 2019-02-15";
    } else {
      delete this.objFilter["tgl_masuk"];
    }
    this.start = 0;
    this.fetchComapany();
  }
  onSelectTglPengajuan(e) {
    if (this.date2[1]) {
      const date1 = moment(this.date2[0]).format("YYYY-MM-DD");
      const date2 = moment(this.date2[1]).format("YYYY-MM-DD");
      this.objFilter["tgl_pengajuan"] = "2019-02-01 - 2019-02-15";
    } else {
      delete this.objFilter["tgl_pengajuan"];
    }
    this.start = 0;
    this.fetchComapany();
  }
}
