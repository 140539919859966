import { Component, OnInit } from '@angular/core';
import { SignService } from "../sign.service";
import { Message,MessageService } from "primeng/components/common/api";
import { ActivatedRoute } from '@angular/router';
interface selectedItem {
    email: string;
 
  }
  
@Component({
  selector: 'app-change-pass',
  templateUrl: './change-pass.component.html',
  styleUrls: ['./change-pass.component.scss'],
  providers: [MessageService],
})

export class ChangepassComponent implements OnInit {
	// Input Dropdown
	// ====================== //
    public edited = false;
    selectedItem: any = {};
    public msgs: Message[] = [];
    loading: boolean = false;
    loadingSave: boolean = false;
    displayForm: boolean = false;
    isEdit: boolean = false;
    constructor(
        private signService: SignService,
        private messageService: MessageService,
        private route: ActivatedRoute
      ) {}
	ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    const email = this.route.snapshot.paramMap.get('email');
    const param = {
      id: id,
      email: email
    };
      this.signService.checkToken(param).subscribe((res) => {
        console.log(res);
        this.edited = true;
        },
        (err) => {
          alert(err.error.message);
          this.edited = false;
        });
	}


    showThis(val: selectedItem) {
      
      }
    
  async save(e: any) {
    // console.log(e);
    const param = {
      token             : this.route.snapshot.paramMap.get('id'),
      email             : this.route.snapshot.paramMap.get('email'),
      password          : e.password,
      confirmpassword   : e.confirmpassword
    };

  if(e.password == e.confirmpassword){
          this.signService.updatePassword(param).subscribe((res) => {
            console.log(res);
            alert('Berhasil ganti Password!');
            this.resetForm();
              window.location.href=location.origin+"/sign/signin";
          },
          (err) => {
            alert(err.error.message);
          });
    }else{
      alert('Password tidak sama silahkan cek kembali!');
    
    }
}

  resetForm() {
    this.ngOnInit();
    location.reload();

  }


    


    

}
