import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router, NavigationExtras } from "@angular/router";
import { LoanService } from "../../loan/loan.service";
import { MasterService } from "../master.service";
import { MessageService } from "primeng/components/common/messageservice";
import * as _ from "lodash";
import * as moment from "moment";
declare var $: any;
import { HttpClient } from "@angular/common/http";
import { Paginator } from "primeng/primeng";

@Component({
  selector: "app-microloan",
  templateUrl: "./orderpoint.component.html",
  styleUrls: ["./orderpoint.component.scss"],
  providers: [MessageService],
})
export class OrderPointComponent implements OnInit {
  private objFilter: any;
  public data: any = [];
  public columns: any = [];
  public selectedColumns: any[];
  public loading: boolean;
  public loadingBtn = false;
  public resetData = true;
  public selectedItem = null;
  public selectedCompany: String;
  public grades = [];
  public grades2 = [];
  public statusList = [];
  public companies = [];
  public companies2 = [];
  public start = 0;
  public display = false;
  public displayUpload = false;
  public displayConfirmation = false;
  public editMode = false;
  public loadingPaginate = false;
  public headerModal = "Point";
  public pageLength = 10;
  public microloan_planfond = 0;
  public totalCount = 0;
  public id_master_microloan_plafond;
  public originGrades = [];
  public selectedGrade = null;
  public urlDownload = null;
  public date: Date = null;
  public date1: Date = null;
  public date2: Date = null;
  public minDate = moment().add("days", -1)["_d"];
  public isSubmitApprove = false;
  public isSubmitReject = false;
  public widthTable = 0;
  public name = "";
  public loan_number = "";
  public id_company = "";
  public id_company_edit = "";
  public start_date = "";
  public end_date = "";
  public id_grade = "";
  public is_holding = "HLD001";
  public id_grade_edit = "";
  public id_workflow_status = "";
  public id_loan_type = "";
  public nik = "";
  public balance = "";
  public user_id = "";
  public amount = 0;
  public point_desc = [];
  public point_desc2 = [];
  public selectedDesc: String;
  public loanTypes = [
    { label: "Semua tipe Loan", value: null },
    { label: "Microloan", value: 1 },
    { label: "Middle Loan", value: 2 },
  ];
  public selectedLoan = null;

  public arrStatus = [
    { label: "All", value: null },
    { label: "Menunggu pembayaran", value: "a" },
    { label: "Menunggu Approval HR", value: "b" },
    { label: "Menunggu Approval Keanggotaan", value: "c" },
  ];
  public selectedStatus = null;
  status: "initial" | "uploading" | "success" | "fail" = "initial";
  files: File[] = [];

  @ViewChild('hiddenLink') hiddenLink: ElementRef<HTMLAnchorElement>;

  constructor(
    private loanService: LoanService,
    private masterService: MasterService,
    private messageService: MessageService,
    private router: Router,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.fetchCompany();
    this.fetchPointDeskripsi();
    this.fetchPoint();
    this.columns = [
      { field: "name", header: "Nama", show: true },
      { field: "name_company", header: "Affco", show: true },
      { field: "status", header: "Status", show: true },
      { field: "billing_date", header: "Tgl Transaksi", show: true },
      { field: "jumlah_awal", header: "Poin Awal", show: true },
      { field: "jumlah_terpakai", header: "Poin Digunakan", show: true },
      { field: "outstanding_balance", header: "Sisa Poin", show: true },
      { field: "biller_name", header: "Produk", show: true },
      {
        field: "sell_price",
        header: "Nominal",
        show: true,
      },
      {
        field: "balance",
        header: "Balance DOKU",
        show: true,
      },
    ];
    this.selectedColumns = _.filter(this.columns, { show: true });

    // const widthTB = $('#tab-card').width();
    // this.widthTable = widthTB;
    // $(window).on('resize', function() {
    //   const widthTB = $('#tab-card').width();
    //   this.widthTable = widthTB;
    // });
  }

  @ViewChild("p") pagination: Paginator;

  onChange(event: any) {
    const files = event.target.files;

    if (files.length) {
      this.status = "initial";
      this.files = files;
    }
  }

  formatCurrency(value) {
    if(value && value != '') {
      value = parseInt(value || 0);
      return (parseInt(value || 0)).toLocaleString();
    }
    return '';
  }

  public loadingSubmitApprove: boolean = false;
  onUpload() {
    this.loadingSubmitApprove = true;
    if (this.files.length) {
      const formData = new FormData();
      const file = this.files[0]; // Menggunakan akses indeks standar untuk FileList
      if(file !== undefined){
        formData.append("file", file, file.name);
      }
    
      const upload$ = this.http.post("https://u.koperasi-astra.com/file-import", formData);
    
      this.status = "uploading";
    
      upload$.subscribe({
        next: () => {
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Data Successfully submitted",
          });
          this.loadingSubmitApprove = false;
          this.status = "success";
          window.location.reload();
        },
        error: (error: any) => {
          this.loadingSubmitApprove = false;
          this.status = "fail";
        },
      });
    }
    
  }

  openDialogForm() {
    this.display = true;
    this.editMode = false;
    this.id_company_edit = "";
    this.id_grade_edit = "";
    this.microloan_planfond = 0;
    this.headerModal = "Tambahkan Point Baru";
  }
  // Toggle Columns
  // ====================== //
  changeColumn(e) {
    const find = _.find(this.columns, e.itemValue);
    if (e.itemValue != undefined) {
      if (e.itemValue.show) {
        find.show = false;
      } else {
        find.show = true;
      }
    } else {
    }

    this.selectedColumns = _.filter(this.columns, { show: true });
  }
  compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const bandA = a.request_date.toUpperCase();
    const bandB = b.request_date.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison * -1;
  }
  // Fetching Loan
  // ========================= //
  // id_master_microloan_plafond (array int)
  // id_company (string)
  // name_company (string)
  // id_grade (string)
  // name_grade (string)
  // plafond_min (int)
  // plafond_max (int)
  // sortby (string)
  // page (int)
  // row (int)
  // order (ASC, DESC)
  fetchLoan(page) {
    this.loading = true;
    const { id_company, id_grade } = this;
    const param = { page, id_company, id_grade, row: 10 };
    this.masterService.getMicroloan(param).subscribe(
      (res) => {
        if (res["status"] == 0) {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: res["message"],
          });
          this.loading = false;
          this.loadingPaginate = false;
          return;
        }

        this.data = res["data"]["data"];

        // const tmp = this.data.sort(this.compare);
        const tmp = this.data;
        console.log({ tmp });
        _.map(tmp, (x, i) => {
          x.number = i;
          x.microloan_planfond_rp =
            "Rp " + x.microloan_planfond.toLocaleString();
        });
        // this.totalCount = res['data'].count_all;
        if (parseInt(res["data"].count_all) == 0) {
          this.totalCount = 0;
        } else {
          this.totalCount = Number(res["data"].count_all);
          if (this.totalCount > 100) {
            this.totalCount -= 10;
          }
        }
        // if(tmp.length>0){
        //   if(tmp.length<10){
        //
        //     this.totalCount = Number( tmp.length);
        //   }else{
        //
        //     this.totalCount = Number( tmp[0].total);
        //   }
        // }else{
        //   this.totalCount = 0;
        // }
        // store.dispatch({ type: 'COUNTER', jmlCalongAnggota: Number(res['data'].count_all) });
        this.loading = false;
        this.loadingPaginate = false;
      },
      (err) => {
        this.loadingPaginate = false;
        this.loading = false;
      }
    );
  }

  fetchPointDeskripsi() {
    this.loading = true;
    
    this.masterService.getPointDeskripsi(this.start, this.pageLength, this.objFilter).subscribe(
      (res) => {
        if (res["status"] == 0) {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: res["message"],
          });
          this.loading = false;
          this.loadingPaginate = false;
          return;
        }

        this.point_desc2 = [{ label: "Semua Alasan", value: null }];
        this.point_desc = [];
        _.map(res["data"], (x) => {
          let obj = {
            label: x.nama_deskripsi,
            value: x.nama_deskripsi
          };
          this.point_desc2.push(obj);
          this.point_desc.push(obj);
        });

        this.loading = false;
        this.loadingPaginate = false;
      },
      (err) => {
        this.loadingPaginate = false;
        this.loading = false;
      }
    );
  }

  fetchPoint() {
    this.loading = true;
    // const { name } = this;
    // const params = { name: names, page: page, row: this.pageLength };
    
    // this.masterService.getOrderPoint(this.start, this.pageLength, this.objFilter).subscribe(
    //   (res) => {
    //     if (res["status"] == 0) {
    //       this.messageService.add({
    //         severity: "error",
    //         summary: "Error",
    //         detail: res["message"],
    //       });
    //       this.loading = false;
    //       this.loadingPaginate = false;
    //       return;
    //     }

    //     this.data = res["data"];
    //     this.totalCount = res["pagination"].total;
    //     console.log('this.totalCount',this.totalCount);

    //     // const tmp = this.data.sort(this.compare);
    //     const tmp = this.data;
    //     console.log({ tmp });
    //     _.map(tmp, (x, i) => {
    //       x.number = i;
    //       x.balance =
    //       this.formatCurrency(x.balance) ;
    //     });
    //     // this.totalCount = res['data'].count_all;
    //     // if (parseInt(res["data"].count_all) == 0) {
    //     //   this.totalCount = 0;
    //     // } else {
    //     //   this.totalCount = Number(res["data"].count_all);
    //     //   if (this.totalCount > 100) {
    //     //     this.totalCount -= 10;
    //     //   }
    //     // }
    //     this.loading = false;
    //     this.loadingPaginate = false;
    //   },
    //   (err) => {
    //     this.loadingPaginate = false;
    //     this.loading = false;
    //   }
    // );
  }

  // Select Item / User
  // ======================== //

  selectItem(e) {
    console.log(e);
    this.editMode = true;
    this.display = true;
    this.selectedItem = e;
    this.name = e.nama;
    this.balance = e.balance;
    this.user_id = e.user_id;
  }

  downloadData() {
    this.displayUpload = true;
  }

  prosesReset() {
    this.resetData = false;
    setTimeout(() => {
      this.resetData = true;
    }, 600);
  }

  submitConfirmation() {
    this.displayConfirmation = true;
  }

  submit() {
    this.loadingBtn = true;
    const { user_id, amount, selectedDesc } = this;
    const datanya = {
      amount: amount,
      user_id: user_id,
      desc: selectedDesc,
      type: 'topup'
    };
    this.masterService.postPoint(datanya).subscribe(
      (res) => {
        // res
        if (res["status"] == 1) {
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Data Successfully submitted",
          });
          this.fetchPoint();
          this.display = false;
          this.displayConfirmation = false;
          this.loadingBtn = false;
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: "Please try again",
          });
          this.loadingBtn = false;
        }
      },
      (err) => {
        this.loadingBtn = false;
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Please try again",
        });
      }
    );
  }

  delete() {
    this.loadingBtn = true;
    const id_master_microloan_plafond = this.id_master_microloan_plafond;

    this.masterService.deleteMicroloan(id_master_microloan_plafond).subscribe(
      async (res) => {
        // res
        if (res["status"] == 1) {
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Data Successfully deleted",
          });
          this.fetchLoan(1);
          this.display = false;
          this.loadingBtn = false;
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: "Please try again",
          });
          this.loadingBtn = false;
        }
      },
      (err) => {
        this.loadingBtn = false;
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Please try again",
        });
      }
    );
  }
  // Fetch Grade
  // ========================= //
  fetchGrade() {
    this.loanService.getGrade().subscribe(
      (res) => {
        this.originGrades = res["data"];
        this.grades = [{ label: "Semua Golongan", value: null }];
        _.map(res["data"], (x) => {
          const obj = { label: x.name_grade, value: x.id_grade };
          this.grades.push(obj);
          this.grades2.push(obj);
        });
      },
      (err) => {
        // this.fetchGrade();
        console.log(err);
      }
    );
  }

  // Fetch Company
  // ========================= //
  fetchCompany() {
    this.loanService.getCompany().subscribe(
      (res) => {
        this.companies = [{ label: "Semua Perusahaan", value: null }];
        _.map(res["data"], (x) => {
          this.companies.push({ label: x.name_company, value: x.name_company });
          this.companies2.push({ label: x.name_company, value: x.name_company });
        });
      },
      (err) => {
        console.log(err);
        // this.fetchLoan();
      }
    );
  }

  paginate(event) {
    this.start = event.page + 1;
    this.fetchPoint();
  }

  // FILTER
  private typingTimer;
  private doneTypingInterval = 1000;
  onSearchName(searchValue: string) {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      this.name = searchValue;
      if (searchValue) {
        this.objFilter["nama"] = searchValue;
      } else {
        delete this.objFilter["nama"];
      }
      this.start = 0;
      this.fetchLoan(1);
    }, this.doneTypingInterval);
  }
  onSearchPinjaman(searchValue: string) {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      this.loan_number = searchValue;
      if (searchValue) {
        this.objFilter["loan_number"] = searchValue;
      } else {
        delete this.objFilter["loan_number"];
      }
      this.start = 0;
      this.fetchLoan(1);
    }, this.doneTypingInterval);
  }
  onSearchNik(searchValue: string) {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      if (searchValue) {
        this.objFilter["no_anggota"] = searchValue;
      } else {
        delete this.objFilter["no_anggota"];
      }
      this.nik = searchValue;
      this.start = 0;
      this.fetchLoan(1);
    }, this.doneTypingInterval);
  }
  changeCompany(e) {
    if (e.value) {
      this.objFilter["company"] = e.value;
    } else {
      delete this.objFilter["company"];
    }
    this.id_company = e.value;
    this.start = 0;
    this.loadingPaginate = true;
    this.fetchLoan(1);
    this.prosesReset();
  }
  changeGrade(e) {
    if (e.value) {
      this.objFilter["golongan"] = e.value;
    } else {
      delete this.objFilter["golongan"];
    }
    this.id_grade = e.value;
    this.start = 0;
    this.loadingPaginate = true;
    this.fetchLoan(1);
  }
  changeStatus(e) {
    if (e.value) {
      this.objFilter["id_workflow_status"] = e.value;
    } else {
      delete this.objFilter["id_workflow_status"];
    }
    this.id_workflow_status = e.value;
    this.start = 0;
    this.fetchLoan(1);
  }
  onSelectTglMasuk(e) {
    if (this.date1[1]) {
      const date1 = moment(this.date1[0]).format("YYYY-MM-DD");
      const date2 = moment(this.date1[1]).format("YYYY-MM-DD");
      // console.log({date1, date2});
      this.start_date = date1;
      this.end_date = date2;
      this.fetchLoan(1);

      this.objFilter["tgl_masuk"] = "2019-02-01 - 2019-02-15";
    } else {
      this.start_date = "";
      this.end_date = "";
      delete this.objFilter["tgl_masuk"];
    }
    this.start = 0;
  }
  onSelectTglPengajuan(e) {
    if (this.date2[1]) {
      const date1 = moment(this.date2[0]).format("YYYY-MM-DD");
      const date2 = moment(this.date2[1]).format("YYYY-MM-DD");
      this.objFilter["tgl_pengajuan"] = "2019-02-01 - 2019-02-15";
    } else {
      delete this.objFilter["tgl_pengajuan"];
    }
    this.start = 0;
    this.fetchLoan(1);
  }

  donwloadReport(valFilter: any) {
    const {
      nama,
      id_koperasi,
      perusahaan,
    } = valFilter;
    let qName = nama != null ? `&name=${nama}` : "";
    let qIdKoperasi = id_koperasi != null ? `&id_koperasi=${id_koperasi}` : "";
    let qPerusahaan = perusahaan != undefined ? `&perusahaan=${perusahaan}` : "";
    this.objFilter = `${qName}${qIdKoperasi}${qPerusahaan}`;
    this.fetchDownloadReportProgress();
  }

  fetchDownloadReportProgress() {
    this.hiddenLink.nativeElement.href = this.loanService.getDownloaReportPoint(this.objFilter);
    this.hiddenLink.nativeElement.click();
  }

  // FILTER
  submitFilter(valFilter: any) {
    const {
      nama,
      id_koperasi,
      perusahaan,
      date_range,
    } = valFilter;
    let qName = nama != null ? `&name=${nama}` : "";
    let qIdKoperasi = id_koperasi != null ? `&id_koperasi=${id_koperasi}` : "";
    let qPerusahaan = perusahaan != undefined ? `&perusahaan=${perusahaan}` : "";
    let qDateRange: string = "";
    if (date_range != undefined) {
      qDateRange =
      date_range[1] != null
          ? `&reqStart=${moment(date_range[0]).format(
              "YYYY-MM-DD"
            )}&reqEnd=${moment(date_range[1]).format("YYYY-MM-DD")}`
          : `&reqStart=${moment(date_range[0]).format(
              "YYYY-MM-DD"
            )}&reqEnd=${moment(date_range[0]).format("YYYY-MM-DD")}`;
    }
    this.objFilter = `${qName}${qIdKoperasi}${qPerusahaan}${qDateRange}`;
    // this.pagination.changePageToFirst(event);
    this.start = 1;
    this.fetchPoint();
  }

  resetFilter() {
    this.objFilter = "";
    // this.pagination.changePageToFirst(event);
    this.fetchPoint();
    this.start = 1;
  }
}
