import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { APIService } from "../../service/api.service";

@Injectable({
  providedIn: "root",
})
export class MasterService {

  // catalog
  private urlGetCatalog = this.APIService["hostContent"] + "/be_catalog/lists";
  private urlSaveCatalog = this.APIService["hostContent"] + "/catalog/create";
  private urlUpdateCatalog = this.APIService["hostContent"] + "/catalog/update";
  
  private urlGetCatalogDraft = this.APIService["hostContent"] + "/catalog/draft";
  private urlSaveCatalogDraft = this.APIService["hostContent"] + "/catalog/create-draft";
  private urlUpdateCatalogDraft = this.APIService["hostContent"] + "/catalog/update-draft";

  

  private urlGetLoanTypeCore =
    this.APIService["hostLoanCore"] + "/master/loan/type";
  private urlGetFeeCore = this.APIService["hostLoanCore"] + "/master/fee";
  private urlGetProductCompany =
    this.APIService["hostLoanCore"] + "/master/productcompany";
  private urlGetTermCore = this.APIService["hostLoanCore"] + "/master/term";
  private urlGetContractTemplate =
    this.APIService["hostLoanCore"] + "/master/contract-templates";
  private urlGetProduct =
    this.APIService["hostLoanCoreStaging"] + "/master/product";
  private urlGetDocumentCore =
    this.APIService["hostLoanCore"] + "/master/document";
  private urlGetLoanType = this.APIService["hostLoan"] + "/master/loan/type";
  private urlMasterMicroLoan =
    this.APIService["hostLoan"] + "/master/microloan/plafonds";
  private urlMasterEditLoad =
    this.APIService["hostAuth"] + "/loan/microloan/edit";
  private urlGetCompany = this.APIService["hostAuth"] + "/company/lists";
  private urlPostCompany = this.APIService["hostAuth"] + "/company/add";
  private urlUpdateCompany = this.APIService["hostAuth"] + "/company/edit";
  private urlSearchProfile =
    this.APIService["hostAuth"] + "/user/get-user-company";
  private urlGetEmailCompany =
    this.APIService["hostAuth"] + "/user/get-user-company-dropdown";
    private urlGetEmailCompany2 =
    this.APIService["hostAuth"] + "/user/get-user-company-dropdown";
  private urlLoanTerm = this.APIService["hostLoan"] + "/master/loan/term";
  private urlInterest = this.APIService["hostLoan"] + "/master/loan/interest";
  private urlPlafond = this.APIService["hostLoan"] + "/master/loan/plafonds";
  private urlVoucherList =
    this.APIService["hostLoan"] + "/loan/backend/voucher/index";
  private urlVoucher = this.APIService["hostLoan"] + "/loan/backend/voucher";

  private urlMasterPointDeskripsi =
  this.APIService["hostLoanNew"] + "/point/point-description";
  private urlInsertPointDeskripsi =
    this.APIService["hostLoanNew"] + "/point/point-description";
  private urlUpdatePointDeskripsi =
  (id: number) => `${this.APIService["hostLoanNew"]}/point/point-description/${id}`;
  private urlMasterPoint =
    this.APIService["hostLoanNew"] + "/point/list";
  private urlTrans =
    this.APIService["hostLoanNew"] + "/point/listorder";
  private urlInsertPoint =
    this.APIService["hostLoanNew"] + "/profile/insertpoint";
  private urlTransPoint=
    this.APIService["hostLoanNew"] + "/point/report";


  // banner
  private urlGetBanner = this.APIService["hostContent"] + "/content/lists";
  private urlGetContentType =
    this.APIService["hostContent"] + "/content-type/dropdown";
  private urlSaveBanner = this.APIService["hostContent"] + "/content/create";
  private urlUpdateBanner = this.APIService["hostContent"] + "/content/update";

  // password
  private urlPutPassword =
  this.APIService["hostAuth"] + "/profile/change-passwordlend";

  constructor(private APIService: APIService, private http: HttpClient) {}

  bikinParam(param) {
    let str = "";
    for (const key in param) {
      if (param[key] != "" && param[key] != undefined) {
        if (str != "") {
          str += "&";
        }
        str += key + "=" + encodeURIComponent(param[key]);
      }
    }
    return str;
  }

  // MASTER company

  getListCompany(start, length, objFilter) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    const filter = escape(JSON.stringify(objFilter));
    return this.http.get(
      this.urlGetCompany +
        "?start=" +
        start +
        "&length=" +
        length +
        "&sort=name_company,asc&manual_filter=" +
        filter,
      options
    );
  }

  postCompany(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    let body = obj;
    return this.http.post(this.urlPostCompany, body, options);
  }
  searchProfile(name, company) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    return this.http.get(
      this.urlSearchProfile + "?name=" + name + "&company_id=" + company,
      options
    );
  }

  getEmailCompany(companyId) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    return this.http.get(
      this.urlGetEmailCompany + "?company_id=" + companyId,
      options
    );
  }




  putCompany(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    let body = obj;
    return this.http.post(this.urlUpdateCompany, body, options);
  }

  // Get Loan
  // ====================== //
  // getLoan(start, length, objFilter) {
  getVoucherList(param) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };

    const filter = this.bikinParam(param);
    // return this.http.get(this.urlGetLoan + '?start=' + start +  '&length=' + length + '&sort=request_date,desc&manual_filter=' + filter, options);
    return this.http.get(this.urlVoucherList + "?" + filter, options);
  }

  postVoucher(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    let body = obj;
    return this.http.post(this.urlVoucher, body, options);
  }
  putVoucher(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    let body = obj;
    return this.http.put(this.urlVoucher, body, options);
  }

  getLoanTerm() {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    return this.http.get(this.urlLoanTerm, options);
  }
  getLoanType() {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    return this.http.get(this.urlGetLoanType, options);
  }
  getCoreLoanType(param) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    const filter = this.bikinParam(param);

    return this.http.get(this.urlGetLoanTypeCore + "?" + filter, options);
    // return this.http.get(this.urlGetLoanTypeCore,options);
  }
  getCoreFee(param) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    const filter = this.bikinParam(param);

    return this.http.get(this.urlGetFeeCore + "?" + filter, options);
  }
  getCoreDocument(param) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };

    const filter = this.bikinParam(param);
    return this.http.get(this.urlGetDocumentCore + "?" + filter, options);
  }

  getCoreTerm(param) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    const filter = this.bikinParam(param);
    return this.http.get(this.urlGetTermCore + "?" + filter, options);
  }

  getContractTemplate() {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    return this.http.get(this.urlGetContractTemplate, options);
  }

  getCoreProduct(param) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };

    const filter = this.bikinParam(param);
    return this.http.get(this.urlGetProduct + "?" + filter, options);
  }

  getCoreProductCompany(param) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    const filter = this.bikinParam(param);
    return this.http.get(this.urlGetProductCompany + "?" + filter, options);
  }

  postCoreProduct(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    let body = obj;
    return this.http.post(this.urlGetProduct, body, options);
  }
  postCoreProductCompany(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    let body = obj;
    return this.http.post(this.urlGetProductCompany, body, options);
  }
  postLoanType(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    let body = obj;
    return this.http.post(this.urlGetLoanType, body, options);
  }
  postCoreLoanType(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    let body = obj;
    return this.http.post(this.urlGetLoanTypeCore, body, options);
  }
  postCoreDocument(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    let body = obj;
    return this.http.post(this.urlGetDocumentCore, body, options);
  }
  postCoreFee(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    let body = obj;
    return this.http.post(this.urlGetFeeCore, body, options);
  }
  postCoreTerm(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    let body = obj;
    return this.http.post(this.urlGetTermCore, body, options);
  }
  putLoanType(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    let body = obj;
    return this.http.put(this.urlGetLoanType, body, options);
  }
  putCoreLoanType(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    let body = obj;
    return this.http.put(this.urlGetLoanTypeCore, body, options);
  }
  putCoreDocument(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    let body = obj;
    return this.http.put(this.urlGetDocumentCore, body, options);
  }
  putCoreProduct(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    let body = obj;
    return this.http.put(this.urlGetProduct, body, options);
  }
  putCoreProductCompany(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    let body = obj;
    return this.http.put(this.urlGetProductCompany, body, options);
  }
  putCoreFee(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    let body = obj;
    return this.http.put(this.urlGetFeeCore, body, options);
  }
  putCoreTerm(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    let body = obj;
    return this.http.put(this.urlGetTermCore, body, options);
  }
  getMicroloan(param) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };

    const filter = this.bikinParam(param);
    return this.http.get(this.urlMasterMicroLoan + "?" + filter, options);
  }

  postMicroloan(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    let body = obj;
    return this.http.post(this.urlMasterMicroLoan, body, options);
  }
  putMicroloan(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    let body = obj;
    return this.http.post(this.urlMasterEditLoad, body, options);
  }

  deleteMicroloan(id) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };

    return this.http.delete(
      `${this.urlMasterMicroLoan}?id_master_microloan_plafond=${id}`,
      options
    );
  }
  postLoanPlafond(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    let body = obj;
    return this.http.post(this.urlPlafond, body, options);
  }
  putLoanPlafond(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    let body = obj;
    return this.http.put(this.urlPlafond, body, options);
  }
  deleteLoanPlafond(id) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };

    return this.http.delete(
      `${this.urlPlafond}?id_master_loan_plafond=${id}`,
      options
    );
  }

  postLoanInterest(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    let body = obj;
    return this.http.post(this.urlInterest, body, options);
  }
  putLoanInterest(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    let body = obj;
    return this.http.put(this.urlInterest, body, options);
  }

  deleteLoanInterest(id) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    return this.http.delete(
      `${this.urlInterest}?id_master_loan_interest=${id}`,
      options
    );
  }

  getDataContentType() {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    return this.http
      .get<any>(this.urlGetContentType, options)
      .pipe(map((res) => res.data));
  }

  getDataBanner(param) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    let filter = this.bikinParam(param);
    return this.http
      .get<any>(this.urlGetBanner + "?" + filter, options)
      .pipe(map((res) => res.data));
  }

  postBanner(param) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };

    return this.http.post<any>(this.urlSaveBanner, param, options);
  }
  updateBanner(param) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };

    return this.http.post<any>(this.urlUpdateBanner, param, options);
  }


    // Update Password
  // ====================== //
  updatePassword(param) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };

    const body = param;
    // console.log(body);
    return this.http.post<any>(this.urlPutPassword, body, options);
  }

  getPointDeskripsi(start, rows, param) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };

    let filter = param != undefined ? param : "";
    // const filter = this.bikinParam(param);

    return this.http.get(this.urlMasterPointDeskripsi + "?page=" + start + "&size=" + rows + filter, options);
  }

 postDeskripsiPoint(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    let body = obj;
    return this.http.post(this.urlInsertPointDeskripsi, body, options);
  }


  getEmailCompany2(companyId) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    return this.http.get(
      this.urlGetEmailCompany2 + "?company_id=" + companyId,
      options
  );
  }

  postPoint(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    let body = obj;
    return this.http.post(this.urlInsertPoint, body, options);
  }

  putDeskripsiPoint(obj,id_deskripsi_point) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    let body = obj;
    const url = this.urlUpdatePointDeskripsi(id_deskripsi_point); // Convert the function call to a string
    return this.http.put(url, body, options);
  }


 getPoint(start, rows, param) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };

    let filter = param != undefined ? param : "";
    // const filter = this.bikinParam(param);

    return this.http.get(this.urlMasterPoint + "?page=" + start + "&size=" + rows + filter, options);
  }

  getTrans(start, rows, param) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };

    let filter = param != undefined ? param : "";
    // const filter = this.bikinParam(param);

    return this.http.get(this.urlTrans + "?page=" + start + "&size=" + rows + filter, options);
  }

  getTransPoint(start, rows, param) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };

    let filter = param != undefined ? param : "";
    // const filter = this.bikinParam(param);

    return this.http.get(this.urlTransPoint + "?page=" + start + "&size=" + rows + filter, options);
  }
postCatalog(param) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };

    return this.http.post<any>(this.urlSaveCatalog, param, options);
  }
  updateCatalog(param) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };

    return this.http.post<any>(this.urlUpdateCatalog, param,options);
}

// getDataCatalog(param, pageLength: number, objFilter: {}) {
//   const options = {
//     headers: new HttpHeaders({
//       accept: "application/json",
//       Authorization: localStorage.getItem("token"),
//     }),
//   };

//   // Extract pagination and sorting parameters
//   const { start, length, sort } = param;

//   // Build the base query string
//   let queryString = `start=${start}&length=${length}&sort=${sort}`;

//   // Add other filter parameters
//   for (const key in param) {
//     if (param.hasOwnProperty(key) && key !== 'start' && key !== 'length' && key !== 'sort') {
//       queryString += `&${key}=${encodeURIComponent(param[key])}`;
//     }
//   }

//   console.log('sandir',queryString);

//   return this.http
//     .get<any>(`${this.urlGetCatalog}?${queryString}`, options)
//     .pipe(map((res) => res.data));
// }


getDataCatalog(start, length, objFilter) {
  const options = {
    headers: new HttpHeaders({
      accept: "application/json",
      Authorization: localStorage.getItem("token"),
    }),
  };
  const filter = escape(JSON.stringify(objFilter));
  return this.http.get<any>(
    this.urlGetCatalog +
      "?start=" +
      start +
      "&length=" +
      length +
      "&sort=a.created_at,desc&manual_filter=" +
      filter,
    options
).pipe(map((res) => res.data));
}

// getDataCatalogDraft(param) {
//   const options = {
//     headers: new HttpHeaders({
//       accept: "application/json",
//       Authorization: localStorage.getItem("token"),
//     }),
//   };
//   let filter = this.bikinParam(param);
//   return this.http
//     .get<any>(this.urlGetCatalogDraft + "?" + filter, options)
//     .pipe(map((res) =>res.data));
// }

getDataCatalogDraft(start, length, objFilter) {
  const options = {
    headers: new HttpHeaders({
      accept: "application/json",
      Authorization: localStorage.getItem("token"),
    }),
  };
  const filter = escape(JSON.stringify(objFilter));
  return this.http.get<any>(
    this.urlGetCatalogDraft +
      "?start=" +
      start +
      "&length=" +
      length +
      "&sort=a.created_at,desc&manual_filter=" +
      filter,
    options
).pipe(map((res) => res.data));
}

postCatalogDraft(param) {
  const options = {
    headers: new HttpHeaders({
      accept: "application/json",
      Authorization: localStorage.getItem("token"),
    }),
  };

  return this.http.post<any>(this.urlSaveCatalogDraft, param, options);
}
updateCatalogDraft(param) {
  const options = {
    headers: new HttpHeaders({
      accept: "application/json",
      Authorization: localStorage.getItem("token"),
    }),
  };

  return this.http.post<any>(this.urlUpdateCatalogDraft, param,options);
}

getDownloaReport() {

  return 'https://u.koperasi-astra.com/download-template-stock' ;
}

getDownloaReportDraft() {

  return 'https://u.koperasi-astra.com/download-template-draft' ;
}

getOrderPoint(start, rows, param) {
  const options = {
    headers: new HttpHeaders({
      accept: "application/json",
      Authorization: localStorage.getItem("token"),
    }),
  };

  let filter = param != undefined ? param : "";
  // const filter = this.bikinParam(param);
  return '';
  // return this.http.get(this.urlOrderPoint + "?page=" + start + "&size=" + rows + filter, options);
}

}
