import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-loan',
  template: `<router-outlet></router-outlet>`,
  styles: []
})
export class MainLoanComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
