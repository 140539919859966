import { Component, OnInit } from '@angular/core';
import {Router, NavigationExtras} from '@angular/router';
import { LoanService } from '../../loan/loan.service';
import { MemberService } from '../../member/member.service';
import { MasterService } from '../master.service';

import { MessageService } from 'primeng/components/common/messageservice';
import * as _ from 'lodash';
import * as moment from 'moment';
declare var $: any;

import { store } from '../../../service/store.service';


@Component({
	selector: 'app-voucher',
	templateUrl: './voucher.component.html',
	styleUrls: ['./voucher.component.scss'],
  providers: [MessageService]
})
export class VoucherComponent implements OnInit {
  private objFilter = {};
  public data;
  public columns: any = [];
  public selectedColumns: any[];
  public loading: boolean;
  public selectedItem = null;
  public grades = [];
  public companies = [];
  public start = 0;

  public pageLength = 10;
  public totalCount = 0;
  public originGrades = [];
  public results : any[];
  public selectedGrade = null;
  public voucherNew = false;
  public voucherView = false;
  public date = null;
  public date1: Date = null;
  public date2: Date = null;
  public minDate = moment().add('days', -1)['_d'];
  public isSubmitApprove = false;
  public loadingBtn = false;
  public isSubmitReject = false;
  public voucherEdit = false;
  public widthTable = 0;
  public name = '';
  public id_company = '';
  public nama;
  public selectedName;
  public selectedIdUser;
  public amount = '';
  public id_voucher = '';
  public start_date = '';
  public end_date = '';
  public voucher_code = '';
  public name_loan_type = '';
  public loan_number = '';
  public start_valid_date = '';
  public end_valid_date = '';
  public start_percentage = '';
  public name_company = '';
  public end_percentage = '';
  public start_redeem_date = '';
  public end_redeem_date = '';
  public id_grade = '';
  public id_workflow_status = '';
  public id_loan_type = '';
  public id_employee = '';
  public nik = '';
  public loanTypes = [
    {label: 'Semua tipe Loan', value: null},
    {label: 'Microloan', value: 1},
    {label: 'Middle Loan', value: 2},
  ];
  public selectedLoan = null;

  public arrStatus = [
    {label: 'All', value: null},
    {label: 'Menunggu pembayaran', value: 'a'},
    {label: 'Menunggu Approval HR', value: 'b'},
    {label: 'Menunggu Approval Keanggotaan', value: 'c'},
  ];
  public selectedStatus = null;

  constructor(
    private loanService: LoanService,
    private masterService: MasterService,
    private memberService: MemberService,
    private router: Router
  ) { }


  ngOnInit() {
    this.fetchLoan(1);
    this.fetchCompany();
    this.fetchGrade();
    this.fetchLoanType();
    this.columns = [
      {field: 'number', header: 'No', show: true},
      {field: 'voucher_code', header: 'Kode Voucher', show: true},
      {field: 'name', header: 'Nama Anggota', show: true},
      {field: 'id_employee', header: 'NIK', show: true},
      {field: 'name_company', header: 'Company', show: true},
      {field: 'name_loan_type', header: 'Tipe Pinjaman', show: true},

      {field: 'percentage', header: 'Persentase', show: false},
      {field: 'valid_date', header: 'Tgl Berlaku', show: true},
      {field: 'redeem_date', header: 'Tgl Redeem', show: true},
      // {field: 'name_loan_type', header: 'Produk Pinjaman', show:true},
    ];
    this.selectedColumns = _.filter(this.columns, {show: true});

    // const widthTB = $('#tab-card').width();
    this.widthTable = 1200;
  }
  fetchLoanType() {
    // this.loading = true;
    this.masterService.getLoanType().subscribe(res => {
      let tmp=[];
      _.map(res['data'], (x, i) => {
       tmp.push(
         {
           label:x.name_loan_type,
           value:x.id_loan_type
         }
       )
      });
      this.loanTypes=tmp;
      // this.data = res['data'];
      // this.loading = false;
    });
  }
  // Toggle Columns
  // ====================== //
  changeColumn(e) {
    const find = _.find(this.columns, e.itemValue);
    if (e.itemValue != undefined) {
      if (e.itemValue.show) {
        find.show = false;
      } else {
        find.show = true;
      }
    } else {

    }

    this.selectedColumns = _.filter(this.columns, {show: true});
  }
  compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const bandA = a.request_date.toUpperCase();
    const bandB = b.request_date.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison * -1;
  }
  // Fetching Loan
  // ========================= //
  fetchLoan(page) {
    this.loading = true;
    let {name, id_employee, name_company, loan_number,  start_valid_date, end_valid_date} = this;
    let param = {page, name, id_employee, name_company, loan_number,  row:10, start_valid_date, end_valid_date};
    this.masterService.getVoucherList(param).subscribe(res => {
      this.data = res['data']['data'];

      // const tmp = this.data.sort(this.compare);
      const tmp = this.data;
      console.log({tmp});
      _.map(tmp, (x, i) => {
        x.number = i + 1;
        // x.microloan_amount = 'Rp ' + x.microloan_amount.toLocaleString();
        x.valid_date = x.valid_date ?  moment(x.valid_date).format('DD MMM YYYY') : '-';
        x.redeem_date = x.redeem_date ?  moment(x.redeem_date).format('DD MMM YYYY') : '-';
      });

      this.totalCount = Number(res['data'].count_filter);
      // store.dispatch({ type: 'COUNTER', jmlCalongAnggota: Number(res['data'].count_all) });
      this.loading = false;
    }, err => {
      this.loading = false;
    });
  }

  searchProfile(event) {
    this.memberService.searchProfile(event.query).subscribe(data => {
      this.results = data['data'];

    });
  }

  tambahBaru(){
    this.voucherNew=true;
    this.nama={};
    this.selectedLoan=null;
    this.voucher_code='';
    this.date = null;
    this.amount='';
  }
  // Select Item / User
  // ======================== //
  selectItem(e) {
    console.log(e);
    this.voucher_code = e.voucher_code;
    this.selectedLoan=e.id_loan_type;
    this.date = moment(e.valid_date).format("YYYY-MM-DD");
    this.amount = e.percentage;
    // this.nama.id_user = e.id_user;
    // this.nama.name = e.name;
    this.selectedName = e.name;
    this.selectedIdUser = e.id_user;
    this.id_voucher = e.id_voucher;

    this.voucherEdit=true;
  }
  submit(){
    this.loadingBtn=true;
    let data = {
      "id_user": this.nama.id_user,
      "voucher_code": this.voucher_code,
      "id_loan_type": this.selectedLoan,
      "valid_date": this.date,
      "percentage": this.amount
    };
    this.masterService.postVoucher(data).subscribe(res => {
      this.loadingBtn = false;
      this.voucherNew = false;
      this.fetchLoan(1);
    });
  }
  submitEdit(){
    this.loadingBtn=true;
    let data = {
      "id_user": this.selectedIdUser,
      "id_voucher": this.id_voucher,
      "voucher_code": this.voucher_code,
      "id_loan_type": this.selectedLoan,
      "valid_date": this.date,
      "percentage": this.amount
    };
    this.masterService.putVoucher(data).subscribe(res => {
      this.loadingBtn = false;
      this.voucherEdit = false;
      this.fetchLoan(1);
    });
  }
  // Fetch Grade
  // ========================= //
  fetchGrade() {
    this.loanService.getGrade().subscribe(res => {
      this.originGrades = res['data'];
      this.grades = [];
      _.map(res['data'], (x) => {
        const obj = {label: x.name_grade, value: x.id_grade};
        this.grades.push(obj);
      });
    }, err => {
      // this.fetchGrade();
      console.log(err);
    });
  }
  terpilih(val){
    // console.log(val);
    console.log(this.nama);
  }
  // Fetch Company
  // ========================= //
  fetchCompany() {
    this.loanService.getCompany().subscribe(res => {
      this.companies = [{label: 'Semua Perusahaan', value: null}];
      _.map(res['data'], (x) => {
        this.companies.push({label: x.name_company, value: x.id_company});
      });
    }, err => {
      console.log(err);
      // this.fetchLoan();
    });
  }

  paginate(e) {
    this.start = e.page * this.pageLength;
    this.fetchLoan(e.page);
  }

  // FILTER
  private typingTimer;
  private doneTypingInterval = 1000;
  onSearchName(searchValue: string ) {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      this.name = searchValue;
      if (searchValue) {

        this.objFilter['nama'] = searchValue;
      } else {
        delete this.objFilter['nama'];
      }
      this.start = 0;
      this.fetchLoan(1);
    }, this.doneTypingInterval);
  }
  onSearchNik(searchValue: string ) {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      if (searchValue) {
        this.objFilter['no_anggota'] = searchValue;
      } else {
        delete this.objFilter['no_anggota'];
      }
      this.nik = searchValue;
      this.start = 0;
      this.fetchLoan(1);
    }, this.doneTypingInterval);
  }
  changeCompany(e) {
    if (e.value) {
      this.objFilter['company'] = e.value;
    } else {
      delete this.objFilter['company'];
    }
    this.id_company = e.value;
    this.start = 0;
    this.fetchLoan(1);
  }
  changeGrade(e) {
    if (e.value) {
      this.objFilter['golongan'] = e.value;
    } else {
      delete this.objFilter['golongan'];
    }
    this.id_grade = e.value;
    this.start = 0;
    this.fetchLoan(1);
  }
  changeStatus(e) {
    if (e.value) {
      this.objFilter['status'] = e.value;
    } else {
      delete this.objFilter['status'];
    }
    this.id_workflow_status = e.value;
    this.start = 0;
    this.fetchLoan(1);
  }
  onSelectTglMasuk(e) {
    if (this.date1[1]) {
      const date1 = moment(this.date1[0]).format('YYYY-MM-DD');
      const date2 = moment(this.date1[1]).format('YYYY-MM-DD');
      // console.log({date1, date2});
      this.start_date = date1;
      this.end_date = date2;
      this.fetchLoan(1);

      this.objFilter['tgl_masuk'] = '2019-02-01 - 2019-02-15';
    } else {
      this.start_date = '';
      this.end_date = '';
      delete this.objFilter['tgl_masuk'];
    }
    this.start = 0;

  }
  onSelectTglPengajuan(e) {
    if (this.date2[1]) {
      const date1 = moment(this.date2[0]).format('YYYY-MM-DD');
      const date2 = moment(this.date2[1]).format('YYYY-MM-DD');
      this.objFilter['tgl_pengajuan'] = '2019-02-01 - 2019-02-15';
    } else {
      delete this.objFilter['tgl_pengajuan'];
    }
    this.start = 0;
    this.fetchLoan(1);
  }
}
