import { Component, OnInit } from "@angular/core";
import { MemberService } from "../../member/member.service";
import { MasterService } from "../master.service";
import { MessageService } from "primeng/components/common/messageservice";
import * as _ from "lodash";
import * as moment from "moment";
import { catchError, retry } from "rxjs/operators";
import { throwError } from "rxjs";

@Component({
  selector: "app-core-document",
  templateUrl: "./core-document.component.html",
  styleUrls: ["./core-document.component.scss"],
  providers: [MessageService],
})
export class CoreDocumentComponent implements OnInit {
  public data: any = [];
  public columns: any = [];
  public display = false;
  public displayImg = false;
  public isAddMulai = false;
  public isEditMulai = false;
  public approveDialoge = false;
  public rejectDialoge = false;
  public addModal = false;
  public selectedColumns: any[];
  public loading: boolean;
  public selectedItem = null;
  public selectedImg = null;
  public grades = [];
  public grades2 = [];
  public selectedGrade = null;
  public companies = [];
  public roles = [];
  public list_unit = [];
  public companies2 = [];
  public selectedCompany = null;
  public originGrades = [];
  public arrStatus = [];
  public arrReligion = [];
  public arrDomicile = [];
  public arrMariege = [];
  public statusOpsi = [];
  public arrRole = [];
  public selectedStatus = null;
  public date: Date = null;
  public date1: Date = null;
  public date2: Date = null;
  public minDate = moment().add("days", -1)["_d"];
  public isSubmitApprove = false;
  public isSubmitReject = false;
  public viewDetail = false;
  public totalCount = 0;
  public start = 0;
  public pageLength = 10;
  public availabelColumn: Number;
  public widthDisplay: number;
  public roleId;
  public status = "";
  public search_name = "";
  public fee_name = "";
  public unit_value = "";
  public is_kopnit = 0;
  public kopnitArray = [
    { label: "Yes", value: 1 },
    { label: "No", value: 0 },
  ];
  public name_company = "";
  public phone_number = "";
  public document_name = "";
  public loan_power = "";
  public id_holding = "HLD001";
  public address = "";
  public dataProfile = null;
  private objFilter = {};

  public displayForm = false;
  public isSubmitRegis = false;

  public name;
  public company;
  public widthTable = 1000;
  public email;
  public identity_photo;
  public company_identity_photo;
  public personal_photo;
  public role;

  public imageID = null;
  public imageKTP = null;
  public imagePersonal = null;

  constructor(
    private messageService: MessageService,
    private masterService: MasterService,
    private memberService: MemberService
  ) {}

  ngOnInit() {
    this.fetchComapany(1);
    this.roleId = localStorage.getItem("id_role_master");
    this.widthDisplay = 1200;
    this.list_unit = [{ label: "0 - Other", value: 0 }];
    this.list_unit.push({ label: "1 - Currency", value: 1 });
    this.list_unit.push({ label: "2 - Percentage", value: 2 });
    this.list_unit.push({ label: "3 - Permil", value: 3 });
    // account_name_bank_main: null
    // account_name_bank_secondary: null
    // account_number_bank_main: null
    // account_number_bank_secondary: null
    // address: "JL MAJAPAHIT NO 26 PETOJO SELATAN - GAMBIR 10160 - JAKARTA"
    // created_at: null
    // created_by: null
    // deleted_at: null
    // deleted_by: null
    // id_bank_main: null
    // id_bank_secondary: null
    // id_company: "COMP1626"
    // id_holding: "HLD001"
    // is_kopnit: null
    // loan_power: null
    // name_company: "ACSET INDONUSA, PT."
    // phone_number: "213511961"
    // status: "1"
    // updated_at: null
    // updated_by: null
    this.columns = [
      { field: "id_document_type", header: "ID Document", show: true },
      { field: "document_name", header: "Document Name", show: true },
      { field: "is_khusus", header: "Jenis", show: true },
      { field: "deleted_at", header: "Status", show: true },
    ];

    this.availabelColumn = _.filter(this.columns, { show: true }).length + 1;
    this.selectedColumns = _.filter(this.columns, { show: true });
  }

  // Save
  // ========================= //
  save(e) {
    this.isSubmitRegis = true;
    e["identity_photo"] = this.imageID.imageSrc;
    e["company_identity_photo"] = this.imageKTP.imageSrc;
    e["personal_photo"] = this.imagePersonal.imageSrc;
    e["role"] = "ROLE001";

    this.memberService.postUser(e).subscribe(
      (res) => {
        this.isSubmitRegis = false;
        this.displayForm = false;
        this.fetchComapany(1);
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "User has been added",
        });
      },
      (err) => {
        console.log(err);

        this.isSubmitRegis = false;
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Please try again",
        });
      }
    );
  }
  // Toggle Columns
  // ====================== //
  changeColumn(e) {
    const find = _.find(this.columns, e.itemValue);
    if (e.itemValue != undefined) {
      if (e.itemValue.show) {
        find.show = false;
      } else {
        find.show = true;
      }
    }
    this.availabelColumn = _.filter(this.columns, { show: true }).length + 1;
    this.selectedColumns = _.filter(this.columns, { show: true });
  }

  // Convert Image
  // ========================= //
  private typeupload = null;
  handleInputChange(e, type) {
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    const pattern = /image-*/;
    const reader = new FileReader();
    if (file != undefined) {
      if (!file.type.match(pattern)) {
        alert("invalid format");
        return;
      }
    }

    this.typeupload = type;
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  _handleReaderLoaded(e, type) {
    const reader = e.target;
    switch (this.typeupload) {
      case "company":
        this.imgEmployee = reader.result;
        break;
      case "document":
        this.imgDocument = reader.result;
        break;
    }

    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 500);
  }
  pilihImg(val) {
    this.selectedImg = val;
    this.displayImg = true;
  }
  // Fetching User
  // ========================= //
  fetchComapany(page) {
    this.loading = true;
    const param = { page, row: 10, document_name: this.search_name };
    this.masterService.getCoreDocument(param).subscribe(
      (res) => {
        this.data = res["data"].detail;
        this.totalCount = Number(res["data"].count);
        this.loading = false;
      },
      (err) => {
        // this.fetchComapany(1);
        this.loading = false;
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }
  paginate(e) {
    this.start = e.page * this.pageLength;
    this.fetchComapany(e.page + 1);
  }
  approveItem(val) {
    this.approveDialoge = true;
    this.selectedItem = val;
  }
  rejectItem(val) {
    this.rejectDialoge = true;
    this.selectedItem = val;
  }
  tambahNew() {
    this.name_company = "";
    this.is_kopnit = 0;
    this.phone_number = "";
    this.document_name = "";
    this.loan_power = "";
    this.address = "";
    this.isAddMulai = false;
    this.addModal = true;
  }
  prosesAdd() {
    this.isAddMulai = true;
    const body = {
      document_name: this.document_name,
    };
    // console.log(body);
    this.masterService.postCoreDocument(body).subscribe(
      (res) => {
        if (res["status"] == 0) {
          this.isAddMulai = false;
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: res["message"],
          });
        } else {
          this.addModal = false;
          this.isAddMulai = false;
          this.fetchComapany(1);
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Data Registered Successfully",
          });
        }
      },
      (err) => {
        console.log(err);
        this.isAddMulai = false;
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: err.error.message,
        });
      }
    );
  }
  prosesEdit() {
    this.isEditMulai = true;
    console.log(this.selectedItem);

    const body = {
      id_document_type: this.selectedItem.id_document_type,
      document_name: this.document_name,
    };
    // console.log(body);
    this.masterService.putCoreDocument(body).subscribe(
      (res) => {
        if (res["status"] == 0) {
          this.isEditMulai = false;
          this.viewDetail = false;
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: res["message"],
          });
        } else {
          this.addModal = false;
          this.isEditMulai = false;
          this.fetchComapany(1);
          this.viewDetail = false;
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Data Update Successfully",
          });
        }
      },
      (err) => {
        console.log(err);
        this.isEditMulai = false;
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: err.error.message,
        });
      }
    );
  }
  prosesActive() {
    this.isSubmitApprove = true;
    const body = {
      id: this.selectedItem["id_user"],
    };
    this.memberService.activeUserBe(body).subscribe(
      (res) => {
        this.viewDetail = false;
        this.isSubmitApprove = false;
        this.fetchComapany(1);
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "User Activated",
        });
      },
      (err) => {
        this.isSubmitApprove = false;
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Please try again",
        });
      }
    );
  }
  prosesDeactive() {
    this.isSubmitReject = true;
    const body = {
      id: this.selectedItem["id_user"],
    };
    this.memberService.deactiveUserBe(body).subscribe(
      (res) => {
        this.viewDetail = false;
        this.isSubmitReject = false;
        this.fetchComapany(1);
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "User Deactivated",
        });
      },
      (err) => {
        this.isSubmitApprove = false;
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Please try again",
        });
      }
    );
  }
  // Select Item / User
  // ======================== //
  selectItem(e) {
    this.viewDetail = true;
    let tmp = JSON.parse(JSON.stringify(e));
    this.selectedItem = JSON.parse(JSON.stringify(e));
    this.document_name = tmp.document_name;
  }
  tabChange() {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 100);
  }

  // Create Mutation Employee
  // ======================== //
  public isSubmitMutation = false;
  public idEmployee = null;
  public division = null;
  public position = null;
  public selectedCompany2 = null;
  public imgEmployee = null;
  createMutation() {
    this.isSubmitMutation = true;
    const obj = {
      id: this.selectedItem.id_user,
      id_employee: this.idEmployee,
      id_company: this.selectedCompany2,
      company_identity_photo: this.imgEmployee,
      division: this.division,
      position: this.position,
    };
    console.log(obj);
    this.memberService.updateMutation(obj).subscribe(
      (res) => {
        this.isSubmitMutation = false;
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Mutasi karyawan berhasil",
        });
        this.display = false;
        this.fetchComapany(1);
      },
      (err) => {
        this.isSubmitMutation = false;
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Mutasi karyawan gagal, silakan coba lagi",
        });
      }
    );
  }

  // Select Company Mutation Employee
  // ============================== //
  changeCompany2(e) {
    this.selectedCompany2 = e.value;
  }

  // Fetch Salary
  // ============================ //
  fetchSallary(id) {
    this.memberService.getSallary(id).subscribe(
      (res) => {
        this.dataProfile["salary"] = res["data"];
        this.dataProfile["salary"].amount = Number(
          res["data"].salary_amount
        ).toLocaleString();
        this.fetchBank(id);
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }

  // Fetch Bank
  // ============================ //
  fetchBank(id) {
    this.memberService.getBank(id).subscribe(
      (res) => {
        this.dataProfile["bank"] = res["data"];
        this.fetchDocument(id);
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }

  // Fetch Document
  // ============================ //
  public isViewDocument = false;
  public openTabDocument = false;
  public selectedDocument = null;
  public imgDocument = null;
  public isSubmitDocument = false;
  public arrDocumentType = [];
  public selectedDocumentType = null;
  public isUpdateDocument = false;

  fetchDocument(id) {
    this.memberService.getDocument(id).subscribe(
      (res) => {
        res["data"].map((x) => {
          x["disable"] = false;
          x["document_name"] = _.find(this.arrDocumentType, {
            value: x.id_document_type,
          }).label;
        });
        this.dataProfile["document"] = res["data"];
        this.display = true;
        this.loading = false;
        this.openTabDocument = false;
        setTimeout(() => {
          window.dispatchEvent(new Event("resize"));
        }, 100);
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }
  removeDocument(e) {
    this.selectedDocument = e;
    this.selectedDocument.disable = true;
    this.memberService
      .deleteDocument(
        Number(this.selectedDocument.id_user),
        this.selectedDocument.id_user_document
      )
      .subscribe(
        (res) => {
          this.fetchDocument(Number(this.selectedDocument.id_user));
          this.selectedDocument = null;
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Berhasil hapush dokumen",
          });
        },
        (err) => {
          this.selectedDocument.disable = false;
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: "Gagal hapush dokumen",
          });
        }
      );
  }
  viewDocument(e) {
    this.widthDisplay = 800;
    this.selectedDocument = e;
    this.isViewDocument = true;
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 100);
  }
  closeViewDocument() {
    this.widthDisplay = 1200;
    this.isViewDocument = false;
    this.openTabDocument = true;
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 100);
  }
  changeDocumentType(e) {
    this.selectedDocumentType = e.value;
  }
  createDocument() {
    const obj = {
      id: this.selectedItem.id_user,
      id_document_type: this.selectedDocumentType,
      doc_photo: this.imgDocument,
    };
    this.isSubmitDocument = true;

    if (this.isUpdateDocument) {
      this.memberService.updateDocument(obj).subscribe(
        (res) => {
          this.isSubmitDocument = false;
          this.fetchDocument(this.selectedItem.id_user);
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Berhasil tambah dokumen",
          });
          this.cancelUpdateDoc();
        },
        (err) => {
          this.isSubmitDocument = false;
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: "Gagal tambah dokumen",
          });
        }
      );
    } else {
      this.memberService.postDocument(obj).subscribe(
        (res) => {
          this.isSubmitDocument = false;
          this.fetchDocument(this.selectedItem.id_user);
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Berhasil tambah dokumen",
          });
        },
        (err) => {
          this.isSubmitDocument = false;
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: "Gagal tambah dokumen",
          });
        }
      );
    }
  }
  editDocument(e) {
    this.isUpdateDocument = true;
    this.selectedDocumentType = e.id_document_type;
    this.imgDocument = e.path;
  }
  cancelUpdateDoc() {
    this.isUpdateDocument = false;
    this.imgDocument = null;
  }

  // Fetch Master
  // ========================= //
  fetchStatus() {
    this.memberService.getStatus().subscribe(
      (res) => {
        this.arrStatus = [{ label: "Semua Status", value: null }];
        _.map(res["data"], (x) => {
          const obj = {
            label: x.workflow_status_name,
            value: x.id_workflow_status,
          };
          this.arrStatus.push(obj);
        });

        _.map(this.data, (x) => {
          x["status_name"] = _.find(this.arrStatus, {
            value: x.id_workflow_status,
          }).label;
        });
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }
  fetchGrade() {
    this.memberService.getGrade().subscribe(
      (res) => {
        this.originGrades = res["data"];
        this.grades = [{ label: "Semua Golongan", value: null }];
        this.grades2 = [];
        _.map(res["data"], (x) => {
          const obj = { label: x.name_grade, value: x.id_grade };
          this.grades.push(obj);
          this.grades2.push(obj);
        });
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }
  fetchDomicile() {
    this.memberService.getDomicile().subscribe(
      (res) => {
        this.arrDomicile = res["data"];
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }
  fetchReligion() {
    this.memberService.getReligion().subscribe(
      (res) => {
        this.arrReligion = res["data"];
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }
  fetchMarriage() {
    this.memberService.getMarriage().subscribe(
      (res) => {
        this.arrMariege = res["data"];
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }
  fetchRole() {
    this.memberService.getRole().subscribe(
      (res) => {
        this.arrRole = res["data"];
        this.roles = [];
        _.map(res["data"], (x) => {
          this.roles.push({
            label: x.name_role_master,
            value: x.id_role_master,
          });
        });
        this.role = this.roles[0].value;
        console.log(this.role);
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }
  fetchMasterDocumentType() {
    this.memberService.getMstDocument().subscribe(
      (res) => {
        _.map(res["data"], (x) => {
          const obj = { label: x.document_name, value: x.id_document_type };
          this.arrDocumentType.push(obj);
        });
        this.selectedDocumentType = this.arrDocumentType[0].value;
      },
      (err) => {
        if (err.status == 401) {
          this.memberService.updateToken(err.error.data.token);
        }
      }
    );
  }

  // Filter List
  // ========================== //
  private typingTimer;
  private doneTypingInterval = 1000;
  onSearchName(searchValue: string) {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      if (searchValue) {
        this.search_name = searchValue;
      } else {
        this.search_name = "";
      }
      this.start = 0;
      this.fetchComapany(1);
    }, this.doneTypingInterval);
  }
  onSearchNik(searchValue: string) {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      if (searchValue) {
        this.objFilter["no_anggota"] = searchValue;
      } else {
        delete this.objFilter["no_anggota"];
      }
      this.start = 0;
      this.fetchComapany(1);
    }, this.doneTypingInterval);
  }
  changeCompany(e) {
    if (e.value) {
      this.objFilter["company"] = e.value;
    } else {
      delete this.objFilter["company"];
    }

    this.start = 0;
    this.fetchComapany(1);
  }
  changeStatus(e) {
    if (e.value) {
      this.objFilter["status"] = e.value;
    } else {
      delete this.objFilter["status"];
    }

    this.start = 0;
    this.fetchComapany(1);
  }
  changeCompanyAdd(e) {
    this.company = e.value;
  }
  changeRoleAdd(e) {
    this.role = e.value;
  }
  changeGrade(e) {
    if (e.value) {
      this.objFilter["golongan"] = e.value;
    } else {
      delete this.objFilter["golongan"];
    }
    this.start = 0;
    this.fetchComapany(1);
  }
  // changeStatus(e) {
  //   if (e.value) {
  //     this.objFilter['status'] = e.value;
  //   } else {
  //     delete this.objFilter['status'];
  //   }
  //   this.start = 0;
  //   this.fetchComapany(1);
  // }
  onSelectTglMasuk(e) {
    if (this.date1[1]) {
      const date1 = moment(this.date1[0]).format("YYYY-MM-DD");
      const date2 = moment(this.date1[1]).format("YYYY-MM-DD");
      this.objFilter["tgl_masuk"] = "2019-02-01 - 2019-02-15";
    } else {
      delete this.objFilter["tgl_masuk"];
    }
    this.start = 0;
    this.fetchComapany(1);
  }
  onSelectTglPengajuan(e) {
    if (this.date2[1]) {
      const date1 = moment(this.date2[0]).format("YYYY-MM-DD");
      const date2 = moment(this.date2[1]).format("YYYY-MM-DD");
      this.objFilter["tgl_pengajuan"] = "2019-02-01 - 2019-02-15";
    } else {
      delete this.objFilter["tgl_pengajuan"];
    }
    this.start = 0;
    this.fetchComapany(1);
  }
}
