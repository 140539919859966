import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { MasterService } from "./master.service";
import { IconsModule } from "../../icons/icons.module";
import { AutoCompleteModule } from "primeng/autocomplete";
import { TableModule } from "primeng/table";
import { MultiSelectModule } from "primeng/multiselect";
import { ToastModule } from "primeng/toast";
import { SidebarModule } from "primeng/sidebar";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { DropdownModule } from "primeng/dropdown";
import { CalendarModule } from "primeng/calendar";
import { DialogModule } from "primeng/dialog";
import { FileUploadModule } from "primeng/fileupload";
import { SpinnerModule } from "primeng/spinner";
import { InputSwitchModule } from "primeng/inputswitch";
import { CustomFormsModule } from "ng2-validation";
import { ListboxModule } from "primeng/listbox";
import { MainMasterComponent } from "./main-master/main-master.component";
import { LoanTypeComponent } from "./loan-type/loan-type.component";

import { PaginatorModule } from "primeng/paginator";
import { UserSettingComponent } from "./user-setting/user-setting.component";
import { VoucherComponent } from "./voucher/voucher.component";
import { InputTextModule } from "primeng/inputtext";
import { KeyFilterModule } from "primeng/keyfilter";
import { CompanyComponent } from "./company/company.component";
import { CheckboxModule } from "primeng/checkbox";
import { MicroloanMasterComponent } from "./microloan/microloan.component";
import { CoreLoanTypeComponent } from "./core-loan-type/core-loan-type.component";
import { CoreFeeComponent } from "./core-fee/core-fee.component";
import { CoreTermComponent } from "./core-term/core-term.component";
import { CoreDocumentComponent } from "./core-document/core-document.component";
import { CoreProductComponent } from "./core-product/core-product.component";
import { ProducCompanyComponent } from "./produc-company/produc-company.component";
import { UploadBannerComponent } from "./upload-banner/upload-banner.component";
import { CatalogComponent } from "./catalog/catalog.component";
import { ChangePassword } from "./change-password/change-password.component";
import { RadioButtonModule } from "primeng/primeng";
import { UserResignComponent } from "./user-resign/user-resign.component";
import { PointMasterComponent } from "./point/point.component";
import { OrderPointComponent } from "./order-point/orderpoint.component";
import { DeskripsiPointComponent } from "./deskripsi-point/deskripsipoint.component";

import { ReportMasterComponent } from "./report/report.component";
import { UserBlacklistComponent } from "./user-blacklist/user-blacklist.component";

import { CatalogDraftComponent } from "./catalog-draft/catalog-draft.component";

@NgModule({
  imports: [
    RouterModule,
    FormsModule,
    CommonModule,
    AutoCompleteModule,
    BrowserAnimationsModule,
    IconsModule,
    HttpClientModule,
    PaginatorModule,
    InputTextModule,
    CustomFormsModule,
    ReactiveFormsModule,
    ListboxModule,
    TableModule,
    ToastModule,
    SidebarModule,
    ProgressSpinnerModule,
    MultiSelectModule,
    DropdownModule,
    CalendarModule,
    DialogModule,
    SpinnerModule,
    FileUploadModule,
    InputSwitchModule,
    CheckboxModule,
    KeyFilterModule,
    RadioButtonModule,    

  ],
  declarations: [
    LoanTypeComponent,
    UserSettingComponent,
    MainMasterComponent,
    VoucherComponent,
    CompanyComponent,
    MicroloanMasterComponent,
    CoreLoanTypeComponent,
    CoreFeeComponent,
    CoreTermComponent,
    CoreDocumentComponent,
    CoreProductComponent,
    ProducCompanyComponent,
    UserResignComponent,
    UploadBannerComponent,
    CatalogComponent,
    ChangePassword,
    PointMasterComponent,
    OrderPointComponent,
    DeskripsiPointComponent,
    ReportMasterComponent,
    UserBlacklistComponent,
    CatalogDraftComponent
  ],
  providers: [MasterService],
})
export class MasterModule {}
