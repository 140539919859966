import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-ticket',
  template: `<router-outlet></router-outlet>`,
  styles: []
})
export class MainTicketComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

