import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrderService } from '../order.service';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-main-order',
  templateUrl: './main-order.component.html',
  styleUrls: ['./main-order.component.scss']
})
export class MainOrderComponent implements OnInit {
	private objFilter = {};
	public data: any = [];
	public columns: any = [];
	public idOrder: String = '';
	public selectedCompany: String;
	public selectedColumns: any[];
  public loading_pagination = false;
  public resetData = true;
	public loading: boolean;
	public loadingModal: boolean;
	public id_workflow_status=null;
	public selectedItem = null;
	public itemRes = null;
	public companies = [];
	public tipes = [
    {label: '- All Type -' , value: ''},
    {label: 'Microloan', value: 'PAY001'},
    {label: 'Middleloan', value: 'PAY002'},
    {label: 'VA', value: 'PAY003'},
    {label: 'Split', value: 'PAY004'}
  ];
	public display = false;
	public date: Date = null;
	public date1: Date = null;
	public sendDate1: String = '';
	public sendDate2: String = '';
  public billing_date_start = '';
  public id_payment_type = '';
  public billing_date_end = '';
	public id_company= null;
	public date2: Date = null;
	public start = 1;
	public pageLength = 10;
  public widthTable = 0;
  public totalCount = 0;
	public arrStatus = [
		{label: '- All Status -', value: ''},
		{label: 'CheckOut', value: 'ODSTS00'},
		{label: 'Waiting for Payment', value: 'ODSTS01'},
		{label: 'Paid Order', value: 'ODSTS02'},
		{label: 'Waiting for Delivery', value: 'ODSTS03'},
		{label: 'On Delivery', value: 'ODSTS04'},
		{label: 'Receive', value: 'ODSTS05'},
		{label: 'Failed', value: 'ODSTS06'},
		{label: 'Cancel', value: 'ODSTS99'},
	];
	public selectedStatus = '';

	constructor(
		private orderService: OrderService,
		private router: Router
	) { }


	ngOnInit() {
		this.fetchData(1);
		this.fetchCompany();

		this.columns = [
			// {field: 'number', header: 'No', show: true},
			{field: 'id_koperasi', header: 'Id Koperasi', show: true},
			{field: 'id_order', header: 'Id Order', show: false},
			{field: 'billing_number', header: 'Invoice No', show: true},
			{field: 'name', header: 'Nama Anggota', show: true},
			{field: 'name_company', header: 'Perusahaan', show: true},
			{field: 'name_payment_type', header: 'Tipe Pembayaran', show: true},
			{field: 'workflow_status_name', header: 'Status', show: true},
			{field: 'billing_date', header: 'Billing Date', show: true},
			{field: 'product_details', header: 'Product Details', show: true},
			{field: 'total_billing', header: 'Total Tagihan', show: false},

		];
		this.selectedColumns = _.filter(this.columns, {show: true});
    const widthTB = $('#tab-card').width();
    this.widthTable = widthTB;
    $(window).on('resize', () => {
      const widthTB = $('#tab-card').width();
      this.widthTable = widthTB;
    });
	}

	// Toggle Columns
	// ====================== //
	changeColumn(e) {
		const find = _.find(this.columns, e.itemValue);
		if (e.itemValue != undefined) {
			if (e.itemValue.show) {
				find.show = false;
			} else {
				find.show = true;
			}
		} else {

		}
		this.selectedColumns = _.filter(this.columns, {show: true});
	}
	prosesReset(){
	  this.resetData = false;
	  setTimeout(()=>{
	    this.resetData = true;
    },600);
  }
	// Fetching Data
	// ========================= //
	fetchData(page) {
		this.loading = true;

    let {id_company, id_workflow_status, billing_date_start, billing_date_end,id_payment_type} = this;
    let param = {page,id_workflow_status,id_company ,billing_date_start, billing_date_end, row:10, id_payment_type};

		this.orderService.getHistoryOrder(param).subscribe(res => {
		  if(res['data']){

        _.map(res['data']['data'], (x, i) => {
          x['number'] = i + 1;
          x.billing_date = moment(x.billing_date).format('YYYY-MM-DD');
          x.order_detail.map((y) => {
            y.base_price = y.base_price.toLocaleString();
            y.sell_price = y.sell_price.toLocaleString();
          });
        });
        if(parseInt(res['data'].count_filter)==0){

          this.totalCount = 0;
        }else{

          this.totalCount = Number(res['data'].count_all);
        }
        this.data = res['data']['data'];
      }else{
		    this.data=[];
        this.totalCount = 0;
      }
			this.loading = false;

      this.loading_pagination = false;
		}, err => {
			this.loading = false;

      this.loading_pagination = false;
			if (err.status == 401) { this.orderService.updateToken(err.errselectedCompanyor.data.token); }
		});
	}

	paginate(e) {
		this.start = e.page * this.pageLength;
    let page = parseInt(e.page) +1;
    this.fetchData(page);
	}

	selectItem(e) {
		this.idOrder = e.id_order;
		this.itemRes = null;
		this.selectedItem = null;
		this.loadingModal = true;
		this.orderService.getOrderDetail(e.id_order).subscribe(res => {
			this.itemRes = res['data'];
			this.selectedItem = this.itemRes[0];
		},  err => {
			console.log(err);
		});

		console.log(this.itemRes);
		// this.selectedItem = this.itemRes[0];
		this.loadingModal = false;
		this.display = true;
	}

	// Fetch Company
	// ========================= //
	fetchCompany() {
		this.orderService.getCompany().subscribe(res => {

      this.companies = [];
      this.companies = [{label: 'Semua Perusahaan', value: null}];
			_.map(res['data'], (x) => {
				this.companies.push({label: x.name_company, value: x.id_company});
			});
		}, err => {
			console.log(err);
			// this.fetchLoan();
		});
	}

	// Filter List
	// ========================== //
	changeCompany(e) {
		if (e.value) {
			this.id_company = e.value;
			this.selectedCompany = e.value;
		} else {
			delete this.objFilter['company'];
		}
		this.start = 0;
    this.id_company = e.value;
    this.loading_pagination = true;
		this.fetchData(1);
    this.prosesReset();
	}
	changeType(e) {
		if (e.value) {
			this.id_payment_type = e.value;
		} else {
			delete this.objFilter['company'];
		}
		this.start = 0;
    this.id_payment_type = e.value;
    this.loading_pagination = true;
		this.fetchData(1);
	}
	changeStatus(e) {
		if (e.value) {
			// this.objFilter['id_workflow_status'] = e.value;
			this.selectedStatus = e.value;
		} else {
			// delete this.objFilter['id_workflow_status'];
			this.selectedStatus = e.value;
		}
		this.start = 0;
    this.id_workflow_status = e.value;
    this.loading_pagination = true;
		this.fetchData(1);
	}
	onSelectBillingStart(e) {
		if (this.date1[1]) {
      const date1 = moment(this.date1[0]).format('YYYY-MM-DD');
      const date2 = moment(this.date1[1]).format('YYYY-MM-DD');
      this.billing_date_start = date1;
      this.billing_date_end = date2;
      this.loading_pagination = true;
      this.fetchData(1);
		} else {
      this.billing_date_start = '';
      this.billing_date_end = '';
		}
		this.start = 0;

    this.loading_pagination = true;
		this.fetchData(1);
	}

  onClearClick(e) {
      this.billing_date_start = '';
      this.billing_date_end = '';
		this.start = 0;
    this.loading_pagination = true;
		this.fetchData(1);
	}
	onSelectBillingEnd(e) {
		if (this.date2) {
			const date2 = moment(this.date2).format('YYYY-MM-DD');
			this.sendDate2 = date2;
		} else {
			this.date2 = null;
			this.sendDate2 = '';
		}
		this.start = 0;

    this.loading_pagination = true;
		this.fetchData(1);
	}
}
