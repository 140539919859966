import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { APIService } from "../../service/api.service";

@Injectable({
  providedIn: "root",
})
export class MemberService {
  private urlGetApprovalUser =
    this.APIService["hostAuth"] + "/user/approve/list";

  private urlGetGrade = this.APIService["hostAuth"] + "/grade/auth/get";
  private urlGradebyId = this.APIService["hostAuth"] + "/mst/list-grade";
  
  private urlGetReligion = this.APIService["hostAuth"] + "/mst/religion";
  private urlGetStatus = this.APIService["hostAuth"] + "/mst/user/status";
  private urlGetMarriage = this.APIService["hostAuth"] + "/mst/marriage";
  private urlGetDomicile = this.APIService["hostAuth"] + "/mst/domicile";
  private urlGetRole = this.APIService["hostAuth"] + "/mst/role";
  private urlGetReason = this.APIService["hostAuth"] + "/mst/reason";

  private urlGetMstDocument = this.APIService["hostLoan"] + "/master/document";

  private urlGetCompany = this.APIService["hostAuth"] + "/company/auth/get";
  private urlGetCompany2 = this.APIService["hostAuth"] + "/company/get";
  private urlApproveUser = this.APIService["hostAuth"] + "/user/approve";
  private urlRejectUser = this.APIService["hostAuth"] + "/user/reject";
  private urlActiveBE = this.APIService["hostAuth"] + "/user/active_be";
  private urlUpdateBE = this.APIService["hostAuth"] + "/user/update_be";
  private urlDeactiveBE = this.APIService["hostAuth"] + "/user/deactive_be";
  private urlRegisterBE = this.APIService["hostAuth"] + "/user/reg_be";
  private urlPostUser = this.APIService["hostAuth"] + "/reg";
  private urlGetUserList = this.APIService["hostAuth"] + "/user/list";
  private urlGetUserListMutasi = this.APIService["hostAuth"] + "/user/listMutasi";
  private urlGetHistoryListMutasi = this.APIService["hostAuth"] + "/user/historyMutasi";
  private urlGetUserListBE = this.APIService["hostAuth"] + "/user/list_be";
  private urlGetUserDetailBE = this.APIService["hostAuth"] + "/user/detail_be?";
  private urlGetUserDetail = this.APIService["hostAuth"] + "/user/detail";
  private urlGetSalary = this.APIService["hostAuth"] + "/profile/salary";
  private urlUpdateSalary = this.APIService["hostAuth"] + "/salary/update";
  private urlGetBank = this.APIService["hostAuth"] + "/profile/bank";
  private urlProfileSearch = this.APIService["hostAuth"] + "/profile/search";

  private urlGetDocument = this.APIService["hostAuth"] + "/profile/document";
  private urlPostDocument =
    this.APIService["hostAuth"] + "/profile/document/add";
  private urlPutDocument =
  
    this.APIService["hostAuth"] + "/profile/document/update";
  private urlDeleteDocument =
    this.APIService["hostAuth"] + "/profile/document/delete";

  private urlPutMutation = this.APIService["hostAuth"] + "/profile/updateMutation";
  private urlPutKeluar = this.APIService["hostAuth"] + "/profile/updateKeluar";
  private urlPutPassword =
    this.APIService["hostAuth"] + "/profile/change-password";

  private urlGetSalaryApproveList =
    this.APIService["hostAuth"] + "/salary/approve/list";
  private urlApproveSalary = this.APIService["hostAuth"] + "/salary/approve";
  private urlRejectSalary = this.APIService["hostAuth"] + "/salary/reject";



  private urlGetUserResign = this.APIService["hostAuth"] + "/user/list_resign";
  private urlDoResign = this.APIService["hostAuth"] + "/user/do_resign";
  private urlNoResign = this.APIService["hostAuth"] + "/user/no_resign";

  private urlGetUserBlacklist= this.APIService["hostLoanNew"] + "/user/list_blacklist";
  private urlDoBlacklist = this.APIService["hostLoanNew"] + "/user/do_blacklist";
  private urlNoBlacklist = this.APIService["hostLoanNew"] + "/user/no_blacklist";

  constructor(private APIService: APIService, private http: HttpClient) {}

  // Update Token
  // ======================== //
  updateToken(token) {
    localStorage.setItem("token", token);
    location.reload();
  }

  // Convert Image to base64
  // ====================== //
  toDataUrl(url, callback) {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      const reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }

  // Update Mutation
  updateMutation(body) {
    console.log(body);
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    return this.http.post(this.urlPutMutation, body, options);
  }

  // Update Keluar
  updateKeluar(body) {
    console.log(body);
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    return this.http.post(this.urlPutKeluar, body, options);
  }


  getListUserBlacklist(start, rows, param) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };

    let filter = param != undefined ? param : "";
    console.log("filternya",filter);
    // const filter = this.bikinParam(param);

    return this.http.get(this.urlGetUserBlacklist + "?page=" + start + "&size=" + rows + filter, options);
  }

  doBlacklist(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    const body = obj;
    return this.http.post(this.urlDoBlacklist, body, options);
  }

  noBlacklist(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    const body = obj;
    return this.http.post(this.urlNoBlacklist, body, options);
  }

  // User Sallary
  // ====================== //
  getSallary(id) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    return this.http.get(this.urlGetSalary + "?id=" + id, options);
  }

  getGradebyId(id) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    return this.http.get(this.urlGradebyId + "?id_company=" + id, options);
  }

  getDownloaReport(param) {
    let filter = param != undefined ? param : "";

    return 'https://u.koperasi-astra.com/download-file-blacklist?' + filter;
  }

  getDownloaReportMutasi(param) {
    let filter = param != undefined ? param : "";

    return 'https://u.koperasi-astra.com/download-file-mutasi?' + filter;
  }

  getDownloaReportHistoryMutasi(param) {
    let filter = param != undefined ? param : "";

    return 'https://u.koperasi-astra.com/download-history-mutasi?' + filter;
  }
  // User Bank
  // ====================== //
  getBank(id) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    return this.http.get(this.urlGetBank + "?id=" + id, options);
  }

  // User Search
  // ====================== //
  searchProfile(search) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    return this.http.post(
      this.urlProfileSearch + "?param=" + search,
      {},
      options
    );
  }

  // Update Password
  // ====================== //
  updatePassword(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    const body = obj;
    return this.http.post(this.urlPutPassword, body, options);
  }

  // User Document
  // ====================== //
  getDocument(id) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    return this.http.get(this.urlGetDocument + "?id=" + id, options);
  }
  postDocument(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    const body = obj;
    return this.http.post(this.urlPostDocument, body, options);
  }
  updateDocument(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    const body = obj;
    return this.http.post(this.urlPutDocument, body, options);
  }

  deleteDocument(id_user, id_user_doc) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    const body = {
      id: id_user.toString(),
      id_user_document: id_user_doc.toString(),
    };
    return this.http.post(this.urlDeleteDocument, body, options);
  }

  // Get User Detail
  // ====================== //
  getUserDetail(id) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    return this.http.get(this.urlGetUserDetail + "?id=" + id, options);
  }

  // Register
  // ====================== //
  postUser(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
      }),
    };
    const body = obj;
    return this.http.post(this.urlPostUser, body, options);
  }

  // Get Approval User
  // ====================== //
  getAprrovalUser(start, length, objFilter) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    const filter = escape(JSON.stringify(objFilter));
    return this.http.get(
      this.urlGetApprovalUser +
        "?start=" +
        start +
        "&length=" +
        length +
        "&sort=d.created_at,desc&manual_filter=" +
        filter,
      options
  );
  }

  // Get List User
  // ====================== //
  getListUser(start, length, objFilter) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    const filter = escape(JSON.stringify(objFilter));
    return this.http.get(
      this.urlGetUserListMutasi +
        "?start=" +
        start +
        "&length=" +
        length +
        "&sort=name,asc&manual_filter=" +
        filter,
      options
    );
  }

   // Get List User
  // ====================== //
  getListUserMutasi(start, length, objFilter) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    const filter = escape(JSON.stringify(objFilter));
    return this.http.get(
      this.urlGetUserListMutasi +
        "?start=" +
        start +
        "&length=" +
        length +
        "&sort=name,asc&manual_filter=" +
        filter,
      options
    );
  }


     // Get List User
  // ====================== //
  getListHistoryMutasi(start, length, objFilter) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    const filter = escape(JSON.stringify(objFilter));
    return this.http.get(
      this.urlGetHistoryListMutasi +
        "?start=" +
        start +
        "&length=" +
        length +
        "&sort=name,asc&manual_filter=" +
        filter,
      options
    );
  }

  
  // Get List User
  // ====================== //
  getListUserBE(start, length, objFilter) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    const filter = escape(JSON.stringify(objFilter));
    return this.http.get(
      this.urlGetUserListBE +
        "?start=" +
        start +
        "&length=" +
        length +
        "&sort=name,asc&manual_filter=" +
        filter,
      options
    );
  }
 // Get List User Resign
  // ====================== //
  getListUserResign(start, length, objFilter) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    const filter = escape(JSON.stringify(objFilter));
    return this.http.get(
      this.urlGetUserResign +
        "?start=" +
        start +
        "&length=" +
        length +
        "&sort=name,asc&manual_filter=" +
        filter,
      options
    );
  }

  doResign(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    const body = obj;
    return this.http.post(this.urlDoResign, body, options);
  }

  noResign(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    const body = obj;
    return this.http.post(this.urlNoResign, body, options);
  }
  
  getDetailUserBe(id) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    return this.http.get(this.urlGetUserDetailBE + "id=" + id, options);
  }

  activeUserBe(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    const body = obj;
    return this.http.post(this.urlActiveBE, body, options);
  }

  deactiveUserBe(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    const body = obj;
    return this.http.post(this.urlDeactiveBE, body, options);
  }
  registerUserBE(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    const body = obj;
    return this.http.post(this.urlRegisterBE, body, options);
  }
  updateUserBE(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    const body = obj;
    return this.http.post(this.urlUpdateBE, body, options);
  }
  // Get List User
  // ====================== //
  getListSalaryApprove(start, length, objFilter) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    const filter = escape(JSON.stringify(objFilter));
    return this.http.get(
      this.urlGetSalaryApproveList +
        "?start=" +
        start +
        "&length=" +
        length +
        "&sort=name,asc&manual_filter=" +
        filter,
      options
    );
  }

  // Approve Salary
  // ====================== //
  approveSalary(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    const body = obj;
    return this.http.post(this.urlApproveSalary, body, options);
  }
  updateDirectSalary(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    const body = obj;
    return this.http.post(this.urlUpdateSalary, body, options);
  }
  rejectSalary(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    const body = obj;
    return this.http.post(this.urlRejectSalary, body, options);
  }
  // Approve User
  // ====================== //
  putApproveUser(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    const body = obj;
    return this.http.post(this.urlApproveUser, body, options);
  }

  // Reject User
  // ====================== //
  putRejectUser(obj) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    const body = obj;
    return this.http.post(this.urlRejectUser, body, options);
  }

  // Get Master
  // ====================== //
  getGrade() {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    return this.http.get(this.urlGetGrade, options);
  }
  getStatus() {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    return this.http.get(this.urlGetStatus, options);
  }
  getCompany() {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    return this.http.get(this.urlGetCompany, options);
  }
  getCompany2() {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    return this.http.get(this.urlGetCompany2, options);
  }
  getReligion() {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    return this.http.get(this.urlGetReligion, options);
  }
  getDomicile() {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    return this.http.get(this.urlGetDomicile, options);
  }
  getMarriage() {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    return this.http.get(this.urlGetMarriage, options);
  }
  getRole() {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    return this.http.get(this.urlGetRole, options);
  }
  getMstDocument() {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    return this.http.get(this.urlGetMstDocument, options);
  }
  getReason() {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        Authorization: localStorage.getItem("token"),
      }),
    };
    return this.http.get(this.urlGetReason, options);
  }
}
