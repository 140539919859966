import { Component, OnInit } from '@angular/core';
import { MemberService } from '../../member/member.service';
import { ReportService } from '../report.service';
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from 'primeng/components/common/messageservice';
import * as _ from 'lodash';
import * as moment from 'moment';
import { store } from '../../../service/store.service';
declare var $: any;

@Component({
  selector: 'app-order-biller-detail',
  templateUrl: './order-biller-detail.component.html',
  styleUrls: ['./order-biller-detail.component.scss'],
  providers: [MessageService]
})
export class OrderBillerDetailComponent implements OnInit {
  public data: any = [];
  public columns: any = [];
  public display = false;
  public selectedColumns: any[];
  public loading: boolean;
  public selectedItem = null;
  public grades = [];
  public grades2 = [];
  public selectedGrade = null;
  public companies = [];
  public originGrades = [];
  public arrStatus = [];
  public selectedStatus = null;
  public date: Date = null;
  public date1: Date = null;
  public date2: Date = null;
  public minDate = moment().add('days', -1)['_d'];
  public isSubmitApprove = false;
  public isSubmitReject = false;
  public totalCount = 0;
  public start = 0;
  public widthTable = 0;
  public pageLength = 10;
  public availabelColumn: Number;
  public roleId;
  public id_user;
  public widthDisplay: number;
  private objFilter = {};

  public displayForm = false;
  public isSubmitRegis = false;

  public name;
  public company;
  public email;
  public identity_photo;
  public company_identity_photo;
  public personal_photo;
  public phone_number;

  constructor(

    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private memberService: MemberService,
    private reportService: ReportService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id_user = params['id'];
    });
    this.fetchGrade();
    this.fetchCompany();
    this.fetchStatus();
    this.fetchReport();
    this.roleId = localStorage.getItem('id_role_master');
    this.widthDisplay = $(window).width() - 60;

    this.columns = [
      {field: 'id_koperasi', header: 'No Anggota', show: true},
      {field: 'name', header: 'Nama', show: true},
      {field: 'billing_date', header: 'Tanggal Trx', show: true},
      {field: 'id_employee', header: 'NPK', show: true},
      {field: 'name_company', header: 'AFFCO', show: true},
      {field: 'name_grade', header: 'Golongan', show: false},
      {field: 'name_payment_type', header: 'Jenis Trx', show: true},
      {field: 'product_name_biller_name', header: 'Tipe Trx', show: true},
      {field: 'sell_price', header: 'Total Trx', show: true}

    ];
    this.availabelColumn = _.filter(this.columns, {show: true}).length + 1;
    this.selectedColumns = _.filter(this.columns, {show: true});
    const widthTB = $('#tab-card').width();
    this.widthTable = widthTB;
    $(window).on('resize', function() {
      const widthTB = $('#tab-card').width();
      this.widthTable = widthTB;
    });
  }

  // Save
  // ========================= //
  save(e) {
    this.isSubmitRegis = true;
    e['identity_photo'] = this.imageID.imageSrc;
    e['company_identity_photo'] = this.imageKTP.imageSrc;
    e['personal_photo'] = this.imagePersonal.imageSrc;
    e['role'] = 'ROLE001';

    this.memberService.postUser(e).subscribe(res => {
      this.isSubmitRegis = false;
      this.displayForm = false;
      this.fetchReport();
      this.messageService.add({severity: 'success', summary: 'Success', detail: 'User has been added'});
    }, err => {
      this.isSubmitRegis = false;
      this.messageService.add({severity: 'error', summary: 'Error', detail: 'Please try again'});
    });
  }

  // Toggle Columns
  // ====================== //
  changeColumn(e) {
    const find = _.find(this.columns, e.itemValue);
    if (e.itemValue != undefined) {
      if (e.itemValue.show) {
        find.show = false;
      } else {
        find.show = true;
      }
    }
    this.availabelColumn = _.filter(this.columns, {show: true}).length + 1;
    this.selectedColumns = _.filter(this.columns, {show: true});
  }

  // Fetching User
  // ========================= //
  fetchReport() {
    this.loading = true;
    this.reportService.getOrderBillerDetailParam(this.start, this.pageLength, this.id_user).subscribe(res => {
      _.map(res['data'].data, (x, i) => {
        x['number'] = i + 1;
        x.billing_date = x.billing_date ? moment(x.billing_date).format('DD MMM YYYY HH:mm:ss') : '-';
        x.product_name_biller_name = x.product_name + ' ' + x.biller_name;
        x.sell_price = 'Rp ' + parseInt(x.sell_price).toLocaleString();

        // x.employee_starting_date = x.employee_starting_date ? moment(x.employee_starting_date).format('YYYY-MM-DD') : '-';
        x['show_input'] = true;

        // switch (this.roleId) {
        //   case 'ROLE003' :
        //     if (x.id_workflow_status == 'MBRSTS02' || x.id_workflow_status == 'MBRSTS03' || x.id_workflow_status == 'MBRSTS07'  || x.id_workflow_status == 'MBRSTS08') {
        //       x['show_input'] = false;
        //     }
        //     break;
        //   case 'ROLE002' :
        //     if (x.id_workflow_status == 'MBRSTS00' || x.id_workflow_status == 'MBRSTS01' || x.id_workflow_status == 'MBRSTS03' || x.id_workflow_status == 'MBRSTS07' || x.id_workflow_status == 'MBRSTS08') {
        //       x['show_input'] = false;
        //     }
        //     break;
        // }
      });
      this.data = res['data'].data;
      this.totalCount = Number(res['data'].count_filter);
      // store.dispatch({ type: 'COUNTER', jmlCalongAnggota: Number(res['data'].count_all) });
      this.loading = false;

    }, err => {
      // this.fetchReport();
      this.loading = false;
      if (err.status == 401) { this.memberService.updateToken(err.error.data.token); }
    });
  }
  paginate(e) {
    this.start = e.page * this.pageLength;
    this.fetchReport();
  }

  // Select Item / User
  // ======================== //
  selectItem(e) {
    this.display = true;
    this.selectedItem = e;
    console.log(e);
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 1000);

    $('img').on('load', function() {
      window.dispatchEvent(new Event('resize'));
    });
  }

  // Fetch Grade
  // ========================= //
  fetchGrade() {
    this.memberService.getGrade().subscribe(res => {
      this.originGrades = res['data'];
      this.grades = [{label: 'Semua Golongan', value: null}];
      this.grades2 = [];
      _.map(res['data'], (x) => {
        const obj = {label: x.name_grade, value: x.id_grade};
        this.grades.push(obj);
        this.grades2.push(obj);
      });
    }, err => {
      if (err.status == 401) { this.memberService.updateToken(err.error.data.token); }
    });
  }

  // Fetch Status
  // ========================= //
  fetchStatus() {
    this.memberService.getStatus().subscribe(res => {
      this.arrStatus = [{label: 'Semua Status', value: null}];
      _.map(res['data'], (x) => {
        const obj = {label: x.workflow_status_name, value: x.id_workflow_status};
        this.arrStatus.push(obj);
      });

      _.map(this.data, (x) => {
        x['status_name'] = _.find(this.arrStatus, {value: x.id_workflow_status}).label;
      });
    }, err => {
      this.fetchStatus();
    });
  }

  // Fetch Company
  // ========================= //
  fetchCompany() {
    this.memberService.getCompany().subscribe(res => {
      this.companies = [{label: 'Semua Perusahaan', value: null}];
      _.map(res['data'], (x) => {
        this.companies.push({label: x.name_company, value: x.id_company});
      });
    }, err => {
      this.fetchCompany();
    });
  }


  // Add New Member
  // ========================= //
  openDialogForm() {
    this.displayForm = true;
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 500);
  }

  public imageKTP: any = {};
  public imageID: any = {};
  public imagePersonal: any = {};
  private uploadType: string;
  handleInputChange(e, type) {
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    const pattern = /image-*/;
    const reader = new FileReader();
    if (file != undefined) {
      if (!file.type.match(pattern)) {
        alert('invalid format');
        return;
      }

      this.uploadType = type;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);

    switch (this.uploadType) {
      case 'ktp' : this.imageKTP['imageName'] = file.name; break;
      case 'id' : this.imageID['imageName'] = file.name; break;
      case 'personal' : this.imagePersonal['imageName'] = file.name; break;
    }
  }
  _handleReaderLoaded(e) {
    const reader = e.target;
    switch (this.uploadType) {
      case 'ktp' : this.imageKTP['imageSrc'] = reader.result; break;
      case 'id' : this.imageID['imageSrc'] = reader.result; break;
      case 'personal' : this.imagePersonal['imageSrc'] = reader.result; break;
    }

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 500);
  }

  // Filter List
  // ========================== //
  private typingTimer;
  private doneTypingInterval = 1000;
  onSearchName(searchValue: string ) {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      if (searchValue) {
        this.objFilter['nama'] = searchValue;
      } else {
        delete this.objFilter['nama'];
      }
      this.start = 0;
      this.fetchReport();
    }, this.doneTypingInterval);
  }
  onSearchNik(searchValue: string ) {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      if (searchValue) {
        this.objFilter['no_anggota'] = searchValue;
      } else {
        delete this.objFilter['no_anggota'];
      }
      this.start = 0;
      this.fetchReport();
    }, this.doneTypingInterval);
  }
  changeCompany(e) {
    if (e.value) {
      this.objFilter['company'] = e.value;
    } else {
      delete this.objFilter['company'];
    }
    this.start = 0;
    this.fetchReport();
  }
  changeGrade(e) {
    if (e.value) {
      this.objFilter['golongan'] = e.value;
    } else {
      delete this.objFilter['golongan'];
    }
    this.start = 0;
    this.fetchReport();
  }
  changeStatus(e) {
    if (e.value) {
      this.objFilter['status'] = e.value;
    } else {
      delete this.objFilter['status'];
    }
    this.start = 0;
    this.fetchReport();
  }
  onSelectTglMasuk(e) {
    if (this.date1[1]) {
      const date1 = moment(this.date1[0]).format('YYYY-MM-DD');
      const date2 = moment(this.date1[1]).format('YYYY-MM-DD');
      this.objFilter['tgl_masuk'] = '2019-02-01 - 2019-02-15';
    } else {
      delete this.objFilter['tgl_masuk'];
    }
    this.start = 0;
    this.fetchReport();
  }
  onSelectTglPengajuan(e) {
    if (this.date2[1]) {
      const date1 = moment(this.date2[0]).format('YYYY-MM-DD');
      const date2 = moment(this.date2[1]).format('YYYY-MM-DD');
      this.objFilter['tgl_pengajuan'] = '2019-02-01 - 2019-02-15';
    } else {
      delete this.objFilter['tgl_pengajuan'];
    }
    this.start = 0;
    this.fetchReport();
  }


}
