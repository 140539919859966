import { Injectable } from '@angular/core';

import { HttpClient,HttpHeaders } from "@angular/common/http";
import { APIService } from "../../service/api.service";

@Injectable({
  providedIn: 'root'
})

export class TicketsService {

  private urlGetReportFlight = this.APIService['hostFlight'] + '/flight/flight/order/report';
  private urlGetOrderDetail = this.APIService['hostLoan'] + '/order/backend/dashboard/detail';
  private urlGetOrderHistory = this.APIService['hostLoan'] + '/order/backend/history';
  private urlGetReportHotel = this.APIService['hostHotel'] + '/api/hotel/list-order';
  constructor(
    private APIService: APIService,
    private http: HttpClient
  ){}
  bikinParam (param ) {
    let str = '';
    for (const key in param) {
      if (param[key] != '' && param[key] != undefined) {
        if (str != '') {
          str += '&';
        }
        str += key + '=' + encodeURIComponent(param[key]);
      }
    }
    return str;
  }
  // Update Token
  // ======================== //
  updateToken(token){
    localStorage.setItem("token", token);
    location.reload();
  }

  // History Order
  // ====================== //
  getHistoryOrder(param){
    const options = {
      headers: new HttpHeaders({
        'accept':  'application/json',
        'Authorization' : localStorage.getItem('token')
      })
    };
    // let filter = escape(JSON.stringify(objFilter));

    const filter = this.bikinParam(param);
    // return this.http.get(this.urlGetLoan + '?start=' + start +  '&length=' + length + '&sort=request_date,desc&manual_filter=' + filter, options);
    return this.http.get(this.urlGetOrderHistory  + '?' + filter, options);
    // return this.http.get(this.APIService.hostLoan + '/order/history'  + '?start=' + start +  '&length='+ length + '&id_workflow_status=' + status + '&billing_date_start=' + bill_start + '&billing_date_end=' + bill_end,options);
  }

  // History Order Detail
  // ====================== //
  getOrderDetail(idOrder){
    const options = {
      headers: new HttpHeaders({
        'accept':  'application/json',
        'Authorization' : localStorage.getItem('token')
      })
    };
    // let filter = escape(JSON.stringify(objFilter));
    return this.http.get(this.urlGetOrderDetail  + '?id_order=' + idOrder ,options);
  }

  // Get Master
  // ====================== //
  getFlightReport(param){
    const options = {
      headers: new HttpHeaders({
        'accept':  'application/json',
        'Authorization' : localStorage.getItem('token')
      })
    };
    const filter = this.bikinParam(param);
    // return this.http.get(this.urlGetLoan + '?start=' + start +  '&length=' + length + '&sort=request_date,desc&manual_filter=' + filter, options);
    return this.http.get(this.urlGetReportFlight + '?' + filter, options);
  }
  // ====================== //
  getHotelReport(param){
    const options = {
      headers: new HttpHeaders({
        'accept':  'application/json',
        'Authorization' : localStorage.getItem('token')
      })
    };
      // ?start=0&length=10&sort=a.hotel_order_id,desc
    const filter = this.bikinParam(param);
    // return this.http.get(this.urlGetLoan + '?start=' + start +  '&length=' + length + '&sort=request_date,desc&manual_filter=' + filter, options);
    return this.http.get(this.urlGetReportHotel + '?' + filter, options);
  }
}
