import { Component, OnInit } from "@angular/core";
import { MemberService } from "../member.service";

import { MessageService } from "primeng/components/common/messageservice";
import * as _ from "lodash";
import * as moment from "moment";
import { store } from "../../../service/store.service";
declare var $: any;

@Component({
  selector: "app-approval-member",
  templateUrl: "./approval-member.component.html",
  styleUrls: ["./approval-member.component.scss"],
  providers: [MessageService],
})
export class ApprovalMemberComponent implements OnInit {
  public data: any = [];
  public columns: any = [];
  public display: boolean = false;
  public selectedColumns: any[];
  public loading: boolean;
  public selectedItem = null;
  public grades = [];
  public grades2 = [];
  public reasons = [];
  public reasons2 = [];
  public selectedGrade = null;
  public selectedReason = null;
  public detailreject = null;
  public companies = [];

  public widthTable = 0;
  public originGrades = [];
  public originReasons = [];
  public arrStatus = [];
  public selectedStatus = null;
  public date: Date = null;
  public date1: Date = null;
  public date2: Date = null;
  public minDate = moment().add("days", -1)["_d"];
  public isSubmitApprove: boolean = false;
  public isSubmitReject: boolean = false;
  public totalCount: number = 0;
  public start = 0;
  public pageLength = 10;
  public availabelColumn: Number;
  public roleId;
  public widthDisplay: number;
  private objFilter = {};

  public displayForm: boolean = false;
  public isSubmitRegis: boolean = false;

  public name;
  public company;
  public email;
  public identity_photo;
  public company_identity_photo;
  public personal_photo;
  public phone_number;

  constructor(
    private messageService: MessageService,
    private memberService: MemberService
  ) {}

  ngOnInit() {
    this.fetchUser();
    this.fetchGrade();
    this.fetchCompany();
    this.fetchReason();
    // this.fetchStatus();
    this.roleId = localStorage.getItem("id_role_master");
    this.widthDisplay = $(window).width() - 60;

    this.columns = [
      { field: "number", header: "No", show: true },
      { field: "name", header: "Nama", show: true },
      { field: "name_company", header: "Perusahaan", show: true },
      { field: "id_employee", header: "NRP/NPK", show: true },
      // { field: "email", header: "Email", show: false },
      // { field: "phone_number", header: "No Telpon", show: false },
      // {
      //   field: "employee_starting_date_format",
      //   header: "Tgl Karyawan Tetap",
      //   show: true,
      // },
      { field: "date_become_member_format", header: "Tgl Kartap", show: true },
      { field: "requested_date", header: "Tgl Pendaftaran", show: true },
      { field: "name_grade", header: "Golongan", show: true },
      { field: "status_name", header: "Status", show: true },
      { field: "reject_reason", header: "Alasan Reject", show: true },
      { field: "detail_reject_reason", header: "Detail Alasan Reject", show: true },
    ];
    this.availabelColumn = _.filter(this.columns, { show: true }).length + 1;
    this.selectedColumns = _.filter(this.columns, { show: true });
    const widthTB = $("#tab-card").width();
    this.widthTable = widthTB;
    $(window).on("resize", () => {
      const widthTB = $("#tab-card").width();
      this.widthTable = widthTB;
    });
  }

  // Save
  // ========================= //
  save(e) {
    this.isSubmitRegis = true;
    e["identity_photo"] = this.imageID.imageSrc;
    e["company_identity_photo"] = this.imageKTP.imageSrc;
    e["personal_photo"] = this.imagePersonal.imageSrc;
    e["role"] = "ROLE001";

    this.memberService.postUser(e).subscribe(
      (res) => {
        this.isSubmitRegis = false;
        this.displayForm = false;
        this.fetchUser();
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "User has been added",
        });
      },
      (err) => {
        this.isSubmitRegis = false;
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Please try again",
        });
      }
    );
  }

  // Toggle Columns
  // ====================== //
  changeColumn(e) {
    let find = _.find(this.columns, e.itemValue);
    if (e.itemValue != undefined) {
      if (e.itemValue.show) {
        find.show = false;
      } else {
        find.show = true;
      }
    }
    this.availabelColumn = _.filter(this.columns, { show: true }).length + 1;
    this.selectedColumns = _.filter(this.columns, { show: true });
  }

  // Fetching User
  // ========================= //
  fetchUser() {
    this.loading = true;
    this.memberService
      .getAprrovalUser(this.start, this.pageLength, this.objFilter)
      .subscribe(
        (res) => {
          _.map(res["data"].data, (x, i) => {
            x["number"] = i + 1;
            x.requested_date = moment(x.requested_date).format("YYYY-MM-DD");
            x.employee_starting_date_format = x.employee_starting_date
              ? moment(x.employee_starting_date).format("YYYY-MM-DD")
              : "-";
            x.date_become_member_format = x.date_become_member
              ? moment(x.date_become_member).format("YYYY-MM-DD")
              : "-";
            x["show_input"] = true;

            switch (this.roleId) {
              case "ROLE003":
                if (
                  x.id_workflow_status == "MBRSTS02" ||
                  x.id_workflow_status == "MBRSTS03" ||
                  x.id_workflow_status == "MBRSTS07" ||
                  x.id_workflow_status == "MBRSTS08"
                ) {
                  x["show_input"] = false;
                }
                break;
              case "ROLE007":
                if (
                  x.id_workflow_status == "MBRSTS02" ||
                  x.id_workflow_status == "MBRSTS03" ||
                  x.id_workflow_status == "MBRSTS07" ||
                  x.id_workflow_status == "MBRSTS08"
                ) {
                  x["show_input"] = false;
                }
                break;
              case "ROLE002":
                if (
                  x.id_workflow_status == "MBRSTS00" ||
                  x.id_workflow_status == "MBRSTS01" ||
                  x.id_workflow_status == "MBRSTS03" ||
                  x.id_workflow_status == "MBRSTS07" ||
                  x.id_workflow_status == "MBRSTS08"
                ) {
                  x["show_input"] = false;
                }
                break;
            }
          });
          this.data = res["data"].data;
          this.fetchStatus();
          this.totalCount = Number(res["data"].count_filter);
          store.dispatch({
            type: "COUNTER",
            jmlCalongAnggota: Number(res["data"].count_all),
          });
          this.loading = false;
        },
        (err) => {
          // this.fetchUser();
          this.loading = false;
          if (err.status == 401)
            this.memberService.updateToken(err.error.data.token);
        }
      );
  }
  paginate(e) {
    this.start = e.page * this.pageLength;
    this.fetchUser();
  }

  // Select Item / User
  // ======================== //
  selectItem(e) {
    this.display = true;
    this.selectedItem = e;
    this.date = e["employee_starting_date"];
    this.grades2 = this.grades.filter((z) => z.id_company == e.id_company);
    // console.log(this.grades);
    let tmp = this.grades2.filter((z) => {
      return z.label == e["name_grade"];
    });
    if (tmp.length > 0) this.selectedGrade = tmp[0].value;
    // console.log(e);
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 1000);

    $("img").on("load", function () {
      window.dispatchEvent(new Event("resize"));
    });
  }

  // Fetch Grade
  // ========================= //
  fetchGrade() {
    this.memberService.getGrade().subscribe(
      (res) => {
        this.originGrades = res["data"];
        this.grades = [{ label: "Semua Golongan", value: null }];
        this.grades2 = [];
        _.map(res["data"], (x) => {
          let obj = {
            label: x.name_grade,
            value: x.id_grade,
            id_company: x.id_company,
          };
          this.grades.push(obj);
          this.grades2.push(obj);
        });
        // console.log('this.grades2',this.grades2);
      },
      (err) => {
        if (err.status == 401)
          this.memberService.updateToken(err.error.data.token);
      }
    );
  }

  
  // Fetch Reason
  // ========================= //
  fetchReason() {
    this.memberService.getReason().subscribe(
      (res) => {
        this.originReasons = res["data"];
        this.reasons = [{ label: "Semua Alasan", value: null }];
        this.reasons2 = [];
        _.map(res["data"], (x) => {
          let obj = {
            label: x.name,
            value: x.name,
            id_opusb: x.id_opusb,
          };
          this.reasons.push(obj);
          this.reasons2.push(obj);
        });
        // console.log('this.reasons2',this.reasons2);
      },
      (err) => {
        if (err.status == 401)
          this.memberService.updateToken(err.error.data.token);
      }
    );
  }

  // Fetch Status
  // ========================= //
  fetchStatus() {
    if (this.arrStatus.length == 0) {
      this.memberService.getStatus().subscribe(
        (res) => {
          this.arrStatus = [{ label: "Semua Status", value: null }];
          
          _.map(res["data"], (x) => {
            console.log(x.workflow_status_name);
            if(x.workflow_status_name =='OTP Submitted'){
              x.workflow_status_name ='Menunggu Persetujuan HR';
            }else if(x.workflow_status_name =='Approved by HR'){
              x.workflow_status_name ='Menunggu Persetujuan Koperasi Astra';
            }else if(x.workflow_status_name =='Reject'){
              x.workflow_status_name ='Pendaftaran Tidak Disetujui';
            }
            
            let obj = {
              label: x.workflow_status_name,
              value: x.id_workflow_status,
            };
           
            this.arrStatus.push(obj);


          });
          this.data.forEach((x) => {


            if(_.find(this.arrStatus, {value: x.id_workflow_status,}).label =='Reject'){
              
              x["status_name"] ='Pendaftaran Tidak Disetujui';

            }else if(_.find(this.arrStatus, {value: x.id_workflow_status,}).label =='Waiting for OTP'){
              
              x["status_name"] ='Menunggu Kode OTP';

            }else if(_.find(this.arrStatus, {value: x.id_workflow_status,}).label =='OTP Submitted'){
              
              x["status_name"] ='Menunggu Persetujuan HR';

            }else if(_.find(this.arrStatus, {value: x.id_workflow_status,}).label =='Approved by HR'){
              
              x["status_name"] ='Menunggu Persetujuan Koperasi Astra';

            }else if(_.find(this.arrStatus, {value: x.id_workflow_status,}).label =='Approved by Kop Admin Keanggotaan'){
              
              x["status_name"] ='Disetujui oleh Koperasi Astra';

            }else if(_.find(this.arrStatus, {value: x.id_workflow_status,}).label =='Non Active'){
              
              x["status_name"] ='Tidak Aktif';

            }else if(_.find(this.arrStatus, {value: x.id_workflow_status,}).label =='Active'){
              
              x["status_name"] ='Aktif';

            }else{
              x["status_name"] =_.find(this.arrStatus, {value: x.id_workflow_status,}).label;
            }
            
          });
          // _.map(this.data, (x)=>{
          //   x['status_name'] = _.find(this.arrStatus, {value: x.id_workflow_status}).label;
          // });
        },
        (err) => {
          // this.fetchStatus();
        }
      );
    } else {
      this.data.forEach((x) => {
        
        if(_.find(this.arrStatus, {value: x.id_workflow_status,}).label =='Reject'){
              
          x["status_name"] ='Tidak Disetujui';

        }else if(_.find(this.arrStatus, {value: x.id_workflow_status,}).label =='Waiting for OTP'){
          
          x["status_name"] ='Menunggu OTP';

        }else if(_.find(this.arrStatus, {value: x.id_workflow_status,}).label =='OTP Submitted'){
          
          x["status_name"] ='Menunggu Persetujuan HR';

        }else if(_.find(this.arrStatus, {value: x.id_workflow_status,}).label =='Approved by HR'){
          
          x["status_name"] ='Disetujui oleh HR';

        }else if(_.find(this.arrStatus, {value: x.id_workflow_status,}).label =='Approved by Kop Admin Keanggotaan'){
          
          x["status_name"] ='Disetujui oleh Koperasi Astra';

        }else if(_.find(this.arrStatus, {value: x.id_workflow_status,}).label =='Non Active'){
          
          x["status_name"] ='Tidak Aktif';

        }else if(_.find(this.arrStatus, {value: x.id_workflow_status,}).label =='Active'){
          
          x["status_name"] ='Aktif';

        }else{
          x["status_name"] =_.find(this.arrStatus, {value: x.id_workflow_status,}).label;
        }



      });
    }
  }

  // Fetch Company
  // ========================= //
  fetchCompany() {
    this.memberService.getCompany().subscribe(
      (res) => {
        this.companies = [{ label: "Semua Perusahaan", value: null }];
        _.map(res["data"], (x) => {
          this.companies.push({ label: x.name_company, value: x.id_company });
        });
      },
      (err) => {
        // this.fetchCompany();
      }
    );
  }

  // Approve User
  // ========================= //
  approve() {
    this.isSubmitApprove = true;
    let body = {
      id: Number(this.selectedItem["id_user"]),
      grade: this.selectedGrade,
      date_in: moment(this.date).format("YYYY-MM-DD hh:mm:ss"),
    };
    this.memberService.putApproveUser(body).subscribe(
      (res) => {
        this.display = false;
        this.isSubmitApprove = false;
        this.fetchUser();
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "User has been approved",
        });
      },
      (err) => {
        // console.log('putApproveUser Error => ', err.error.text); 
        this.isSubmitApprove = false;
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: err.error.message,
        });
      }
    );
  }

  // Reject User
  // ========================= //
  reject() {
    this.isSubmitReject = true;
    this.memberService
      .putRejectUser({ id: Number(this.selectedItem["id_user"]),
      reason: this.selectedReason,
      detailreason: this.detailreject,
    })
      .subscribe(
        (res) => {
          console.log(res);
          this.display = false;
          this.isSubmitReject = false;
          this.fetchUser();
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "User berhasil di reject",
          });
        },
        (err) => {
          this.isSubmitReject = false;
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: "Please try again",
          });
        }
      );
  }

  // Add New Member
  // ========================= //
  openDialogForm() {
    this.displayForm = true;
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 500);
  }

  public imageKTP: any = {};
  public imageID: any = {};
  public imagePersonal: any = {};
  private uploadType: string;
  handleInputChange(e, type) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (file != undefined) {
      if (!file.type.match(pattern)) {
        alert("invalid format");
        return;
      }

      this.uploadType = type;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);

    switch (this.uploadType) {
      case "ktp":
        this.imageKTP["imageName"] = file.name;
        break;
      case "id":
        this.imageID["imageName"] = file.name;
        break;
      case "personal":
        this.imagePersonal["imageName"] = file.name;
        break;
    }
  }
  _handleReaderLoaded(e) {
    let reader = e.target;
    switch (this.uploadType) {
      case "ktp":
        this.imageKTP["imageSrc"] = reader.result;
        break;
      case "id":
        this.imageID["imageSrc"] = reader.result;
        break;
      case "personal":
        this.imagePersonal["imageSrc"] = reader.result;
        break;
    }

    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 500);
  }

  // Filter List
  // ========================== //
  private typingTimer;
  private doneTypingInterval = 1000;
  onSearchName(searchValue: string) {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      if (searchValue) {
        this.objFilter["nama"] = searchValue;
      } else {
        delete this.objFilter["nama"];
      }
      this.start = 0;
      this.fetchUser();
    }, this.doneTypingInterval);
  }
  onSearchNik(searchValue: string) {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      if (searchValue) {
        this.objFilter["no_anggota"] = searchValue;
      } else {
        delete this.objFilter["no_anggota"];
      }
      this.start = 0;
      this.fetchUser();
    }, this.doneTypingInterval);
  }
  changeCompany(e) {
    console.log(e);
    if (e.value) {
      this.objFilter["company"] = e.value;
    } else {
      delete this.objFilter["company"];
    }
    this.start = 0;
    this.fetchUser();
  }
  changeGrade(e) {
    if (e.value) {
      this.objFilter["golongan"] = e.value;
    } else {
      delete this.objFilter["golongan"];
    }
    this.start = 0;
    this.fetchUser();
  }
  changeStatus(e) {
    if (e.value) {
      this.objFilter["status"] = e.value;
    } else {
      delete this.objFilter["status"];
    }
    this.start = 0;
    this.fetchUser();
  }
  onSelectTglMasuk(e) {
    console.log("cek");
    this.date2 = null;
    if (this.date1) {
      if (this.date1[1]) {
        let date1 = moment(this.date1[0]).format("YYYY-MM-DD");
        let date2 = moment(this.date1[1]).format("YYYY-MM-DD");
        this.objFilter["tgl_masuk"] = `${date1} - ${date2}`;
      } else {
        delete this.objFilter["tgl_masuk"];
      }
      delete this.objFilter["tgl_pengajuan"];
      this.start = 0;
      this.fetchUser();
    } else {
      delete this.objFilter["tgl_masuk"];
      delete this.objFilter["tgl_pengajuan"];
      this.start = 0;
      this.fetchUser();
    }
  }
  onClearClick(e) {
    delete this.objFilter["tgl_masuk"];
    delete this.objFilter["tgl_pengajuan"];
    this.start = 0;
    this.fetchUser();
  }
  onSelectTglPengajuan(e) {
    this.date1 = null;
    if (this.date2) {
      if (this.date2[1]) {
        let date1 = moment(this.date2[0]).format("YYYY-MM-DD");
        let date2 = moment(this.date2[1]).add(1, 'days');
        let date3 =  moment(date2).format("YYYY-MM-DD");
        this.objFilter["tgl_pengajuan"] = `${date1} - ${date3}`;
        console.log(date3);
      } else {
        delete this.objFilter["tgl_pengajuan"];
      }
      delete this.objFilter["tgl_masuk"];
      this.start = 0;
      this.fetchUser();
    } else {
      delete this.objFilter["tgl_pengajuan"];
      delete this.objFilter["tgl_masuk"];
      this.start = 0;
      this.fetchUser();
  }
  }
  close() {
    this.selectedGrade  =null;
    this.selectedReason=null;
    this.detailreject=null;
  }
  // onSelectTglMasuk(e){
  // 	if(this.date1[1]){
  // 		let date1 = moment(this.date1[0]).format("YYYY-MM-DD");
  // 		let date2 = moment(this.date1[1]).format("YYYY-MM-DD");
  // 		this.objFilter['tgl_masuk'] = "2019-02-01 - 2019-02-15";
  // 	}else{
  // 		delete this.objFilter['tgl_masuk'];
  // 	}
  // 	this.start = 0;
  // 	this.fetchUser();
  // }
  // onSelectTglPengajuan(e){
  // 	if(this.date2[1]){
  // 		let date1 = moment(this.date2[0]).format("YYYY-MM-DD");
  // 		let date2 = moment(this.date2[1]).format("YYYY-MM-DD");
  // 		this.objFilter['tgl_pengajuan'] = "2019-02-01 - 2019-02-15";
  // 	}else{
  // 		delete this.objFilter['tgl_pengajuan'];
  // 	}
  // 	this.start = 0;
  // 	this.fetchUser();
  // }
}
