import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MemberService} from '../../member/member.service';
import {MasterService} from '../master.service';

import { MessageService } from 'primeng/components/common/messageservice';
import * as _ from 'lodash';
import {SelectItem} from 'primeng/api';
import {P} from '../../../../../node_modules/@angular/core/src/render3';

@Component({
  selector: 'app-loan-type',
  templateUrl: './loan-type.component.html',
  styleUrls: ['./loan-type.component.scss'],
  providers: [MessageService]
})
export class LoanTypeComponent implements OnInit {
  golongan = [];
  public data: any = [];
  public columns: any = [];
  public selectedColumns: any[];
  public loading: boolean;
  public selectedItem = null;
  public companies = [];
  public display = false;
  public editMode = false;

  public id_loan_type;
  public headerModal = 'Tipe Loan';
  public name_loan_type;
  public icon_loan_type;
  public bunga;
  public tenor;
  public plafond;
  public description;
  public masterGrades;
  public activeGrades;
  public masterTerm;
  public loadingBtn = false;
  selectedGolongan: any[];

  constructor(
    private memberService: MemberService,
    private masterService: MasterService,

    private messageService: MessageService,
    private router: Router
  ) {
    this.golongan = [];
  }


  ngOnInit() {
    this.fetchLoanType();
    this.fetchCompany();
    this.fetchGrade();
    this.fetchLoanTerm();

    this.columns = [
      {field: 'number', header: 'No', show: true},
      {field: 'name_loan_type', header: 'Tipe Pinjaman', show: true},
      {field: 'interest_effective', header: 'Golongan', show: true},
      {field: 'interest_flat', header: 'Bunga', show: true},
      {field: 'is_flat', header: 'Plafond', show: true},
    ];
    this.selectedColumns = _.filter(this.columns, {show: true});
  }

  // Toggle Columns
  // ====================== //
  changeColumn(e) {
    const find = _.find(this.columns, e.itemValue);
    if (e.itemValue != undefined) {
      if (e.itemValue.show) {
        find.show = false;
      } else {
        find.show = true;
      }
    } else {

    }

    this.selectedColumns = _.filter(this.columns, {show: true});
  }

  fetchGrade() {
    this.memberService.getGrade().subscribe(res => {

      this.masterGrades = [];
      _.map(res['data'], (x) => {
        // {
        //       "id_grade": "string",
        //       "interest": 0,
        //       "term": 0,
        //       "plafond": 0
        // }
        const obj = {label: x.name_grade, value: x.id_grade, interest: '', term: '', plafond: '', status: [], id_master_loan_interest: '', id_master_loan_plafond: '' };
        this.masterGrades.push(obj);
      });
    }, err => {
      if (err.status == 401) { this.memberService.updateToken(err.error.data.token); }
    });
  }
  fetchLoanTerm() {
    this.masterService.getLoanTerm().subscribe(res => {

      this.masterTerm = [];
      _.map(res['data'], (x) => {
        const obj = {label: x.count_term + ' Bulan' , value: x.id_term};
        this.masterTerm.push(obj);
      });
    }, err => {
      // if(err.status == 401) this.memberService.updateToken(err.error.data.token);
    });
  }

  submit() {
    this.loadingBtn = true;
    const {id_loan_type, name_loan_type, icon_loan_type, golongan, editMode } = this;
    if(!editMode){
      let plafond = [];
      let interest = [];
      golongan.forEach(z => {
        if (z.status.length > 0) {
          plafond.push(
            {
              id_grade : z.value,
              id_loan_type : id_loan_type,
              loan_plafond : z.plafond
            }
          );
          interest.push(
            {
              id_grade : z.value,
              id_loan_type : id_loan_type,
              interest : z.interest
            }
          );
        }
      });
      const datanya =  {
        name_loan_type, icon_loan_type, id_loan_type
      };
      this.masterService.postLoanType(datanya).subscribe(res => {
        // res
        if (res['status'] == 1) {
          this.masterService.postLoanInterest({model: interest}).subscribe(res2 => {

            this.masterService.postLoanPlafond({model: plafond}).subscribe(res3 => {
              this.messageService.add({severity: 'success', summary: 'Success', detail: 'Data Successfully submitted'});
              this.fetchLoanType();
              this.display = false;
              this.loadingBtn = false;
            }, err => {
              this.loadingBtn = false;
              this.messageService.add({severity: 'error', summary: 'Error', detail: 'Please try again'});
            });
          }, err => {
            this.loadingBtn = false;
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Please try again'});
          });
        }
      }, err => {
        this.messageService.add({severity: 'error', summary: 'Error', detail: 'Please try again'});
        this.loadingBtn = false;
      });
    }else{
      let tambahan = [];
      let edited = [];
      let hapus = [];

      let plafond = [];
      let interest = [];
      let plafond_edit = [];
      let interest_edit = [];
      golongan.forEach(z=>{
        if (z.status.length > 0 && z.id_master_loan_interest!='') {
          edited.push(z);
        }
        if (z.status.length > 0 && z.id_master_loan_interest=='') {
          tambahan.push(z);
        }
        if (z.status.length == 0 && z.id_master_loan_interest!='') {
          hapus.push(z);
        }
      });
      tambahan.forEach(z=>{
        plafond.push(
          {
            id_grade : z.value,
            id_loan_type : id_loan_type,
            loan_plafond : z.plafond
          }
        );
        interest.push(
          {
            id_grade : z.value,
            id_loan_type : id_loan_type,
            interest : z.interest
          }
        );
      });
      edited.forEach(z=>{
        plafond_edit.push(
          {
            id_master_loan_plafond:z.id_master_loan_plafond,
            id_grade : z.value,
            id_loan_type : id_loan_type,
            loan_plafond : z.plafond
          }
        );
        interest_edit.push(
          {
            id_master_loan_interest:z.id_master_loan_interest,
            id_grade : z.value,
            id_loan_type : id_loan_type,
            interest : z.interest
          }
        );
      });
      const datanya =  {
        name_loan_type, icon_loan_type, id_loan_type
      };
      this.masterService.putLoanType(datanya).subscribe(async res => {
        // res
        if (res['status'] == 1) {
          if(tambahan.length>0){
            await this.masterService.postLoanInterest({model: interest}).subscribe(res2 => {

            }, err => {
              this.loadingBtn = false;
              this.messageService.add({severity: 'error', summary: 'Error', detail: 'Please try again'});
            });
            await this.masterService.postLoanPlafond({model: plafond}).subscribe(res3 => {
              // this.messageService.add({severity: 'success', summary: 'Success', detail: 'Data Successfully submitted'});
              // this.fetchLoanType();
              // this.display = false;
              // this.loadingBtn = false;
            }, err => {
              this.loadingBtn = false;
              this.messageService.add({severity: 'error', summary: 'Error', detail: 'Please try again'});
            });
          }
          if(hapus.length>0){
            let tmp = await hapus.forEach(async z=>{
             await this.masterService.deleteLoanInterest(z.id_master_loan_interest).subscribe(res=>{

              }, err =>{

                this.messageService.add({severity: 'error', summary: 'Error', detail: 'Something Wrong'});
              });
              await this.masterService.deleteLoanPlafond(z.id_master_loan_plafond).subscribe(res=>{

              }, err =>{

                this.messageService.add({severity: 'error', summary: 'Error', detail: 'Something Wrong'});
              });
            });
          }

          if(edited.length>0){
           await this.masterService.putLoanInterest({model: interest_edit}).subscribe(res2 => {

            }, err => {
              this.loadingBtn = false;
              this.messageService.add({severity: 'error', summary: 'Error', detail: 'Please try again'});
            });
            await this.masterService.putLoanPlafond({model: plafond_edit}).subscribe(res3 => {

            }, err => {
              this.loadingBtn = false;
              this.messageService.add({severity: 'error', summary: 'Error', detail: 'Please try again'});
            });
          }
          setTimeout(()=>{
            this.messageService.add({severity: 'success', summary: 'Success', detail: 'Data Successfully submitted'});
            this.fetchLoanType();
            this.display = false;
            this.loadingBtn = false;
          },2000);


        }
      }, err => {
        this.messageService.add({severity: 'error', summary: 'Error', detail: 'Please try again'});
        this.loadingBtn = false;
      });
    }

  }

  // Fetching Loan Type
  // ========================= //
  fetchLoanType() {
    this.loading = true;
    this.masterService.getLoanType().subscribe(res => {
      _.map(res['data'], (x, i) => {
        x['number'] = i + 1;
      });
      this.data = res['data'];
      this.loading = false;
    });
  }


  convertMoney(val) {
    return 'Rp ' + val.toLocaleString();
  }

  openDialogForm() {
    this.display = true;
    this.editMode = false;
    this.id_loan_type = '';
    this.headerModal = 'Buat Tipe Baru';
    this.name_loan_type = '';
    this.icon_loan_type = '';
    this.golongan = JSON.parse(JSON.stringify(this.masterGrades));
    this.selectedGolongan = [];
  }

  // Fetch Company
  // ========================= //
  fetchCompany() {
    this.memberService.getCompany().subscribe(res => {
      this.companies = [{label: 'Semua Perusahaan', value: null}];
      _.map(res['data'], (x) => {
        this.companies.push({label: x.name_company, value: x.id_company});
      });
    });
  }

  // Select Item / User
  // ======================== //
  selectItem(e) {
    console.log(e);
    this.editMode = true;
    this.display = true;
    this.selectedItem = e;
    this.id_loan_type = e.id_loan_type;
    this.name_loan_type = e.name_loan_type;
    this.icon_loan_type = e.icon_loan_type;

    this.golongan = JSON.parse(JSON.stringify(this.masterGrades));
    this.golongan.forEach(z => {
      // id_master_loan_interest: 1
      // id_loan_type: "LOAN0001"
      // name_loan_type: "Multiguna"
      // id_grade: "GRD001"
      // name_grade: "I"
      // interest: 8.5
      const tmp = e.interest.filter(y => {
        return y.id_grade == z.value;
      });
      const tmp_plafond = e.plafond.filter(y => {
        return y.id_grade == z.value;
      });
      console.log({tmp_plafond});
      // id_grade:z.value,
      //   interest:z.interest,
      //   term:z.term,
      //   plafond:z.plafond
      if (tmp.length > 0) {
        z.status.push("1");
        z.interest = tmp[0].interest;
        z.id_master_loan_interest = tmp[0].id_master_loan_interest;
      }
      if (tmp_plafond.length > 0) {
        z.plafond = tmp_plafond[0].loan_plafond;
        z.id_master_loan_plafond = tmp_plafond[0].id_master_loan_plafond;
      }
    });
    console.log(this.golongan);
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 500);
  }
}
