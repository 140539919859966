import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import * as _ from "lodash";
import { FileUpload } from "primeng/fileupload";
import { Paginator } from "primeng/paginator";
import { MasterService } from "../master.service";
import { Message, MessageService } from "primeng/components/common/api";
import { HttpClient } from "@angular/common/http";
import * as moment from "moment";

interface selectedItem {
  id_catalog: string;
  nama_barang: string;
  start_date: Date;
  startDate: Date;
  endDate: Date;
  end_date: Date;
  position: string;
  product_images: FileUpload;
  isAktif: boolean;
  status: number;
  id_content: string;
  tipe_merk: null;
  stock_onhand: null;
  jenis: null;
  SKU: null;
  model: null;
}

@Component({
  selector: "app-catalog",
  templateUrl: "./catalog.component.html",
  styleUrls: ["./catalog.component.scss"],
  providers: [MessageService],
})
export class CatalogComponent implements OnInit {
  public displayUpload = false;
  public status = "";
  
  files: File[] = [];
  columns: any[];
  arrData: any[] = [];
  arrContentType: any[] = [];
  selectedItem: any = {};
  selectedThumbnail;
  idCatalog: string = null;
  loading: boolean = false;
  loadingSave: boolean = false;
  displayForm: boolean = false;
  isEdit: boolean = false;
  arrPosition: any[];
  totalCount: number = 0;
  perPage: number = 5;
  errorMessage: string = '';
  private objFilter = {};
  public date_range = null;
  public start = 0;
  public statusOpsi = [];
  public arrGambar = [];

  
 

  private selectedPage = 1;
  @ViewChild("p") paginator: Paginator;
  @ViewChild("fileInput") fileInput: FileUpload;
  @ViewChild('hiddenLink') hiddenLink: ElementRef<HTMLAnchorElement>;

  public msgs: Message[] = [];
  constructor(
    private masterService: MasterService,
    private messageService: MessageService,
    private http: HttpClient
  ) {}

  async ngOnInit() {
    this.loading = true; // Set loading to true initially

    this.statusOpsi = [{ label: "Semua Status", value: null }];
    this.statusOpsi.push({ label: "Active", value: "1" });
    this.statusOpsi.push({ label: "Non Active", value: "0" });

    this.arrGambar = [{ label: "Semua", value: null }];
    this.arrGambar.push({ label: "Bergambar", value: "1" });
    this.arrGambar.push({ label: "Tidak ada Gambar", value: "0" });

    try {
      let resContent: any = await this.fetchData(this.selectedPage, this.perPage, "nama_barang,asc");
      if (resContent.data) {
        this.arrData = resContent.data;
        this.totalCount = resContent.count_all;
      }
    } catch (error) {
      console.log("Something went wrong!", error);
    } finally {
      this.loading = false; // Set loading to false after data is fetched
    }

    this.columns = [
      { header: "Produk", field: "product_images" },
      { header: "Stock Avaible", field: "stock_onhand" },
      { header: "Nama Produk", field: "nama_barang" },
      { header: "Harga", field: "harga" },
      { header: "Warna", field: "warna" },
      { header: "Tipe Merk", field: "tipe_merk" },
      { header: "Status", field: "status" },
      { header: "Last Updated", field: "updated_at" },
    ];

    this.arrPosition = [
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "4", label: "4" },
    ];
  }

  fetchData(start: number, length: number, sort: string) {
    this.loading = true;
    return new Promise((resolve, reject) => {
      this.arrData = [];
      this.masterService.getDataCatalog(start, length, this.objFilter).subscribe(
        (res) => {
          this.arrData = res.data; // Update arrData with fetched data
          this.totalCount = res.count_all; // Update totalCount for paginator
          this.loading = false; // Set loading to false after data is fetched
          resolve(res);
        },
        (error: Response) => {
          this.loading = false; // Set loading to false in case of error
          this.errorMessage = error.statusText;
          reject(error.statusText);
        }
      );
    });
  }

  submitFilter(valFilter: any) {
    const { nama, tipe_merk, id_employee, status, gambar } = valFilter;

    this.objFilter = {}; // Reset the filter object

    if (nama) this.objFilter["nama"] = nama;
    if (tipe_merk) this.objFilter["tipe_merk"] = tipe_merk;
    if (id_employee) this.objFilter["id_employee"] = id_employee;
    if (status) this.objFilter["status"] = status;
    if (gambar) this.objFilter["gambar"] = gambar;

    if (this.date_range) {
      const date1 = moment(this.date_range[0]).format("YYYY-MM-DD");
      const date2 = moment(this.date_range[1]).format("YYYY-MM-DD");
      this.objFilter["last_updated"] = date1+" - "+date2;
      this.objFilter["reqEnd"] = date2;
    }

    this.start = 1; // Reset to the first page
    this.fetchData(this.selectedPage, this.perPage, "nama_barang,asc");
  }

  paginate(e) {
    this.selectedPage = e.page + 1;
    this.ngOnInit();
  }

  showThis(val: selectedItem) {
    val.isAktif = val.status == 1 ? true : false;
    this.isEdit = true;
    this.idCatalog = val.id_catalog;
    this.selectedThumbnail = val.product_images;
    this.selectedItem = val;
  }

  validateFileSize($event: any, maxFileSize: number) {
    if ($event.files[0].size > maxFileSize) {
      alert("Maximum File 3MB");
    }
  }

  onChangeThumbnail(file) {
    var myReader: FileReader = new FileReader();
    myReader.readAsDataURL(file);
    myReader.onloadend = (e) => {
      this.selectedThumbnail = myReader.result;
    };
  }

  resetFilter() {
    this.objFilter = {}; // Reset the filter object
    this.date_range = null; // Reset the date range
    this.selectedPage = 1; // Reset to the first page
    this.fetchData(this.selectedPage, this.perPage, "nama_barang,asc"); // Fetch data with reset filters
  }

  // downloadReport(valFilter: any) {
  //   this.hiddenLink.nativeElement.href = this.masterService.getDownloadReport();
  //   this.hiddenLink.nativeElement.click();
  // }

  uploadItem() {
    this.displayUpload = true;
  }

  convertImgToBlob() {
    return new Promise((resolve) => {
      var myReader: FileReader = new FileReader();
      myReader.readAsDataURL(this.fileInput.files[0]);
      myReader.onloadend = (e) => {
        resolve(myReader.result);
      };
    });
  }

  onChange(event: any) {
    const files = event.target.files;

    if (files.length) {
      this.status = "initial";
      this.files = files;
    }
  }

  public loadingSubmitApprove: boolean = false;
  onUpload() {
    this.loadingSubmitApprove = true;
    if (this.files.length) {
      const formData = new FormData();
      const file = this.files[0]; // Menggunakan akses indeks standar untuk FileList

      if (file !== undefined) {
        formData.append("file", file, file.name);
      }
      const upload$ = this.http.post("https://u.koperasi-astra.com/import-stock", formData);

      this.status = "uploading";

      upload$.subscribe({
        next: () => {
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Data Successfully submitted",
          });
          this.loadingSubmitApprove = false;
          this.status = "success";
          window.location.reload();
        },
        error: (error: any) => {
          this.loadingSubmitApprove = false;
          this.status = "fail";
        },
      });
    }
  }

  async save(e: any) {
    let img =
      this.fileInput.files.length > 0 ? await this.convertImgToBlob() : "no";
    this.loadingSave = true;
    const currentDate = moment().format("YYYY-MM-DD HH:mm:ss");
    const param = {
      nama_barang: e.nama_barang,
      product_images: img,
      status: e.status ? 1 : 0,
      deskripsi: e.deskripsi,
      harga: e.harga,
      warna: e.warna,
      total_harga_device: e.total_harga_device,
      margin: e.margin,
      nilai_margin: e.nilai_margin,
      hpp: e.hpp,
      stock_onhand: e.stock_onhand,
      SKU: e.SKU,
      tipe_merk: e.tipe_merk,
      jenis: e.jenis,
      model: e.model,
      created_at: currentDate,
      updated_at: currentDate
    };

    if (this.isEdit) {
      param["id_catalog"] = this.idCatalog;
      this.masterService.updateCatalog(param).subscribe((res) => {
        if (res.status == 1) {
          this.msgs = [];
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Update Success",
          });
          this.resetForm();
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: res.message,
          });
        }
        this.loadingSave = false;
      });
    } else {
      this.masterService.postCatalog(param).subscribe(
        (res) => {
          if (res.status == 1) {
            this.messageService.add({
              severity: "success",
              summary: "Success",
              detail: "Save Success",
            });
            this.resetForm();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: res.message,
            });
          }
          this.loadingSave = false;
        },
        (error) => {
          this.loadingSave = false;
        }
      );
    }
  }

  showDialog() {
    this.displayForm = true;
    this.selectedItem = {};
    this.idCatalog = "";
    this.selectedThumbnail = "";
  }

  resetForm() {
    this.selectedItem = {};
    this.selectedThumbnail = "";
    this.displayForm = false;
    this.isEdit = false;
    this.ngOnInit();
  }
}
