import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { APIService } from "../../service/api.service";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class LoanReportService {
  
  
  private urlGetReportProgress = this.APIService["hostLoanNew"] + "/loan-new/report-progress";
  private urlGetDownloaReportProgress = this.APIService["hostLoanNew"] + "/loan-new/download-report-progress";

  constructor(private APIService: APIService, private http: HttpClient) {}

  setOptions(options = null) {
    const _defaultOption = {
      headers: new HttpHeaders({ accept: "application/json", Authorization: localStorage.getItem("token")}),
    };
    if(options && options != '') {
      return {..._defaultOption, ...options};
    }
    return _defaultOption;
  }
  // Update Token
  // ======================== //
  updateToken(token) {
    localStorage.setItem("token", token);
    location.reload();
  }

  getReportProgress(param) {
    let filter = param != undefined ? param : "";
    return this.http.get(this.urlGetReportProgress + '?token=' + filter, this.setOptions());
  }

  getDownloaReportProgress(param) {
    let filter = param != undefined ? param : "";
    let token = localStorage.getItem("token");
    token = token.replace('Bearer', '')

    return this.urlGetDownloaReportProgress + '?token=' +  token + filter;
  }

}
