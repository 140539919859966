import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { MainComponent } from "./admin-page/layout/main-component.component";

// Dashboard
// ========================== //
import { MainDashboardComponent } from "./admin-page/dashboard/main-dashboard/main-dashboard.component";
import { EcommerceComponent } from "./admin-page/dashboard/ecommerce/ecommerce.component";

// Member
// ========================== //
import { ApprovalMemberComponent } from "./admin-page/member/approval-member/approval-member.component";
import { MainMemberComponent } from "./admin-page/member/main-member/main-member.component";
import { ListMemberComponent } from "./admin-page/member/list-member/list-member.component";
import { ListMemberMutasiComponent } from "./admin-page/member/list-memberMutasi/list-memberMutasi.component";
import { ListHistoryMutasiCompoent } from "./admin-page/member/list-historyMutasi/list-historyMutasi.component";
import { ListMemberKeluarComponent } from "./admin-page/member/list-memberKeluar/list-memberKeluar.component";
import { ListApproveSalaryComponent } from "./admin-page/member/list-approve-salary/list-approve-salary.component";
import { UpdateSalaryComponent } from "./admin-page/member/update-salary/update-salary.component";

// Loan
// ========================== //
import { MicroloanComponent } from "./admin-page/loan/microloan/microloan.component";
import { JangkaPanjangComponent } from "./admin-page/loan/jangka-panjang/jangka-panjang.component";
import { MainLoanComponent } from "./admin-page/loan/main-loan/main-loan.component";
import { DetailLoanComponent } from "./admin-page/loan/detail-loan/detail-loan.component";
import { NonKoperasiComponent } from "./admin-page/loan/non-koperasi/non-koperasi.component";
import { NonKoperasiDetailComponent } from "./admin-page/loan/non-koperasi-detail/non-koperasi-detail.component";

// Loan New
// ========================== //
import { MainLoanNewComponent } from "./admin-page/loan-new/main-loan-new/main-loan-new.component";
import { LoanNewListComponent } from "./admin-page/loan-new/loan-new-list/loan-new-list.component";
import { LoanNewDetailComponent } from "./admin-page/loan-new/loan-new-detail/loan-new-detail.component";

// Products
// ========================== //
import { MainProductComponent } from "./admin-page/products/main-product/main-product.component";
import { SyncProductComponent } from "./admin-page/products/sync-product/sync-product.component";
import { SettingProductComponent } from "./admin-page/products/setting-product/setting-product.component";

// Master
// ========================== //
import { MainMasterComponent } from "./admin-page/master/main-master/main-master.component";
import { LoanTypeComponent } from "./admin-page/master/loan-type/loan-type.component";
import { MicroloanMasterComponent } from "./admin-page/master/microloan/microloan.component";
import { UserSettingComponent } from "./admin-page/master/user-setting/user-setting.component";
import { VoucherComponent } from "./admin-page/master/voucher/voucher.component";
import { CompanyComponent } from "./admin-page/master/company/company.component";
import { CoreFeeComponent } from "./admin-page/master/core-fee/core-fee.component";
import { CoreTermComponent } from "./admin-page/master/core-term/core-term.component";
import { CoreLoanTypeComponent } from "./admin-page/master/core-loan-type/core-loan-type.component";
import { CoreDocumentComponent } from "./admin-page/master/core-document/core-document.component";
import { CoreProductComponent } from "./admin-page/master/core-product/core-product.component";
import { ProducCompanyComponent } from "./admin-page/master/produc-company/produc-company.component";
import { DeskripsiPointComponent } from "./admin-page/master/deskripsi-point/deskripsipoint.component";
import { PointMasterComponent } from "./admin-page/master/point/point.component";
import { OrderPointComponent } from "./admin-page/master/order-point/orderpoint.component";


// Pages
// ========================== //
import { SigninComponent } from "./pages/layout-sign/signin/signin.component";
import { SignupComponent } from "./pages/layout-sign/signup/signup.component";
import { ChangepassComponent } from "./pages/layout-sign/change-pass/change-pass.component";
import { Page404Component } from "./pages/layout-error/page404/page404.component";
import { Page500Component } from "./pages/layout-error/page500/page500.component";

// Report
// ========================== //
import { MainReportComponent } from "./admin-page/report/main-report/main-report.component";
import { ReportPaymentComponent } from "./admin-page/report/report-payment/report-payment.component";
import { OrderBillerComponent } from "./admin-page/report/order-biller/order-biller.component";
import { OrderBillerDetailComponent } from "./admin-page/report/order-biller-detail/order-biller-detail.component";

// Layout Sign
// ========================== //
import { LayoutSignComponent } from "./pages/layout-sign/layout-sign.component";
import { LayoutErrorComponent } from "./pages/layout-error/layout-error.component";

// Order
// ========================== //
import { MainOrderComponent } from "./admin-page/order/main-order/main-order.component";

// Ticket
// ========================== //
import { MainTicketComponent } from "./admin-page/tickets/main-ticket/main-ticket.component";
import { TicketKeretaComponent } from "./admin-page/tickets/ticket-kereta/ticket-kereta.component";
import { TicketKeretaDetailComponent } from "./admin-page/tickets/ticket-kereta-detail/ticket-kereta-detail.component";
import { TicketPesawatComponent } from "./admin-page/tickets/ticket-pesawat/ticket-pesawat.component";
import { TicketPesawatDetailComponent } from "./admin-page/tickets/ticket-pesawat-detail/ticket-pesawat-detail.component";
import { BookingHotelComponent } from "./admin-page/tickets/booking-hotel/booking-hotel.component";
import { BookingHotelDetailComponent } from "./admin-page/tickets/booking-hotel-detail/booking-hotel-detail.component";
import { UploadBannerComponent } from "./admin-page/master/upload-banner/upload-banner.component";
import { CatalogComponent } from "./admin-page/master/catalog/catalog.component";
import { LoanReportProgressComponent } from "./admin-page/loan-report/loan-report-progress/loan-report-progress.component";

import { UserResignComponent } from "./admin-page/master/user-resign/user-resign.component";

// Pelunasan
// ========================== //
import { MainPelunasanComponent } from "./admin-page/pelunasan/main-pelunasan/main-pelunasan.component";
import { PelunasanListComponent } from "./admin-page/pelunasan/pelunasan-list/pelunasan-list.component";
import { PelunasanDetailComponent } from "./admin-page/pelunasan/pelunasan-detail/pelunasan-detail.component";

import { ChangePassword } from "./admin-page/master/change-password/change-password.component";

import { ReportMasterComponent } from "./admin-page/master/report/report.component";
import { UserBlacklistComponent } from "./admin-page/master/user-blacklist/user-blacklist.component";

import { CatalogDraftComponent } from "./admin-page/master/catalog-draft/catalog-draft.component";

const routes: Routes = [
  { path: "", redirectTo: "main", pathMatch: "full" },
  {
    path: "main",
    component: MainComponent,
    children: [
      { path: "", redirectTo: "dashboard", pathMatch: "full" },
      { path: "dashboard", component: MainDashboardComponent },
      { path: "ecommerce-dahsboard", component: EcommerceComponent },
      { path: "order", component: MainOrderComponent },
      { path: "voucher", component: VoucherComponent },
      {
        path: "member",
        component: MainMemberComponent,
        children: [
          { path: "", redirectTo: "anggota", pathMatch: "full" },
          { path: "calon-anggota", component: ApprovalMemberComponent },
          { path: "anggota", component: ListMemberComponent },
          { path: "anggotaMutasi", component: ListMemberMutasiComponent },
          { path: "historyMutasi", component: ListHistoryMutasiCompoent },      
          { path: "anggotaKeluar", component: ListMemberKeluarComponent },                 
          { path: "approve-salary", component: ListApproveSalaryComponent },
          { path: "update-salary", component: UpdateSalaryComponent },
        ],
      },
      {
        path: "ticket",
        component: MainTicketComponent,
        children: [
          { path: "", redirectTo: "pesawat", pathMatch: "full" },
          { path: "pesawat", component: TicketPesawatComponent },
          {
            path: "pesawat-detail/:id",
            component: TicketPesawatDetailComponent,
          },
          { path: "kereta", component: TicketKeretaComponent },
          { path: "kereta-detail/:id", component: TicketKeretaDetailComponent },
        ],
      },
      {
        path: "booking",
        component: MainTicketComponent,
        children: [
          { path: "", redirectTo: "hotel", pathMatch: "full" },
          { path: "hotel", component: BookingHotelComponent },
          { path: "hotel-detail/:id", component: BookingHotelDetailComponent },
        ],
      },
      {
        path: "product",
        component: MainProductComponent,
        children: [
          { path: "", redirectTo: "sync", pathMatch: "full" },
          { path: "sync", component: SyncProductComponent },
          { path: "setting", component: SettingProductComponent },
        ],
      },
      {
        path: "loan",
        component: MainLoanComponent,
        children: [
          { path: "", redirectTo: "microloan", pathMatch: "full" },
          { path: "microloan", component: MicroloanComponent },
          { path: "jangka-panjang", component: JangkaPanjangComponent },
          { path: "detail/:id", component: DetailLoanComponent },
          { path: "non-koperasi", component: NonKoperasiComponent },
          { path: "non-koperasi/:id", component: NonKoperasiDetailComponent },
        ],
      },
      {
        path: "loan-new",
        component: MainLoanNewComponent,
        children: [
          { path: "", redirectTo: "list", pathMatch: "full" },
          { path: "list", component: LoanNewListComponent },
          { path: "detail/:id", component: LoanNewDetailComponent },
          { path: "report-progress", component: LoanReportProgressComponent },
        ],
      },
      {
        path: "master",
        component: MainMasterComponent,
        children: [
          { path: "", redirectTo: "loan-type", pathMatch: "full" },
          { path: "loan-type", component: LoanTypeComponent },
          { path: "core-document", component: CoreDocumentComponent },
          { path: "core-loan-type", component: CoreLoanTypeComponent },
          { path: "core-fee", component: CoreFeeComponent },
          { path: "core-term", component: CoreTermComponent },
          { path: "core-product", component: CoreProductComponent },
          { path: "core-company-product", component: ProducCompanyComponent },
          { path: "microloan", component: MicroloanMasterComponent },
          { path: "user-setting", component: UserSettingComponent },
          { path: "upload-banner", component: UploadBannerComponent },
          { path: "catalog", component: CatalogComponent },
          { path: "change-password", component: ChangePassword },
          { path: "company", component: CompanyComponent },
          { path: "user-resign", component: UserResignComponent},
          { path: "point", component: PointMasterComponent },
          { path: "orderpoint", component: OrderPointComponent },
          { path: "deskripsi-point", component: DeskripsiPointComponent },
          { path: "report-master", component: ReportMasterComponent },
          { path: "user-blacklist", component: UserBlacklistComponent },
          { path: "catalog-draft", component: CatalogDraftComponent },
        ],
      },
      {
        path: "report",
        component: MainReportComponent,
        children: [
          { path: "", redirectTo: "payment-report", pathMatch: "full" },
          { path: "payment-report", component: ReportPaymentComponent },
          { path: "order-biller", component: OrderBillerComponent },
          { path: "order-biller/:id", component: OrderBillerDetailComponent },
        ],
      },
      {
        path: "pelunasan",
        component: MainPelunasanComponent,
        children: [
          { path: "", redirectTo: "list", pathMatch: "full" },
          { path: "list", component: PelunasanListComponent },
          { path: "detail/:id", component: PelunasanDetailComponent },
          { path: "report-progress", component: LoanReportProgressComponent },
        ],
      },
    ],
  },
  {
    path: "error",
    component: LayoutErrorComponent,
    children: [
      { path: "", redirectTo: "404", pathMatch: "full" },
      { path: "404", component: Page404Component },
      { path: "500", component: Page500Component },
    ],
  },
  {
    path: "sign",
    component: LayoutSignComponent,
    children: [
      { path: "", redirectTo: "signin", pathMatch: "full" },
      { path: "signin", component: SigninComponent },
      { path: "signup", component: SignupComponent },
      { path: "changepass/:id/:email", component: ChangepassComponent },
    ],
  },
  { path: "**", redirectTo: "/error/404", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
